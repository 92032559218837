.row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.row-reversed{
    flex-direction: row-reverse;
}

.column {
    flex: 1 0 10%;
    margin: 5px;
}

.column-2 {
    flex: 2 0 10%;
    margin: 5px;
}

.column-4 {
    flex: 4 0 10%;
    margin: 5px;
}

.spaced{
    padding-left: 3.5%;
    padding-right: 3.5%;
}

@media screen and (max-width: 738px){
    .row-sm-reversed{
        flex-direction: column-reverse;
    }
    .column{
        flex-basis: 50%;
        align-items: center;
        justify-content: center;
    }
    .column-2{
        flex-basis: 50%;
        align-items: center;
        justify-content: center;
    }
    .column-4{
        flex-basis: 50%;
        align-items: center;
        justify-content: center;
    }
}