.Header_mainIcon {
  height: 50px;
}

.drawer {
  position: absolute;
  background-color: white;
  top: 5rem;
  right: 0;
  height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

a.Header_loginButton {
  background: #3cb371;
  color: white;
  border: 2px solid #3cb371;
  width: 148px;
  font-size: 1rem;
  font-weight: bold;
}
a.Header_signupButton {
  color: #63d063;
  background-color: white;
  border: 2px solid #63d063;
  width: 148px;
}

span.Header_hireButton {
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

a.Header_hireButton {
  margin-right: 20px;
  font-size: 1.1rem;
  font-weight: 500;
  color: black;
  text-decoration: none;
  cursor: pointer;
}
a.logout_btn {
  color: grey;
}
a.Header_hireButton.active {
  color: #3cb371;
}
a.Header_hireButton:hover {
  color: #3cb371;
}
span.Header_hireButton:hover {
  color: #3cb371;
}

.Header_bottomDrawerImg {
  margin-left: 20%;
  width: 90px;
}

ul.Header_bottomDrawer {
  margin-top: auto;
}
.header_navup {
  background: #f7f8f8;
  color: #252525;
}

.header-container{
 padding-top: 24px; 
 padding-bottom: 24px;
 display:flex !important;
 justify-content: center !important;
}

.logout {
  /* display:block; */
  margin-left: 10px;
}

.header-container a {
  color: rgb(24, 27,31);
  text-decoration: none;
  font-weight: 400;
}

.get-started {
  color: white;
  font-weight: 700;
}

.header-container a:hover {
  color: rgb(0, 130, 243); 
  text-decoration:none; 
  cursor:pointer;  
}

.logo-padding {
  padding-top: 10px;
}

.hidde-dropdown {
  display: none;
}


/* new css */

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-commerce-commercecartwrapper {
  display: inline-block;
  position: relative;
}

.w-commerce-commercecartopenlink {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecartopenlinkcount {
  display: inline-block;
  height: 18px;
  min-width: 18px;
  margin-left: 8px;
  padding-right: 6px;
  padding-left: 6px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background-color: #fff;
  color: #3898ec;
  font-size: 11px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
}

.w-commerce-commercecartcontainerwrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.8);
}

.w-commerce-commercecartcontainerwrapper--cartType-modal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftSidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightSidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
  position: absolute;
  top: 100%;
  left: 0px;
  right: auto;
  bottom: auto;
  background-color: transparent;
}

.w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
  position: absolute;
  left: auto;
  top: 100%;
  right: 0px;
  bottom: auto;
  background-color: transparent;
}

.w-commerce-commercecartcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.25);
}

.w-commerce-commercecartheader {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #e6e6e6;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.w-commerce-commercecartheading {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.w-commerce-commercecartcloselink {
  width: 16px;
  height: 16px;
}

.w-commerce-commercecartformwrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecartform {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecartlist {
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
  -webkit-overflow-scrolling: touch;
}

.w-commerce-commercecartitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecartiteminfo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 16px;
  margin-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecartoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercecartquantity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecartquantity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecartquantity::placeholder {
  color: #999;
}

.w-commerce-commercecartquantity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecartfooter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
}

.w-commerce-commercecartlineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.w-commerce-commercecartordervalue {
  font-weight: 700;
}

.w-commerce-commercecartapplepaybutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 8px;
  height: 38px;
  min-height: 30px;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  background-color: #000;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 8px;
  height: 38px;
}

.w-commerce-commercequickcheckoutgoogleicon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercequickcheckoutmicrosofticon {
  display: block;
  margin-right: 8px;
}

.w-commerce-commercecartcheckoutbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.w-commerce-commercecartemptystate {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecarterrorstate {
  margin-top: 0px;
  margin-right: 24px;
  margin-bottom: 24px;
  margin-left: 24px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: auto;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  background-color: #ffdede;
}

.w-pagination-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-pagination-previous {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-pagination-next {
  display: block;
  color: #333;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #fafafa;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-color: #ccc;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
}

.w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercelayoutmain {
  -webkit-flex-basis: 800px;
  -ms-flex-preferred-size: 800px;
  flex-basis: 800px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  margin-right: 20px;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutblockcontent {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutemailinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.w-commerce-commercecheckoutcolumn {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
}

.w-commerce-commercecheckoutshippingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutshippingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left-color: #e6e6e6;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-color: #e6e6e6;
  border-right-width: 1px;
  border-right-style: solid;
}

.w-commerce-commercecheckoutshippingmethoditem {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  border-bottom-color: #e6e6e6;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 0px;
  background-color: #fff;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  margin-left: 12px;
  margin-right: 12px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardnumber::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardexpirationdate::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: text;
}

.w-commerce-commercecheckoutcardsecuritycode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  font-weight: 400;
  margin-left: 8px;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingfullname::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddress::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcity::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingstateprovince::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingzippostalcode::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commercecheckoutbillingcountryselector::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 20px;
  padding-left: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #e6e6e6;
  background-color: #fff;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  margin-left: 16px;
  margin-right: 16px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  white-space: pre-wrap;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  text-decoration: none;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  -webkit-flex-basis: 320px;
  -ms-flex-preferred-size: 320px;
  flex-basis: 320px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutdiscounts {
  background-color: #fff;
  border-bottom-color: #e6e6e6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: #e6e6e6;
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: #e6e6e6;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: #e6e6e6;
  border-top-style: solid;
  border-top-width: 1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.w-commerce-commercecheckoutdiscountslabel {
  margin-bottom: 8px;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.w-commerce-commercecheckoutdiscountsinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0%;
  -ms-flex-preferred-size: 0%;
  flex-basis: 0%;
  min-width: 0px;
}

.w-commerce-commercecheckoutdiscountsinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutdiscountsinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutdiscountsbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 8px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  height: 38px;
}

.w-commerce-commercecheckoutplaceorderbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 20px;
  text-align: center;
}

.w-commerce-commercecheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-commerce-commerceaddtocartform {
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 15px;
  margin-left: 0px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.w-commerce-commerceaddtocartoptionpill {
  margin-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #000;
  border-bottom-color: #000;
  border-left-color: #000;
  border-right-color: #000;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  color: #000;
  background-color: #fff;
  cursor: pointer;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  background-color: #fafafa;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ddd;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: #ddd;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: #ddd;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  display: block;
  height: 38px;
  line-height: 20px;
  margin-bottom: 10px;
  padding-top: 8px;
  padding-right: 6px;
  padding-bottom: 8px;
  padding-left: 12px;
  width: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartquantityinput::-webkit-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-top-color: #3898ec;
  border-right-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  background-color: #3898ec;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 0px;
  color: #fff;
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  display: inline-block;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 10px;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  background-color: #e6e6e6;
  border-top-color: #e6e6e6;
  border-bottom-color: #e6e6e6;
  border-left-color: #e6e6e6;
  border-right-color: #e6e6e6;
  cursor: not-allowed;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ddd;
}

.w-commerce-commerceaddtocarterror {
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffdede;
}

.w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercelayoutcontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #f5f5f5;
}

.w-commerce-commercepaypalcheckouterrorstate {
  margin-top: 16px;
  margin-bottom: 16px;
  padding-top: 10px;
  padding-right: 16px;
  padding-bottom: 10px;
  padding-left: 16px;
  background-color: #ffdede;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}



.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-top-width: 4px;
  border-bottom-width: 4px;
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
}

.w-embed-youtubevideo {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/youtube-placeholder.2b05e7d68d.svg');
  background-size: cover;
  background-position: 50% 50%;
}

.w-embed-youtubevideo:empty {
  min-height: 75px;
  padding-bottom: 56.25%;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutmain {
    margin-right: 0px;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .w-commerce-commercelayoutsidebar {
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .w-commerce-commercelayoutcontainer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecartcontainerwrapper--cartType-modal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .w-commerce-commercecartquantity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutemailinput {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutshippingcountryselector {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardnumber {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardexpirationdate {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutcardsecuritycode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingfullname {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddress {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcity {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingstateprovince {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingzippostalcode {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutbillingcountryselector {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutdiscountsinput {
    font-size: 16px;
  }

  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

body {
  font-family: Inter, sans-serif;
  color: #707480;
  font-size: 18px;
  line-height: 1.667em;
  font-weight: 400;
}

h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 54px;
  line-height: 1.222em;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 38px;
  line-height: 1.316em;
  font-weight: 700;
}

h3 {
  margin-top: 0px;
  margin-bottom: 8px;
  color: #181b1f;
  font-size: 24px;
  line-height: 1.417em;
  font-weight: 700;
}

h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #181b1f;
  font-size: 22px;
  line-height: 1.273em;
  font-weight: 700;
}

h5 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #181b1f;
  font-size: 18px;
  line-height: 1.333em;
  font-weight: 700;
}

h6 {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #181b1f;
  font-size: 16px;
  line-height: 1.375em;
  font-weight: 700;
}

p {
  margin-bottom: 16px;
}

a {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #508dff;
  text-decoration: underline;
}

a:hover {
  color: #181b1f;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

ol {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  margin-bottom: 8px;
  padding-left: 8px;
}

img {
  display: inline-block;
  max-width: 100%;
}

label {
  display: block;
  margin-bottom: 12px;
  color: #181b1f;
  line-height: 1.111em;
  font-weight: 700;
}

strong {
  color: #181b1f;
  font-weight: 700;
}

blockquote {
  margin-top: 14px;
  margin-bottom: 32px;
  padding: 83px 106px;
  border: 0px solid #000;
  border-radius: 23px;
  background-color: #f7f9fc;
  color: #3e434d;
  font-size: 28px;
  line-height: 1.607em;
  font-weight: 500;
  text-align: center;
}

figure {
  margin-top: 24px;
  margin-bottom: 48px;
}

figcaption {
  margin-top: 16px;
  text-align: center;
}

.page-wrapper {
  min-height: 100vh;
}

.grid-2-columns {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-2-columns.style---styleguide-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 0px;
  grid-row-gap: 28px;
  -ms-grid-columns: 0.3fr 1fr;
  grid-template-columns: 0.3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-2-columns._1-col-tablet.top {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.grid-2-columns.form {
  grid-column-gap: 24px;
  grid-row-gap: 32px;
}

.grid-2-columns.title-and-paragraph {
  grid-column-gap: 40px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
}

.grid-2-columns.template-page-sidebar {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 40px;
  -ms-grid-columns: 0.35fr 1fr;
  grid-template-columns: 0.35fr 1fr;
}

.grid-2-columns.changelog-item {
  padding-top: 64px;
  padding-bottom: 64px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 60px;
  -ms-grid-columns: 0.4fr 1fr;
  grid-template-columns: 0.4fr 1fr;
  border-bottom: 1px solid #f1f3f8;
}

.grid-2-columns.changelog-item.first {
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom-width: 0px;
}

.grid-2-columns.checkout-page {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  -ms-grid-columns: 1fr 0.45fr;
  grid-template-columns: 1fr 0.45fr;
}

.grid-2-columns.suprises-pages-right {
  margin-bottom: 124px;
  grid-column-gap: 100px;
  -ms-grid-columns: 1fr 1.25fr;
  grid-template-columns: 1fr 1.25fr;
}

.grid-2-columns.suprises-pages-right.last {
  margin-bottom: 0px;
}

.grid-2-columns.suprises-pages-left {
  margin-bottom: 124px;
  grid-column-gap: 100px;
  -ms-grid-columns: 1.25fr 1fr;
  grid-template-columns: 1.25fr 1fr;
}

.grid-2-columns.title-and-buttons {
  grid-column-gap: 40px;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
}

.grid-2-columns.title-and-buttons.card-blog-post-author-top {
  grid-column-gap: 20px;
  grid-row-gap: 19px;
  -ms-grid-columns: 0.7fr auto;
  grid-template-columns: 0.7fr auto;
}

.grid-2-columns.small-print-social-media {
  grid-column-gap: 40px;
  -ms-grid-columns: 1fr 0.4fr;
  grid-template-columns: 1fr 0.4fr;
}

.grid-2-columns.card-newsletter-grid {
  grid-column-gap: 40px;
  -ms-grid-columns: 0.9fr 1fr;
  grid-template-columns: 0.9fr 1fr;
}

.grid-2-columns.blog-post-page-grid {
  position: relative;
  z-index: 1;
  grid-column-gap: 40px;
  -ms-grid-columns: auto 1fr;
  grid-template-columns: auto 1fr;
}

.grid-2-columns.conatct-form-section-grid {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 40px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
}

.grid-2-columns.faqs-grid {
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid-2-columns.careers-hero-images-grid {
  margin-top: -36px;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 37px;
  grid-row-gap: 37px;
}

.grid-2-columns.demo-grid {
  grid-column-gap: 40px;
  -ms-grid-columns: 1fr 0.75fr;
  grid-template-columns: 1fr 0.75fr;
}

.grid-2-columns._2-col-sales {
  max-width: 804px;
  margin-top: 28px;
  margin-right: auto;
  margin-left: auto;
}

.style---navigation-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  overflow: hidden;
  height: 100%;
  max-height: 100vh;
  box-shadow: 1px 0 14px 0 rgba(24, 24, 31, 0.06);
}

.style---main-wrapper {
  background-color: #f7f9fc;
}

.card {
  overflow: hidden;
  border-radius: 20px;
  background-color: #f7f9fc;
}

.card.link-card {
  display: block;
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  color: #707480;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card.link-card:hover {
  -webkit-transform: translate3d(0px, -6px, 0.01px);
  transform: translate3d(0px, -6px, 0.01px);
  color: #707480;
}

.card.link-card.search-item {
  min-height: 100%;
  padding: 40px;
}

.card.link-card.blog-post-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card.link-card.career-item {
  padding: 69px 30px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card.link-card.career-item:hover {
  -webkit-transform: translate3d(0px, -3px, 0.01px);
  transform: translate3d(0px, -3px, 0.01px);
}

.card.link-card.blog-post-featured {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card.link-card.blog-post-author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.link-card.contact-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 48px 42px 64px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 22px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.link-card.contact-icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  border-style: solid;
  border-width: 0.31px;
  border-color: #f1f3f8;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card.link-card.contact-icon-link:hover {
  -webkit-transform: translate3d(0px, -3px, 0.01px);
  transform: translate3d(0px, -3px, 0.01px);
  color: #508dff;
}

.card.link-card.integration-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 53px 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card.sticky-top.top-24px {
  top: 24px;
}

.card.checkout-block {
  margin-bottom: 28px;
  padding: 32px 32px 48px;
}

.card.checkout-block.order-summary {
  padding-bottom: 40px;
}

.card.checkout-block.last {
  margin-bottom: 0px;
}

.card.figma-file {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.card.sales-home-page {
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  text-align: center;
  text-decoration: none;
}

.card.sales-home-page:hover {
  -webkit-transform: scale(0.96);
  -ms-transform: scale(0.96);
  transform: scale(0.96);
}

.card.buy-now {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #4253ff;
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  text-decoration: none;
}

.card.buy-now:hover {
  -webkit-transform: scale(0.96);
  -ms-transform: scale(0.96);
  transform: scale(0.96);
}

.card.buy-now.white {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(24, 27, 31, 0.09);
}

.card.featured-feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 62px 52px 62px 55px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.feature {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 45px 24px 53px;
  text-align: center;
}

.card.cta-v1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 49px;
  padding-bottom: 49px;
  padding-left: 62px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#508dff), to(#50b5ff));
  background-image: linear-gradient(180deg, #508dff, #50b5ff);
  box-shadow: inset 0 1px 12px 0 rgba(24, 24, 31, 0.16), 0 3px 16px 0 rgba(89, 175, 255, 0.02);
}

.card.advantage-02 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.advantage-03 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.advantage-04 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.testimonial-twitter-strip {
  max-width: 376px;
  padding: 51px 33px;
}

.card.footer-cta {
  padding: 44px 63px 44px 53px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.footer-newsletter {
  padding: 46px 35px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.stats {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 58px 30px;
}

.card.value {
  padding: 56px 33px 54px;
}

.card.logo-strip {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 160px;
  max-height: 160px;
  min-height: 160px;
  padding: 60px 34px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
}

.card.newsletter {
  padding: 87px 74px 87px 68px;
}

.card.blog-post-body {
  padding: 92px 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.contact-form {
  padding: 63px 52px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.accordion-item {
  padding: 28px 42px 34px;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  cursor: pointer;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.card.accordion-item:hover {
  -webkit-transform: scale3d(0.99, 0.99, 1.01);
  transform: scale3d(0.99, 0.99, 1.01);
}

.card.pricing-wrapper {
  width: 100%;
  border-radius: 17px;
  background-color: #fff;
}

.card.product-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 53px 65px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
}

.card.product-description {
  padding: 68px 78px 71px;
}

.card.integration-body {
  padding: 92px 74px 61px;
}

.card.careers-perks {
  padding: 129px 74px 100px 78px;
}

.card.career-body {
  padding: 74px 60px 60px;
}

.card.team-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 29px;
  padding-right: 58px;
  padding-left: 58px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card.demo-form {
  padding: 60px 52px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.sign-up-form {
  padding: 70px 52px 76px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.sign-in-form {
  padding: 67px 44px 60px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.password-page {
  position: relative;
  z-index: 1;
  max-width: 624px;
  margin-right: auto;
  margin-left: auto;
  padding: 61px 55px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.card.dropdown-nav {
  margin-top: 15px;
  padding: 15px 30px 40px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  box-shadow: 0 1px 40px 0 rgba(24, 24, 31, 0.14);
}

.style---color-block {
  position: relative;
  min-height: 180px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.style---color-block-content {
  padding: 24px 24px 32px;
  border-top: 1px solid #f1f3f8;
}

.style---color-block-name {
  margin-bottom: 8px;
  color: #181b1f;
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

.style---color-block-hex {
  line-height: 20px;
}

.grid-4-columns {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-4-columns.pricing-tablet-grid {
  position: relative;
  z-index: 1;
  margin-top: -20px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 0.9fr 1fr;
  grid-template-columns: 1fr 1fr 0.9fr 1fr;
}

.grid-4-columns.pricing-tablet-grid.mobile {
  display: none;
}

.bg-accent-1 {
  background-color: #508dff;
}

.bg-secondary-1 {
  background-color: #50b5ff;
}

.bg-secondary-2 {
  background-color: #2a7fff;
}

.bg-neutral-800 {
  background-color: #181b1f;
}

.bg-neutral-700 {
  background-color: #3e434d;
}

.bg-neutral-600 {
  background-color: #707480;
}

.bg-neutral-500 {
  background-color: #b1b7c5;
}

.bg-neutral-400 {
  background-color: #e9edf4;
}

.bg-neutral-300 {
  background-color: #f1f3f8;
}

.bg-neutral-200 {
  background-color: #f7f9fc;
}

.bg-neutral-100 {
  background-color: #fff;
}

.style---color-block-text-example {
  position: absolute;
  left: auto;
  top: 16px;
  right: 16px;
  bottom: auto;
  padding: 10px 14px;
  border-radius: 40px;
  background-color: #fff;
  font-size: 14px;
  line-height: 16px;
}

.style---color-block-text-example.badge-dark {
  background-color: #181b1f;
}

.color-accent-1 {
  color: #508dff;
}

.color-secondary-1 {
  color: #50b5ff;
}

.color-secondary-2 {
  color: #2a7fff;
}

.color-neutral-800 {
  color: #181b1f;
}

.color-neutral-700 {
  color: #3e434d;
}

.color-neutral-600 {
  color: #707480;
}

.color-neutral-500 {
  color: #b1b7c5;
}

.color-neutral-300 {
  color: #f1f3f8;
}

.color-neutral-200 {
  color: #f7f9fc;
}

.color-neutral-100 {
  color: #fff;
}

.bg-green-400 {
  background-color: #11845b;
}

.color-green-400 {
  color: #11845b;
}

.bg-blue-400 {
  background-color: #086cd9;
}

.color-blue-400 {
  color: #086cd9;
}

.bg-blue-300 {
  background-color: #1d88fe;
}

.color-blue-300 {
  color: #1d88fe;
}

.bg-blue-200 {
  background-color: #8fc3ff;
}

.bg-blue-100 {
  background-color: #eaf4ff;
}

.color-blue-100 {
  color: #eaf4ff;
}

.color-neutral-400 {
  color: #e9edf4;
}

.bg-green-300 {
  background-color: #05c168;
}

.color-green-300 {
  color: #05c168;
}

.bg-green-200 {
  background-color: #7fdca4;
}

.color-green-200 {
  color: #7fdca4;
}

.color-green-100 {
  color: #def2e6;
}

.bg-green-100 {
  background-color: #def2e6;
}

.bg-red-400 {
  background-color: #dc2b2b;
}

.color-red-400 {
  color: #dc2b2b;
}

.bg-red-300 {
  background-color: #ff5a65;
}

.color-red-300 {
  color: #ff5a65;
}

.bg-red-200 {
  background-color: #ffbec2;
}

.color-red-200 {
  color: #ffbec2;
}

.bg-red-100 {
  background-color: #ffeff0;
}

.color-red-100 {
  color: #ffeff0;
}

.bg-orange-400 {
  background-color: #d5691b;
}

.color-orange-400 {
  color: #d5691b;
}

.bg-orange-300 {
  background-color: #ff9e2c;
}

.color-orange-300 {
  color: #ff9e2c;
}

.bg-orange-200 {
  background-color: #ffd19b;
}

.color-orange-200 {
  color: #ffd19b;
}

.bg-orange-100 {
  background-color: #fff3e4;
}

.color-orange-100 {
  color: #fff3e4;
}

.style---block-sub-heading {
  margin-bottom: 32px;
}

.style---block-sub-heading.border {
  margin-bottom: 64px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e9edf4;
}

.divider {
  height: 1px;
  margin-top: 80px;
  margin-bottom: 80px;
  background-color: #e9edf4;
}

.divider._64px {
  margin-top: 64px;
  margin-bottom: 64px;
}

.divider._64px.template-pages-start-here {
  margin-bottom: 40px;
}

.divider._40px {
  margin-top: 40px;
  margin-bottom: 40px;
}

.style---typography-block-grid {
  grid-column-gap: 40px;
  -ms-grid-columns: minmax(auto, 320px) 1fr;
  grid-template-columns: minmax(auto, 320px) 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.style---content-block {
  margin-bottom: 100px;
}

.mg-bottom-8px {
  margin-bottom: 8px;
}

.mg-bottom-16px {
  margin-bottom: 16px;
}

.mg-bottom-24px {
  margin-bottom: 24px;
}

.mg-bottom-32px {
  margin-bottom: 32px;
}

.mg-bottom-40px {
  margin-bottom: 40px;
}

.mg-bottom-48px {
  margin-bottom: 48px;
}

.mg-bottom-56px {
  margin-bottom: 56px;
}

.mg-top-16px {
  margin-top: 16px;
}

.mg-top-40px {
  margin-top: 40px;
}

.mg-top-48px {
  margin-top: 48px;
}

.mg-top-56px {
  margin-top: 56px;
}

.mg-top-64px {
  margin-top: 64px;
}

.mg-right-8px {
  margin-right: 8px;
}

.mg-right-16px {
  margin-right: 16px;
}

.mg-right-24px {
  margin-right: 24px;
}

.text-200 {
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 400;
}

.text-200.list {
  line-height: 24px;
}

.text-200.medium {
  font-weight: 500;
}

.text-200.bold {
  font-weight: 700;
}

.text-200.bold.color-neutral-800 {
  color: #024930;
  font-size: 20px;
}

.text-200.bold.color-neutral-700 {
  font-weight: 500;
}

.text-200.text-uppercase.form-space-text {
  margin-right: 12px;
  margin-left: 12px;
}

.display-1 {
  color: #181b1f;
  font-size: 80px;
  line-height: 1.175em;
  font-weight: 700;
}

.display-1.mg-bottom-7px.typing-01 {
  font-size: 80px;
}

.display-1.mg-bottom-7px.typing-02 {
  padding-left: 40px;
}

.grid-1-column {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-1-column.gap-row-0 {
  grid-row-gap: 0px;
}

.grid-1-column.open-positions-grid {
  grid-row-gap: 32px;
}

.grid-1-column.career-item-details-grid {
  grid-row-gap: 28px;
}

.gap-16px {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.gap-24px {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.gap-column-24px {
  grid-column-gap: 24px;
}

.gap-row-24px {
  grid-row-gap: 24px;
}

.gap-row-32px {
  grid-row-gap: 32px;
}

.gap-row-40px {
  grid-row-gap: 40px;
}

.gap-row-80px {
  grid-row-gap: 80px;
}

.display-2 {
  color: #181b1f;
  font-size: 62px;
  line-height: 1.258em;
  font-weight: 700;
}

.display-2.mg-bottom-0 {
  font-size: 49px;
}

.display-2.color-neutral-100 {
  color: #fff;
}

.display-3 {
  color: #181b1f;
  font-size: 38px;
  line-height: 1.263em;
  font-weight: 700;
}

.display-4 {
  color: #181b1f;
  font-size: 28px;
  line-height: 1.429em;
  font-weight: 700;
}

.style---style-grid-wrapper {
  grid-row-gap: 180px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.paragraph-large {
  font-size: 24px;
  line-height: 1.583em;
}

.paragraph-small {
  font-size: 14px;
  line-height: 1.714em;
}

.text-400 {
  font-size: 24px;
  line-height: 1.083em;
  font-weight: 400;
}

.text-400.medium {
  font-weight: 500;
}

.text-400.bold {
  font-weight: 700;
}

.text-300 {
  font-size: 20px;
  line-height: 1.1em;
  font-weight: 400;
}

.text-300.medium {
  font-weight: 500;
}

.text-300.bold {
  font-weight: 700;
}

.text-100 {
  padding-top: 40px;
  font-size: 16px;
  line-height: 1.125em;
  font-weight: 400;
}

.text-100.medium {
  font-weight: 500;
}

.text-100.bold {
  font-weight: 700;
}

.text-100.color-neutral-800 {
  padding-top: 0px;
}

.text-bold {
  color: #181b1f;
  font-weight: 700;
}

.text-link {
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #508dff;
  text-decoration: underline;
}

.text-link:hover {
  color: #181b1f;
}

.text-link.dark {
  color: #181b1f;
}

.text-link.dark:hover {
  color: #508dff;
}

.text-link.default {
  color: #707480;
}

.text-link.default:hover {
  color: #508dff;
}

.text-uppercase {
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.text-strikethrough {
  text-decoration: line-through;
}

.grid-3-columns {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-3-columns.gap-row-80px {
  grid-row-gap: 80px;
}

.grid-3-columns.style---buttons-grid {
  grid-column-gap: 70px;
  -ms-grid-columns: auto auto 1fr;
  grid-template-columns: auto auto 1fr;
}

.grid-3-columns.style---buttons-grid.download-grid {
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
}

.grid-3-columns.gap-16px {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.grid-3-columns.gap-24px {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid-3-columns.section-blog {
  grid-column-gap: 40px;
}

.grid-3-columns.values-grid {
  grid-column-gap: 24px;
  grid-row-gap: 32px;
}

.grid-3-columns.team-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.grid-3-columns.blog {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.grid-3-columns.pricing {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 0.9fr 1fr;
  grid-template-columns: 1fr 0.9fr 1fr;
}

.grid-3-columns.integrations-grid {
  grid-column-gap: 33px;
  grid-row-gap: 33px;
}

.grid-3-columns.integration-icons {
  max-width: 220px;
  margin-right: auto;
  margin-left: auto;
  grid-column-gap: 23px;
  -ms-grid-columns: 1fr 24px 1fr;
  grid-template-columns: 1fr 24px 1fr;
}

.grid-3-columns.careers-perks-grid {
  grid-column-gap: 62px;
  grid-row-gap: 75px;
}

.style---shadow-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 220px;
  padding: 32px 44px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  text-align: center;
}

.shadow-01 {
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
}

.shadow-02 {
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.shadow-03 {
  box-shadow: 0 8px 28px 0 rgba(24, 24, 31, 0.1);
}

.shadow-04 {
  box-shadow: 0 14px 42px 0 rgba(24, 24, 31, 0.14);
}

.shadow-05 {
  box-shadow: 0 24px 65px 0 rgba(24, 24, 31, 0.16);
}

.shadow-06 {
  box-shadow: 0 32px 72px 0 rgba(24, 24, 31, 0.24);
}

.button-shadow-color-01 {
  box-shadow: 0 4px 10px 0 rgba(89, 175, 255, 0.02);
}

.button-shadow-color-02 {
  box-shadow: 0 6px 20px 0 rgba(89, 175, 255, 0.08);
}

.button-shadow-color-03 {
  box-shadow: 0 10px 28px 0 rgba(89, 175, 255, 0.1);
}

.button-shadow-white-01 {
  box-shadow: 0 4px 10px 0 rgba(20, 22, 43, 0.04);
}

.button-shadow-white-02 {
  box-shadow: 0 6px 20px 0 rgba(20, 22, 43, 0.06);
}

.button-shadow-white-03 {
  box-shadow: 0 10px 28px 0 rgba(20, 22, 43, 0.1);
}

.style---heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 70vh;
  margin-bottom: 30px;
  padding: 72px 4vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.08);
}

.inner-container._600px {
  max-width: 600px;
}

.inner-container.center {
  margin-right: auto;
  margin-left: auto;
}

.inner-container._460px {
  max-width: 460px;
}

.inner-container._900px {
  max-width: 900px;
}

.inner-container._800px {
  max-width: 800px;
}

.inner-container._700px {
  max-width: 700px;
}

.inner-container._640px {
  max-width: 640px;
}

.inner-container._680px {
  max-width: 680px;
}

.inner-container._680px {
  max-width: 680px;
}

.inner-container._545px {
  max-width: 545px;
}

.inner-container._728px {
  max-width: 728px;
}

.inner-container._668px {
  max-width: 668px;
}

.inner-container._524px {
  max-width: 524px;
}

.inner-container._480px {
  max-width: 479px;
}

.inner-container._746px {
  max-width: 746px;
}

.inner-container._299px {
  max-width: 299px;
}

.inner-container.card-featured-feature-content {
  max-width: 45%;
  margin-right: 40px;
}

.inner-container._381px {
  max-width: 381px;
}

.inner-container._565px {
  max-width: 565px;
}

.inner-container._585px {
  max-width: 585px;
}

.inner-container._64px {
  max-width: 64px;
}

.inner-container._436px {
  max-width: 436px;
}

.inner-container._377px {
  max-width: 377px;
}

.inner-container._460px---mbl {
  padding-right: 0px;
}

.inner-container._1008px {
  max-width: 1008px;
}

.inner-container._794px {
  max-width: 794px;
}

.inner-container._530px {
  max-width: 530px;
}

.inner-container._499px {
  max-width: 499px;
}

.inner-container.card-career-item-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 527px;
  margin-right: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.inner-container.card-career-item-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 482px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.inner-container._438px {
  max-width: 438px;
}

.inner-container._452px {
  max-width: 452px;
}

.inner-container.newsletter-form-wrapper {
  width: 100%;
  max-width: 476px;
}

.inner-container._576px {
  max-width: 576px;
}

.inner-container._530px {
  max-width: 530px;
}

.inner-container._1000px {
  max-width: 1000px;
}

.inner-container._477px {
  max-width: 477px;
}

.inner-container._290px {
  max-width: 290px;
}

.inner-container._560px {
  max-width: 560px;
}

.inner-container._476px {
  max-width: 476px;
}

.inner-container._239px {
  max-width: 239px;
}

.inner-container.card-product-page-left {
  width: 100%;
  max-width: 467px;
}

.inner-container.card-product-page-right {
  width: 100%;
  max-width: 419px;
}

.inner-container._568px {
  max-width: 568px;
}

.inner-container._765px {
  max-width: 765px;
}

.inner-container._892px {
  max-width: 892px;
}

.inner-container._853px {
  max-width: 853px;
}

.inner-container._716px {
  max-width: 716px;
}

.inner-container._760px {
  max-width: 760px;
}

.inner-container._824px {
  max-width: 824px;
}

.inner-container._748px {
  max-width: 748px;
}

.inner-container.career-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 75px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.inner-container._726px {
  max-width: 726px;
}

.inner-container._570px {
  max-width: 570px;
}

.inner-container._458px {
  max-width: 458px;
}

.inner-container._521px {
  max-width: 521px;
}

.inner-container._848px {
  max-width: 848px;
}

.inner-container._517px {
  max-width: 517px;
}

.inner-container._470px {
  max-width: 470px;
}

.inner-container._546px {
  max-width: 546px;
}

.inner-container.not-found-content {
  max-width: 548px;
  min-width: 518px;
  margin-right: 40px;
}

.inner-container._510px {
  max-width: 510px;
}

.inner-container.centered-content {
  text-align: center;
}

.style---content-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  padding: 48px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.08);
}

.style---heading-icon-wrapper {
  margin-right: 18px;
  border-radius: 20px;
  background-color: #508dff;
}

.mg-bottom-0 {
  margin-bottom: 0px;
}

.style---content-wrapper {
  padding-top: 40px;
  padding-right: 4vw;
  padding-left: 4vw;
}

.border-radius-16px {
  border-radius: 16px;
}

.border-radius-16px.width-100 {
  width: 60%;
}

.border-radius-32px {
  border-radius: 32px;
}

.style---card-wrapper {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 7px 0 rgba(24, 24, 31, 0.06);
}

.style---components-wrapper {
  border-radius: 24px;
  background-color: #fff;
}

.style---bg-white {
  padding: 48px;
}

.style-buttons-dark {
  padding: 48px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #181b1f;
}

.btn-primary {
  position: relative;
  overflow: hidden;
  padding: 22px 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 48px;
  background-color: #508dff;
  box-shadow: 0 1px 12px 0 rgba(24, 24, 31, 0.16), 0 3px 16px 0 rgba(89, 175, 255, 0.02);
  -webkit-transition: box-shadow 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, background-color 300ms ease, transform 300ms ease, color 300ms ease;
  transition: box-shadow 300ms ease, background-color 300ms ease, transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  color: #fff;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.btn-primary:hover {
  background-color: #50b5ff;
  box-shadow: 0 0 0 0 rgba(24, 24, 31, 0.16), 0 0 0 0 rgba(89, 175, 255, 0.02);
  -webkit-transform: translate3d(0px, 3px, 0.01px);
  transform: translate3d(0px, 3px, 0.01px);
  color: #fff;
}

.btn-primary.small {
  padding: 16px 20px;
  font-size: 16px;
  line-height: 1.125em;
}

.btn-primary.small.white {
  color: #181b1f;
}

.btn-primary.small.white:hover {
  background-color: #f1f3f8;
  color: #181b1f;
}

.btn-primary.small.header-btn-hidde-on-mb {
  margin-left: 24px;
}

.btn-primary.small.discount {
  height: auto;
  margin-left: 0px;
  background-color: transparent;
  box-shadow: none;
}

.btn-primary.small.discount:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.btn-primary.large {
  padding: 24px 44px;
  font-size: 20px;
  line-height: 1.1em;
}

.btn-primary.white {
  background-color: #fff;
  color: #508dff;
}

.btn-primary.white:hover {
  background-color: #508dff;
  color: #fff;
}

.btn-primary.white.no-hover {
  color: #4253ff;
}

.btn-primary.white.no-hover:hover {
  background-color: #fff;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.btn-primary.inside-input {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-primary.inside-input.default {
  top: 8px;
  right: 8px;
  bottom: 8px;
}

.btn-primary.button-row {
  margin-right: 28px;
}

.btn-primary.download-app {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.btn-primary.no-hover:hover {
  background-color: #508dff;
}

.btn-primary.pagination {
  margin-top: 60px;
}

.btn-primary.form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-primary.form-btn.inside-input {
  padding: 0px;
}

.btn-primary.form-btn.inside-input.default {
  padding: 0px;
}

.btn-primary.form-btn.inside-input.default:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.btn-primary.form-btn.btn-pd-none {
  padding: 0px;
}

.btn-primary.form-btn-inside {
  padding: 0px;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
}

.btn-primary.form-btn-inside:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.btn-primary.form-btn-inside.pd-default {
  padding: 20px 32px;
}

.btn-primary.form-btn-inside.pd-default.checkout-btn {
  width: 100%;
  margin-bottom: 0px;
}

.btn-primary.smaller {
  padding: 13px 20px;
  font-size: 16px;
}

.btn-secondary {
  padding: 22px 32px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 48px;
  background-color: #fff;
  box-shadow: inset 0 1px 6px 0 rgba(24, 23, 72, 0.07), 0 4px 10px 0 rgba(20, 22, 43, 0.04);
  -webkit-transition: box-shadow 300ms ease, border-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, border-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: box-shadow 300ms ease, border-color 300ms ease, transform 300ms ease, color 300ms ease;
  transition: box-shadow 300ms ease, border-color 300ms ease, transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  color: #181b1f;
  font-size: 18px;
  line-height: 1.111em;
  text-align: center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.btn-secondary:hover {
  border-color: #e9edf4;
  box-shadow: inset 0 1px 2px 0 rgba(24, 23, 72, 0.07), 0 0 0 0 rgba(20, 22, 43, 0.04);
  -webkit-transform: translate3d(0px, 3px, 0.01px);
  transform: translate3d(0px, 3px, 0.01px);
  color: #508dff;
}

.btn-secondary.small {
  padding: 16px 20px;
  font-size: 16px;
  line-height: 1.125em;
}

.btn-secondary.large {
  padding: 24px 44px;
  font-size: 20px;
  line-height: 1.1em;
}

.btn-secondary.white {
  background-color: transparent;
  color: #fff;
}

.btn-secondary.white:hover {
  border-color: #fff;
  background-color: #fff;
  color: #181b1f;
}

.btn-secondary.width-100.log-social-media {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.btn-secondary.pagination {
  margin-top: 60px;
}

.badge-primary {
  display: inline-block;
  padding: 18px 28px;
  border-radius: 40px;
  background-color: #508dff;
  color: #fff;
  font-size: 18px;
  line-height: 1.111em;
  text-align: center;
  text-decoration: none;
}

.badge-primary.white {
  background-color: #fff;
  color: #508dff;
}

.badge-primary.light {
  background-color: #181b1f;
}

.badge-primary.small {
  padding: 14px 22px;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-primary.large {
  padding: 22px 38px;
  font-size: 20px;
  line-height: 1.1em;
}

.badge-primary.nav-link---badge {
  display: inline-block;
  padding: 7px 8px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#508dff), to(#50b5ff));
  background-image: linear-gradient(180deg, #508dff, #50b5ff);
  font-size: 11px;
  line-height: 1em;
  font-weight: 700;
}

.badge-secondary {
  display: inline-block;
  padding: 18px 28px;
  border-style: solid;
  border-width: 1px;
  border-color: #508dff;
  border-radius: 40px;
  background-color: #fff;
  color: #508dff;
  font-size: 18px;
  line-height: 1.111em;
  text-align: center;
  text-decoration: none;
}

.badge-secondary.white {
  border-color: #fff;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.badge-secondary.light {
  border-width: 0px;
  background-color: #f7f9fc;
  box-shadow: none;
}

.badge-secondary.small {
  padding: 14px 22px;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-secondary.large {
  padding: 22px 38px;
  font-size: 20px;
  line-height: 1.1em;
}

.badge-secondary.category-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: box-shadow 300ms ease, border-color 300ms ease, background-color 300ms ease, color 300ms ease;
  transition: box-shadow 300ms ease, border-color 300ms ease, background-color 300ms ease, color 300ms ease;
  font-size: 16px;
  line-height: 1.125em;
  text-align: left;
}

.badge-secondary.category-link:hover {
  background-color: #508dff;
  color: #fff;
}

.badge-secondary.category-link.w--current {
  border-color: transparent;
  background-color: #508dff;
  box-shadow: 0 1px 2px 0 rgba(21, 137, 244, 0.31), 0 1px 1px 0 rgba(47, 46, 116, 0.11), 0 1px 1px 0 hsla(0, 0%, 100%, 0.47);
  color: #fff;
  font-weight: 700;
}

.badge-secondary.tab-btn {
  padding-top: 16px;
  padding-bottom: 16px;
  background-color: transparent;
  -webkit-transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, border-color 300ms ease, background-color 300ms ease, color 300ms ease;
  transition: transform 300ms ease, border-color 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  font-size: 16px;
  line-height: 1.125em;
}

.badge-secondary.tab-btn:hover {
  background-color: #508dff;
  color: #fff;
}

.badge-secondary.tab-btn.w--current {
  background-color: #508dff;
  color: #fff;
  font-weight: 700;
}

.input {
  min-height: 64px;
  margin-bottom: 0px;
  padding: 23px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 100px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
  -webkit-transition: box-shadow 300ms ease, color 300ms ease, border-color 300ms ease;
  transition: box-shadow 300ms ease, color 300ms ease, border-color 300ms ease;
  color: #181b1f;
  font-size: 16px;
  line-height: 1.125em;
}

.input:hover {
  border-color: #508dff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.input:focus {
  border-color: #508dff;
  color: #181b1f;
}

.input.-wfp-focus {
  border-color: #508dff;
  color: #181b1f;
}

.input::-webkit-input-placeholder {
  color: #707480;
}

.input:-ms-input-placeholder {
  color: #707480;
}

.input::-ms-input-placeholder {
  color: #707480;
}

.input::placeholder {
  color: #707480;
}

.input.small {
  min-height: 46px;
  margin-bottom: 0px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.input.small::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.small:-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.small::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.small::placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.large {
  min-height: 76px;
}

.input.button-inside {
  min-height: 72px;
  padding-right: 175px;
}

.input.mg-bottom-16px {
  margin-bottom: 16px;
}

.input.cart-quantity-input {
  width: 70px;
  min-height: 40px;
  padding: 10px 16px;
}

.input.medium {
  min-height: 56px;
  margin-bottom: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
}

.input.medium::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.medium:-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.medium::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.medium::placeholder {
  font-size: 16px;
  line-height: 18px;
}

.input.medium.last {
  margin-bottom: 0px;
}

.input.icon-left {
  padding-left: 49px;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 23px 50%;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

.input.select-inside {
  height: auto;
  min-height: auto;
  margin-left: -3px;
  padding: 0px;
  border-width: 0px;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  color: #707480;
}

.input.select-inside:focus {
  color: #181b1f;
}

.input.select {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.position-relative.hero-v2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.position-relative.careers-testimonials-wrapper {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.text-area {
  max-height: 200px;
  max-width: 100%;
  min-height: 144px;
  min-width: 100%;
  margin-bottom: 0px;
  padding: 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 22px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
  -webkit-transition: box-shadow 300ms ease, color 300ms ease, border-color 300ms ease;
  transition: box-shadow 300ms ease, color 300ms ease, border-color 300ms ease;
  color: #181b1f;
  font-size: 18px;
  line-height: 1.667em;
}

.text-area:hover {
  border-color: #508dff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
}

.text-area:focus {
  border-color: #508dff;
  color: #181b1f;
}

.text-area::-webkit-input-placeholder {
  color: #707480;
}

.text-area:-ms-input-placeholder {
  color: #707480;
}

.text-area::-ms-input-placeholder {
  color: #707480;
}

.text-area::placeholder {
  color: #707480;
}

.text-area.small {
  min-height: 102px;
  margin-bottom: 24px;
  font-size: 16px;
}

.text-area.small::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.small:-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.small::-ms-input-placeholder {
  font-size: 16px;
  line-height: 18px;
}

.text-area.small::placeholder {
  font-size: 16px;
  line-height: 18px;
}



.checkbox-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 22px;
}

.checkbox-field-wrapper.large {
  font-size: 20px;
  line-height: 24px;
}

.checkbox-field-wrapper.small {
  font-size: 16px;
  line-height: 20px;
}

.checkbox {
  width: auto;
  height: auto;
  min-height: 24px;
  min-width: 24px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  border-width: 1px;
  border-color: #e9edf4;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
  -webkit-transition: border-color 300ms ease, background-color 300ms ease;
  transition: border-color 300ms ease, background-color 300ms ease;
}

.checkbox:hover {
  border-color: #508dff;
}


.checkbox.w--redirected-focus {
  box-shadow: none;
}

.checkbox.large {
  min-height: 28px;
  min-width: 28px;
}

.checkbox.small {
  min-height: 22px;
  min-width: 22px;
  border-radius: 5px;
}

.radio-button-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 24px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 24px;
}

.radio-button-field-wrapper.large {
  font-size: 20px;
  line-height: 26px;
}

.radio-button-field-wrapper.small {
  font-size: 16px;
  line-height: 22px;
}

.radio-button {
  width: auto;
  height: auto;
  min-height: 24px;
  min-width: 24px;
  margin-top: 0px;
  margin-right: 10px;
  margin-left: 0px;
  border-color: #e9edf4;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
  -webkit-transition: border-color 300ms ease, border-width 300ms ease, background-color 300ms ease;
  transition: border-color 300ms ease, border-width 300ms ease, background-color 300ms ease;
}

.radio-button:hover {
  border-color: #508dff;
}

.radio-button.w--redirected-checked {
  border-width: 6px;
  border-color: #508dff;
}

.radio-button.w--redirected-focus {
  box-shadow: none;
}

.radio-button.large {
  min-height: 28px;
  min-width: 28px;
}

.radio-button.large.w--redirected-checked {
  border-width: 8px;
}

.radio-button.small {
  min-height: 22px;
  min-width: 22px;
}

.style---avatars-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 290px);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.avatar-circle {
  overflow: hidden;
  width: 100%;
  border-radius: 50%;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.avatar-circle._01 {
  width: 32px;
  height: 32px;
  max-height: 32px;
  max-width: 32px;
}

.avatar-circle._02 {
  width: 48px;
  height: 48px;
  max-height: 48px;
  max-width: 48px;
}

.avatar-circle._03 {
  width: 64px;
  height: 64px;
  max-height: 64px;
  max-width: 64px;
}

.avatar-circle._04 {
  width: 80px;
  height: 80px;
  max-height: 80px;
  max-width: 80px;
}

.avatar-circle._05 {
  width: 120px;
  height: 120px;
  max-height: 120px;
  max-width: 120px;
}

.avatar-circle._06 {
  width: 160px;
  height: 160px;
  max-height: 160px;
  max-width: 160px;
}

.avatar-circle._07 {
  width: 240px;
  height: 240px;
  max-height: 240px;
  max-width: 240px;
}

.avatar-circle._08 {
  width: 380px;
  height: 380px;
  max-height: 380px;
  max-width: 380px;
}

.style---icons-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 72px);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.style---social-square-icons-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 36px);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.social-icon-font {
  font-family: 'Social Icons Font', sans-serif;
  line-height: 21px;
}

.social-icon-font.button-left {
  margin-right: 6px;
}

.social-icon-square {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 38px;
  height: 38px;
  min-height: 38px;
  min-width: 38px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#508dff), color-stop(60%, #50b5ff));
  background-image: linear-gradient(180deg, #508dff, #50b5ff 60%);
  box-shadow: 0 2px 7px 0 rgba(24, 24, 31, 0.08);
  -webkit-transition: border-color 300ms ease, box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: border-color 300ms ease, box-shadow 300ms ease, -webkit-transform 300ms ease;
  transition: border-color 300ms ease, box-shadow 300ms ease, transform 300ms ease;
  transition: border-color 300ms ease, box-shadow 300ms ease, transform 300ms ease, -webkit-transform 300ms ease;
  text-decoration: none;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.social-icon-square:hover {
  border-color: #508dff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#508dff), color-stop(60%, #50b5ff));
  background-image: linear-gradient(180deg, #508dff, #50b5ff 60%);
  box-shadow: 0 0 0 0 rgba(24, 24, 31, 0.08);
  -webkit-transform: translate3d(0px, 3px, 0.01px);
  transform: translate3d(0px, 3px, 0.01px);
}

.social-icon-square.size-10px {
  width: 10px;
}

.line-rounded-icon {
  font-family: 'Line Rounded Icons', sans-serif;
}

.line-rounded-icon.style---dropdown-arrow-sidebar {
  line-height: 18px;
}

.line-rounded-icon.link-icon-right {
  display: inline-block;
  margin-left: 6px;
}

.line-rounded-icon.link-icon-left {
  display: inline-block;
  margin-right: 6px;
}

.line-rounded-icon.dropdown-arrow {
  margin-left: 8px;
}

.line-rounded-icon.success-message-check {
  margin-right: 10px;
  margin-bottom: 6px;
  color: #508dff;
  font-size: 35px;
}

.line-rounded-icon.success-message-check.large {
  margin-right: 0px;
  margin-bottom: 16px;
  font-size: 80px;
  line-height: 1em;
}

.style---line-icons-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 20px);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.line-square-icon {
  font-family: 'Line Square Icons', sans-serif;
}

.line-square-icon.top-bar-close-icon {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
  cursor: pointer;
}

.line-square-icon.top-bar-close-icon:hover {
  opacity: 0.55;
}

.filled-icons {
  font-family: 'Filled Icons', sans-serif;
}

.rich-text img {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 13px;
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
}

.rich-text p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rich-text h2 {
  margin-bottom: 16px;
}

.rich-text h3 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.rich-text ul {
  margin-bottom: 40px;
}

.rich-text ol {
  margin-bottom: 40px;
}

.rich-text h6 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text h5 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text h4 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text blockquote {
  margin-top: 56px;
  margin-bottom: 56px;
}

.style---dropdown-wrapper-sidebar {
  overflow: hidden;
  width: 100%;
}

.style---dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 18px 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #f1f3f8;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}

.style---dropdown-toggle:hover {
  color: #508dff;
}

.style---dropdown-toggle.w--open {
  color: #508dff;
}

.buttons-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 22px;
  grid-row-gap: 16px;
}

.buttons-row.center {
  display: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.buttons-row.add-cart-buttons {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.style---dropdown-list-sidebar {
  position: relative;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #f1f3f8;
  background-color: #fff;
}

.style---dropdown-link-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 12px 16px 12px 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 4px solid transparent;
  -webkit-transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease;
  transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease;
  color: #707480;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
}

.style---dropdown-link-sidebar:hover {
  border-right-color: #508dff;
  background-color: #f7f9fc;
  color: #508dff;
}

.style---dropdown-link-sidebar.w--current {
  border-right-color: #508dff;
  background-color: #f1f3f8;
  color: #508dff;
  font-weight: 700;
}

.style---dropdown-link-icon-sidebar {
  max-width: 38px;
  margin-right: 10px;
  padding: 2px;
  border-radius: 8px;
  background-color: #508dff;
}

.style---sidebar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.style---logo-wrapper {
  width: 100%;
  padding: 38px 22px;
  border-bottom: 1px solid #f1f3f8;
  background-color: #f7f9fc;
  color: #707480;
  text-align: center;
  text-decoration: none;
}

.width-100 {
  width: 100%;
}

.style---button-wrapper-sidebar {
  margin-top: 20px;
  margin-bottom: 24px;
  padding-right: 20px;
  padding-left: 20px;
}

.style---logo-sidebar {
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.style---logo-sidebar:hover {
  opacity: 0.6;
}

.container-default {
  max-width: 1224px;
  padding-right: 24px;
  padding-left: 24px;
}

.container-default.order-confirmation {
  display: block;
}

.container-default.password-page {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.elements---page-hero {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f7f9fc;
}

.section {
  padding-top: 0px;
  padding-bottom: 100px;
}

.section.small {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section.overflow-hidden {
  padding-top: 50px;
  padding-bottom: 50px;
}

.section.template-pages-hero {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f7f9fc;
}

.section.template-pages-hero.short {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section.hero-page {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 200px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(46%, #fff), color-stop(0, #508dff), color-stop(81%, #50b5ff));
  background-image: linear-gradient(180deg, #fff 46%, #508dff 0, #50b5ff 81%);
}

.section.cta-home-pages {
  padding-top: 0px;
}

.section.hero {
  overflow: hidden;
}

.section.hero.v1 {
  padding-top: 67px;
  padding-bottom: 160px;
}

.section.hero.v2 {
  padding-top: 252px;
  padding-bottom: 160px;
}

.section.hero.v3 {
  overflow: visible;
  padding-top: 100px;
  padding-bottom: 80px;
}

.section.hero.v4 {
  overflow: visible;
  padding-top: 118px;
  padding-bottom: 99px;
}

.section.hero.v5 {
  overflow: visible;
  padding-top: 100px;
  padding-bottom: 188px;
}

.section.hero.v5.overflow-hidden {
  overflow: hidden;
}

.section.hero.v6 {
  overflow: visible;
  padding-top: 120px;
  padding-bottom: 90px;
}

.section.hero.v7 {
  overflow: visible;
  padding-top: 120px;
  padding-bottom: 105px;
}

.section.hero.v8 {
  overflow: visible;
  padding-top: 120px;
  padding-bottom: 135px;
}

.section.hero.v9 {
  overflow: visible;
  padding-top: 120px;
  padding-bottom: 120px;
}

.section.hero.v10 {
  overflow: visible;
  padding-top: 120px;
  padding-bottom: 150px;
}

.section.hero.v11 {
  overflow: visible;
  padding-top: 156px;
  padding-bottom: 156px;
}

.section.hero.v11.coming-soon-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section.pd-100px {
  padding-top: 83px;
  padding-bottom: 83px;
}

.section.pd-bottom-240px {
  padding-top: 50px;
  padding-bottom: 200px;
  background-color: transparent;
}

.section.pd-bottom-160px {
  padding-bottom: 128px;
}

.section.pd-bottom-280px {
  padding-bottom: 224px;
}

.section.pd-200px {
  padding-top: 168px;
  padding-bottom: 168px;
}

.section.pd-top-100px {
  padding-top: 83px;
}

.section.pd-90px {
  padding-top: 80px;
  padding-bottom: 80px;
}

.section.pd-top-0px {
  padding-top: 0px;
}

.section.pd-180px {
  padding-top: 150px;
  padding-bottom: 150px;
}

.section.pd-top-90px {
  padding-top: 80px;
}

.section.pd-bottom-224px {
  padding-bottom: 180px;
}

.section.pd-148px {
  padding-top: 123px;
  padding-bottom: 123px;
}

.section.pd-148px.pd-top-0px {
  padding-top: 0px;
}

.section.pd-bottom-80px {
  padding-bottom: 70px;
}

.section.pd-top-80px {
  padding-top: 70px;
}

.section.pd-bottom-0px {
  padding-bottom: 0px;
}

.section.pd-150px {
  padding-top: 125px;
  padding-bottom: 125px;
}

.section.pd-150px.pd-top-0px {
  padding-top: 0px;
}

.section.space {
  padding-top: 40px;
}

.top-bar-wrapper {
  position: relative;
  overflow: hidden;
  padding-right: 72px;
  padding-left: 72px;
  background-color: #508dff;
  background-image: -webkit-gradient(linear, left top, right top, from(#508dff), to(#50b5ff));
  background-image: linear-gradient(90deg, #508dff, #50b5ff);
  color: #fff;
  text-align: center;
}

.top-bar-padding {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.top-bar-padding.small-padding {
  padding-top: 22px;
  padding-bottom: 22px;
}

.top-bar-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-bar-flex.space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.link-wrapper {
  display: inline-block;
  color: #181b1f;
  line-height: 1.111em;
  font-weight: 700;
}

.link-wrapper:hover {
  color: #508dff;
}

.link-wrapper.color-primary {
  color: #508dff;
}

.link-wrapper.color-primary:hover {
  color: #181b1f;
}

.link-wrapper.white {
  color: #fff;
}

.link-wrapper.white:hover {
  color: #2a7fff;
}

.link-wrapper.white.text-bold.hover-white:hover {
  color: #fff;
}

.link-text {
  display: inline-block;
}

.link-text.read-more {
  color: #11845b;
}

.elements---element-title {
  margin-top: 72px;
  margin-bottom: 32px;
  padding: 24px 32px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 rgba(24, 24, 31, 0.06);
}

.elements---element-title.first {
  margin-top: 0px;
}

.elements---element-title.first.large {
  margin-top: 0px;
}

.elements---element-title.large {
  margin-top: 266px;
  margin-bottom: 54px;
}

.heading-h1-size {
  color: #181b1f;
  font-size: 54px;
  line-height: 1.222em;
  font-weight: 700;
}

.heading-h2-size {
  color: #181b1f;
  font-size: 38px;
  line-height: 1.316em;
  font-weight: 700;
}

.heading-h3-size {
  color: #181b1f;
  font-size: 24px;
  line-height: 1.417em;
  font-weight: 700;
}

.heading-h3-size.mg-bottom-32px {
  font-size: 21px;
  font-weight: 500;
}

.heading-h4-size {
  color: #181b1f;
  font-size: 22px;
  line-height: 1.273em;
  font-weight: 700;
}

.heading-h5-size {
  color: #181b1f;
  font-size: 18px;
  line-height: 1.333em;
  font-weight: 700;
}

.heading-h5-size.color-neutral-600 {
  color: #707480;
}

.heading-h6-size {
  color: #181b1f;
  font-size: 16px;
  line-height: 1.375em;
  font-weight: 700;
}

.header-wrapper {
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: transparent;
}

.header-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-nav-menu-list {
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  list-style-type: none;
  margin-left: 100px;
}

.header-nav-link {
  padding: 0px;
  color: #181b1f;
  font-size: 18px;
  line-height: 1.111em;
}

.header-nav-link:hover {
  color: #508dff;
}

.header-nav-link.dropdown-header-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.header-nav-link.cart-link {
  background-color: transparent;
}

.header-nav-list-item {
  margin-bottom: 0px;
  padding-left: 32px;
}

.header-nav-list-item.show-in-tablet {
  display: none;
}

.header-nav-list-item.middle {
  padding-right: 13px;
  padding-left: 13px;
  line-height: 1.111em;
}

.dropdown-column-wrapper {
  width: 680px;
  background-color: transparent;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.dropdown-column-wrapper.w--open {
  width: 300px;
  max-width: 311px;
}

.header-logo {
  width: 174px;
  margin-left: -5px;
}

.header-logo-link {
  width: 100%;
  max-width: 182px;
  padding-left: 0px;
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.header-logo-link:hover {
  -webkit-transform: scale3d(0.96, 0.96, 1.01);
  transform: scale3d(0.96, 0.96, 1.01);
}

.header-logo-link.left {
  margin-right: 40px;
}

.hamburger-menu-btn {
  padding: 0px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.hamburger-menu-btn.w--open {
  background-color: transparent;
}

.hamburger-menu-bar {
  width: 52px;
  border-radius: 20px;
  background-color: #181b1f;
}

.header-right-side {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 10%;
}

.header-middle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-middle.header-small {
  margin-right: auto;
  margin-left: auto;
}

.header-left-side {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.btn-circle-primary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #508dff;
  -webkit-transition: background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, background-color 300ms ease, color 300ms ease;
  transition: transform 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  color: #fff;
  font-size: 26px;
  line-height: 1em;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.btn-circle-primary:hover {
  background-color: #50b5ff;
  -webkit-transform: scale3d(0.94, 0.94, 1.01);
  transform: scale3d(0.94, 0.94, 1.01);
  color: #fff;
}

.btn-circle-primary.small {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  font-size: 22px;
}

.btn-circle-primary.large {
  width: 88px;
  height: 88px;
  min-height: 88px;
  min-width: 88px;
  font-size: 30px;
  line-height: 32px;
}

.btn-circle-primary.white {
  background-color: #fff;
  color: #508dff;
}

.btn-circle-primary.white:hover {
  background-color: #508dff;
  color: #fff;
}

.btn-circle-secondary {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
  -webkit-transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: border-color 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  transition: border-color 300ms ease, transform 300ms ease, background-color 300ms ease, color 300ms ease;
  transition: border-color 300ms ease, transform 300ms ease, background-color 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  color: #181b1f;
  font-size: 26px;
  line-height: 1em;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.btn-circle-secondary:hover {
  border-color: #e9edf4;
  box-shadow: 0 0 0 0 rgba(24, 24, 31, 0.08);
  -webkit-transform: scale3d(0.94, 0.94, 1.01);
  transform: scale3d(0.94, 0.94, 1.01);
  color: #508dff;
}

.btn-circle-secondary.small {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  font-size: 22px;
}

.btn-circle-secondary.large {
  width: 88px;
  height: 88px;
  min-height: 88px;
  min-width: 88px;
  font-size: 30px;
}

.btn-circle-secondary.white {
  border-color: #fff;
  background-color: transparent;
  box-shadow: none;
  color: #fff;
}

.btn-circle-secondary.white:hover {
  border-color: #508dff;
  background-color: #508dff;
}

.social-media-grid-top {
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  justify-items: start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-template-columns: repeat(auto-fit, 38px);
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.social-media-grid-top.right {
  -webkit-box-pack: end;
  -webkit-justify-content: end;
  -ms-flex-pack: end;
  justify-content: end;
  justify-items: end;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.social-media-grid-top.team-social-media {
  grid-template-columns: repeat(auto-fit, 20px);
}

.social-media-grid-top.contact-social-media {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-columns: repeat(auto-fit, 22px);
}

.flex-vertical {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-vertical.center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-vertical.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-vertical.small-page {
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex-horizontal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-horizontal.space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-horizontal.children-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex-horizontal.align-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal.align-center.justify-center---tablet {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-row-gap: 10px;
}

.flex-horizontal.hero-v1 {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.flex-horizontal.footer-cta {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal.card-newsletter {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-horizontal.category-title {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 14px;
}

.flex-horizontal.card-product-page-title {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 12px;
}

.flex-horizontal.card-product-page-title-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.flex-horizontal.integration-title {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 18px;
}

.flex-horizontal.investors-top {
  margin-bottom: 24px;
  margin-left: 74px;
}

.flex-horizontal.investors-bottom {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer-wrapper {
  background-color: #f7f9fc;
}

.footer-wrapper.small {
  margin-top: auto;
  border-top: 1px solid rgba(177, 183, 197, 0.29);
  background-color: #fff;
}

.grid-footer-v1---5-column {
  grid-column-gap: 80px;
  -ms-grid-columns: 0.8fr 1fr auto;
  grid-template-columns: 0.8fr 1fr auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-logo {
  width: 100%;
}

.footer-logo-wrapper {
  width: 100%;
  max-width: 400px;
  margin-bottom: 28px;
  margin-left: -5px;
}

.footer-logo-wrapper.mg-bottom-0 {
  margin-bottom: 0px;
}

.nav-menu-list-wrapper {
  margin-right: 20px;
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}

.nav-menu-list-wrapper.last {
  margin-right: 0px;
}

.nav-menu-list-item {
  margin-bottom: 20px;
  padding-left: 0px;
  line-height: 1.111em;
}

.nav-link {
  display: inline-block;
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  color: #707480;
  line-height: 1.111em;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.nav-link:hover {
  -webkit-transform: translate3d(6px, 0px, 0.01px);
  transform: translate3d(6px, 0px, 0.01px);
  color: #508dff;
}

.nav-link.nav-link-badge {
  height: 21px;
}

.nav-link.accent {
  color: #508dff;
  font-weight: 700;
}

.nav-link.accent:hover {
  color: #181b1f;
}

.footer-top {
  padding-top: 74px;
  padding-bottom: 60px;
}

.footer-bottom {
  padding-top: 28px;
  padding-bottom: 28px;
}

.footer-bottom.center {
  text-align: center;
}

.footer-middle {
  padding-top: 92px;
  padding-bottom: 92px;
}

.success-message {
  padding: 0px;
  background-color: transparent;
  color: #181b1f;
  font-weight: 700;
}

.error-message {
  margin-top: 20px;
  padding: 0px;
  background-color: transparent;
  color: #ff5a65;
  font-weight: 500;
}

.error-message.password {
  text-align: center;
}

.error-message.cart-error {
  margin-top: 0px;
  text-align: center;
}

.text-left {
  text-align: left;
}

.grid-subscribe-form-button-down {
  grid-row-gap: 14px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.grid-subscribe-form-button-down.coming-soon-form-grid {
  grid-row-gap: 23px;
}

.text-center {
  text-align: center;
}

.text-center.mobile-l {
  text-align: left;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.opacity-90 {
  opacity: 0.9;
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

.accordion-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.acordion-body {
  overflow: hidden;
  width: 100%;
}

.accordion-spacer {
  min-height: 17px;
}

.accordion-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.accordion-icon-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 14px;
  height: 14px;
  min-height: 14px;
  min-width: 14px;
  margin-left: 14px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.accordion-title {
  margin-bottom: 0px;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tabs-menu.integration-menu {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.tabs-content {
  overflow: visible;
}

.empty-state {
  padding: 26px 24px;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(24, 24, 31, 0.08);
  text-align: center;
}

.empty-state.small {
  padding: 20px;
  font-size: 16px;
  line-height: 1.125em;
}

.empty-state.card-empty {
  padding-right: 0px;
  padding-left: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
}

.empty-state.card-empty.pricing {
  margin-right: 40px;
  margin-left: 40px;
}

.empty-state.cart-empty {
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pd---content-inside-card {
  padding: 40px 28px 48px;
}

.pd---content-inside-card.large {
  padding: 64px 56px;
}

.pd---content-inside-card.large.template-pages {
  padding-top: 54px;
  padding-right: 54px;
  padding-left: 54px;
}

.pd---content-inside-card.template-pages---sidebar {
  padding: 32px 16px 24px;
}

.flex-growth {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image.cover {
  width: 80%;
  height: 100%;
  max-width: 80%;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: cover;
  object-fit: cover;
}

.image.cover.card-cta-v1-image {
  position: relative;
  left: -20%;
  width: 184.5%;
  max-width: none;
  margin-top: -22px;
  margin-bottom: -130px;
  -o-object-position: 0% 50%;
  object-position: 0% 50%;
}

.image.cover.blog {
  width: 100%;
  max-width: 100%;
  margin-top: 0px;
}

.mg-bottom-4px {
  margin-bottom: 4px;
}

.height-100 {
  height: 100%;
}

.pricing-content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-height: 55px;
  min-height: 55px;
  margin-bottom: 8px;
  padding-top: 18px;
  padding-bottom: 18px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-content-wrapper.left {
  padding: 18px 28px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pricing-content-wrapper.left.accent {
  padding-bottom: 18px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pricing-content-wrapper.left.content-last {
  padding-right: 28px;
  padding-left: 28px;
}

.pricing-content-wrapper.left.title {
  background-color: rgba(127, 220, 164, 0.81);
  background-image: linear-gradient(88deg, #7fdca4, hsla(0, 0%, 100%, 0.27) 100%, rgba(127, 220, 164, 0.13) 0, #fcfefd);
}

.pricing-content-wrapper.empty.title {
  background-color: rgba(127, 220, 164, 0.59);
}

.pricing-content-wrapper.accent {
  background-color: #f7f9fc;
}

.pricing-content-wrapper.accent.last {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pricing-content-wrapper.title {
  margin-bottom: 0px;
}

.pricing-content-wrapper.space {
  max-height: 20px;
  min-height: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.pricing-content-wrapper.content-last {
  max-height: 80px;
  min-height: 80px;
  margin-bottom: 0px;
  padding-right: 33px;
  padding-bottom: 0px;
  padding-left: 33px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.image-wrapper.order-item-image-wrapper {
  width: 100%;
  max-width: 50px;
  margin-right: 16px;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.image-wrapper.order-item-image-wrapper:hover {
  -webkit-transform: scale3d(0.95, 0.95, 1.01) translate(0px, 0px);
  transform: scale3d(0.95, 0.95, 1.01) translate(0px, 0px);
}

.image-wrapper.border-radius-20px {
  overflow: hidden;
}

.image-wrapper.hero-v1 {
  width: 100%;
  max-width: 590px;
  margin: 0px;
}

.image-wrapper.card-featured-feature-image {
  width: 100%;
  max-width: 500px;
  margin: -8px -20px -38px;
}

.image-wrapper.card-feature-image._01 {
  width: 100%;
  max-width: 176px;
  margin: -17px -18px -36px;
}

.image-wrapper.card-feature-image._03 {
  width: 100%;
  max-width: 154px;
  margin-right: -20px;
  margin-bottom: -26px;
  margin-left: -14px;
}

.image-wrapper.card-feature-image._02 {
  width: 100%;
  max-width: 258px;
  margin: -12px -12px -18px -17px;
}

.image-wrapper.card-advantage-01-image {
  position: relative;
}

.image-wrapper.card-advantage-02-image {
  position: relative;
  width: 100%;
  max-width: 91%;
  margin: -18px -30px -65px;
}

.image-wrapper.card-advantage-03-image {
  position: relative;
  width: 100%;
  max-width: 95%;
  margin: -21px -31px -37px -32px;
}

.image-wrapper.card-advantage-04-image {
  position: relative;
  width: 100%;
  max-width: 95%;
  margin: -23px -34px -40px;
}

.image-wrapper.step-image._01 {
  width: 100%;
  max-width: 182px;
  margin: -13px -18px -24px;
}

.image-wrapper.step-image._03 {
  width: 100%;
  max-width: 200px;
  margin: -16px -14px -22px -24px;
}

.image-wrapper.step-image._02 {
  width: 100%;
  max-width: 199px;
  margin: -14px -16px -28px -21px;
}

.image-wrapper.step-image._04 {
  width: 100%;
  max-width: 180px;
  margin: -14px -21px -22px;
}

.image-wrapper.card-cta-v1-image {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.image-wrapper.circle-image {
  overflow: hidden;
  border-radius: 50%;
}

.image-wrapper.circle-image.v1 {
  box-shadow: 0 2px 6px 0 rgba(24, 24, 31, 0.06);
}

.image-wrapper.circle-image.hero-v2-avatar---01 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  max-width: 96px;
  margin-top: -13%;
  margin-left: -16%;
  box-shadow: 0 14px 24px 0 rgba(24, 24, 31, 0.16);
}

.image-wrapper.circle-image.hero-v2-avatar---02 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  max-width: 104px;
  margin-top: -18%;
  margin-left: 29.5%;
  box-shadow: 0 14px 24px 0 rgba(24, 24, 31, 0.16);
}

.image-wrapper.circle-image.hero-v2-avatar---03 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  max-width: 107px;
  margin-top: -17%;
  margin-right: -12%;
  box-shadow: 0 14px 24px 0 rgba(24, 24, 31, 0.16);
}

.image-wrapper.circle-image.hero-v2-avatar---04 {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  max-width: 110px;
  margin-bottom: -2%;
  margin-left: -11%;
  box-shadow: 0 14px 24px 0 rgba(24, 24, 31, 0.16);
}

.image-wrapper.circle-image.hero-v2-avatar---05 {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  max-width: 110px;
  margin-right: -10%;
  margin-bottom: -3%;
  box-shadow: 0 14px 24px 0 rgba(24, 24, 31, 0.16);
}

.image-wrapper.blog-post-image {
  overflow: hidden;
  max-height: 242px;
  border-style: solid solid none;
  border-width: 1px 1px 0px;
  border-color: #f1f3f8 #f1f3f8 #000;
}

.image-wrapper.card-team-item-image {
  margin-top: -16px;
}

.image-wrapper.hero-v2-bg {
  position: absolute;
  top: -63%;
  z-index: -1;
  width: 1697px;
}

.image-wrapper.value-image._01 {
  width: 100%;
  max-width: 178px;
  margin: -10px -27px -14px -14px;
}

.image-wrapper.value-image._02 {
  width: 100%;
  max-width: 147px;
  margin: -6px -15px -32px -20px;
}

.image-wrapper.value-image._03 {
  width: 100%;
  max-width: 160px;
  margin: -4px -12px -24px -11px;
}

.image-wrapper.value-image._04 {
  max-width: 144px;
  margin-top: -2px;
  margin-right: -16px;
  margin-left: -7px;
}

.image-wrapper.value-image._05 {
  max-width: 158px;
  margin: -14px -16px -20px;
}

.image-wrapper.value-image._06 {
  max-width: 140px;
  margin: -8px -7px -24px -9px;
}

.image-wrapper.career-item-icon {
  max-width: 96px;
  margin: -5px -18px -24px;
}

.image-wrapper.career-item-details-icon {
  max-width: 26px;
  margin-top: -3px;
  margin-right: 10px;
}

.image-wrapper.hero-v2-gradient-bg---01 {
  position: absolute;
  left: -75%;
  top: -65%;
  z-index: -1;
  width: 100%;
}

.image-wrapper.hero-v2-gradient-bg---02 {
  position: absolute;
  left: 66%;
  top: -65%;
  z-index: -1;
  width: 100%;
}

.image-wrapper.logo-strip-gradient-bg---01 {
  position: absolute;
  left: -62%;
  top: -87%;
  z-index: -1;
  width: 100%;
}

.image-wrapper.logo-strip-gradient-bg---02 {
  position: absolute;
  top: -75%;
  right: -50%;
  z-index: -1;
  width: 100%;
}

.image-wrapper.hero-v1-gradient-bg {
  position: absolute;
  left: -47%;
  top: -12%;
  z-index: -1;
}

.image-wrapper.blog-post-featured-image {
  overflow: hidden;
  max-height: 370px;
}

.image-wrapper.hero-v3-gradient-bg {
  position: absolute;
  left: -47%;
  top: -36%;
  z-index: -1;
}

.image-wrapper.card-blog-post-author-image {
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

.image-wrapper.blog-post-body-bg {
  position: absolute;
  left: -31%;
  top: -4%;
  z-index: -1;
}

.image-wrapper.contact-link-icon._01 {
  max-width: 128px;
  margin-right: -11px;
  margin-bottom: -12px;
  margin-left: -11px;
}

.image-wrapper.contact-link-icon._02 {
  max-width: 138px;
  margin: -7px -10px -23px -14px;
}

.image-wrapper.contact-link-icon._03 {
  max-width: 125px;
  margin: -7px;
}

.image-wrapper.contact-form-bg {
  position: absolute;
  left: -77%;
  top: -57%;
  z-index: -1;
}

.image-wrapper.contact-links-bg {
  position: absolute;
  top: -3%;
  z-index: -1;
}

.image-wrapper.card-plan-icon {
  max-width: 29px;
  margin-right: 10px;
}

.image-wrapper.card-product-page-icon {
  max-width: 48px;
  margin-top: 8px;
  margin-right: 14px;
}

.image-wrapper.card-product-feature-icon {
  max-width: 24px;
  margin-top: -3px;
  margin-right: 8px;
}

.image-wrapper.card-integration-icon {
  max-width: 65px;
  margin-right: 15px;
  margin-bottom: -7px;
  margin-left: -1px;
}

.image-wrapper.integrations-hero {
  max-width: 1585px;
  min-width: 1585px;
  margin-right: 24px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.image-wrapper.integrations-bg---01 {
  position: absolute;
  left: -50%;
  top: -76%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.integrations-bg---02 {
  position: absolute;
  top: -76%;
  right: -64%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.integration-page-image {
  width: 129%;
  margin-top: -18px;
  margin-right: -7px;
  margin-bottom: -140px;
}

.image-wrapper.integration-icon {
  margin: -3px -3px -7px;
}

.image-wrapper.integration-app {
  max-width: 94px;
  margin: -10px;
}

.image-wrapper.border-radius-32px {
  overflow: hidden;
}

.image-wrapper.careers-perk-icon._01 {
  width: 128%;
  margin-top: -11px;
  margin-bottom: -14px;
}

.image-wrapper.careers-perk-icon._02 {
  width: 110%;
  margin-top: -5px;
  margin-bottom: -17px;
  margin-left: -4px;
}

.image-wrapper.careers-perk-icon._03 {
  width: 113%;
  margin-top: -3px;
  margin-bottom: -1px;
}

.image-wrapper.careers-perk-icon._04 {
  width: 138%;
  margin-top: -2px;
  margin-bottom: -20px;
}

.image-wrapper.careers-perk-icon._05 {
  width: 122%;
  margin-top: -4px;
  margin-bottom: -16px;
}

.image-wrapper.careers-perk-icon._06 {
  width: 140%;
  margin-top: -1px;
  margin-bottom: -18px;
}

.image-wrapper.careers-testimonials {
  max-width: 1261px;
  margin-right: auto;
  margin-left: auto;
}

.image-wrapper.careers-hero-bg---01 {
  position: absolute;
  left: -80%;
  top: -70%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.careers-hero-bg---02 {
  position: absolute;
  top: -63%;
  right: -65%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.careers-testimonials-bg---01 {
  position: absolute;
  left: -65%;
  top: 10%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.careers-testimonials-bg---02 {
  position: absolute;
  top: -21%;
  right: -76%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.career-icon-image {
  width: 151%;
  margin-top: -11px;
  margin-bottom: -27px;
}

.image-wrapper.career-page-bg---01 {
  position: absolute;
  left: -70%;
  top: -40%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.career-page-bg---02 {
  position: absolute;
  top: -63%;
  right: -61%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.features-hero-bg {
  position: absolute;
  top: -13%;
  right: -68%;
  z-index: -1;
  width: 113%;
}

.image-wrapper.card-team-page-image {
  overflow: hidden;
  width: 100%;
  max-width: 554px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.image-wrapper.coming-soon-icon-image {
  width: 147%;
  margin-top: -13px;
  margin-bottom: -26px;
}

.image-wrapper.coming-soon-bg---01 {
  position: absolute;
  left: -50%;
  top: -40%;
  z-index: -1;
  width: 79%;
}

.image-wrapper.coming-soon-bg---02 {
  position: absolute;
  top: -41%;
  right: -58%;
  z-index: -1;
  width: 79%;
}

.image-wrapper.image-circle {
  border-radius: 50%;
}

.image-wrapper.image-circle.avatar-left {
  max-width: 76px;
  margin-right: 12px;
}

.image-wrapper.log-social-media-icon {
  max-width: 24px;
  margin-right: 6px;
}

.image-wrapper.sign-up-bg---01 {
  position: absolute;
  left: -47%;
  top: -34%;
  z-index: -1;
  width: 81%;
}

.image-wrapper.sign-up-bg---02 {
  position: absolute;
  left: auto;
  top: auto;
  right: -32%;
  bottom: -40%;
  z-index: -1;
  width: 81%;
}

.image-wrapper.sign-in-bg---01 {
  position: absolute;
  left: -47%;
  top: -34%;
  z-index: -1;
  width: 81%;
}

.image-wrapper.sign-in-bg---02 {
  position: absolute;
  left: auto;
  top: auto;
  right: -34%;
  bottom: -32%;
  z-index: -1;
  width: 81%;
}

.image-wrapper.not-found-image---01 {
  width: 120%;
}

.image-wrapper.not-found-image---02 {
  width: 130%;
  margin-right: -14px;
  margin-left: -11%;
}

.image-wrapper.not-found-bg---01 {
  position: absolute;
  left: -93%;
  top: -117%;
  z-index: -1;
  width: 114%;
}

.image-wrapper.not-found-bg---02 {
  position: absolute;
  left: auto;
  top: auto;
  right: -69%;
  bottom: -39%;
  z-index: -1;
  width: 114%;
}

.image-wrapper.password-page-icon-image {
  width: 118%;
  margin-bottom: -11px;
}

.image-wrapper.password-bg {
  position: absolute;
  z-index: -1;
  width: 1170px;
}

.image-wrapper.cart-item-image-wrapper {
  width: 100%;
  max-width: 50px;
  margin-right: 16px;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.image-wrapper.cart-item-image-wrapper:hover {
  -webkit-transform: scale3d(0.95, 0.95, 1.01) translate(0px, 0px);
  transform: scale3d(0.95, 0.95, 1.01) translate(0px, 0px);
}

._404-not-found {
  margin-bottom: -43px;
  color: #e9edf4;
  font-size: 250px;
  line-height: 1.256em;
  font-weight: 700;
}

.template-pages---nav-item-wrapper {
  padding-left: 0px;
}

.template-pages---sidebar-navigation {
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}

.template-pages---nav-item-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  color: #707480;
  text-decoration: none;
}

.template-pages---nav-item-link:hover {
  background-color: #f1f3f8;
  color: #508dff;
}

.template-pages---nav-item-link.w--current {
  background-color: #e9edf4;
  color: #508dff;
  font-weight: 700;
}

.template-pages---phography-wrapper {
  overflow: hidden;
  border-radius: 16px;
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.template-pages---phography-wrapper:hover {
  -webkit-transform: scale3d(0.97, 0.97, 1.01);
  transform: scale3d(0.97, 0.97, 1.01);
}

.cart-button-wrapper {
  margin-left: 13px;
}

.cart-quantity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  min-width: auto;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: transparent;
  color: #181b1f;
  font-size: 18px;
  line-height: 1.111em;
  font-weight: 400;
}

.cart-wrapper {
  background-color: rgba(0, 0, 0, 0.85);
}

.cart-container {
  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0 5px 25px 0 rgba(24, 24, 31, 0.24);
}

.cart-header {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom-color: #e9edf4;
}

.cart-footer {
  border-top-color: #e9edf4;
}

.cart-item-title {
  margin-bottom: 8px;
  color: #181b1f;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.cart-item-title:hover {
  color: #508dff;
}

.cart-item-price {
  color: #181b1f;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}

.cart-item-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9edf4;
}

.cart-item-wrapper:last-child {
  border-bottom-width: 0px;
}

.cart-subtotal-number {
  color: #181b1f;
}

.pay-btn {
  border-radius: 90px;
}

.pay-btn.cart {
  height: 60px;
}

.pay-btn.order-summary {
  height: 50px;
}

.cart-line-item {
  margin-bottom: 25px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 20px;
}

.cart-remove-link {
  line-height: 20px;
}

.cart-close-button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #181b1f;
  font-size: 24px;
  line-height: 1em;
  text-decoration: none;
}

.cart-close-button:hover {
  color: #ff5a65;
}

.checkout-form {
  min-height: auto;
  padding: 0px;
  background-color: transparent;
}

.checkout-form.form-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.checkout-col-left {
  margin-right: 0px;
}

.checkout-col-right {
  position: static;
}

.checkout-block-header {
  padding: 0px 0px 15px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  border-width: 0px 0px 1px;
  border-bottom-color: #e9edf4;
  background-color: transparent;
}

.checkout-block-content {
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  border: 0px solid #000;
  background-color: transparent;
}

.checkout-block-content.pd-top-0px {
  padding-top: 0px;
}

.shipping-list {
  border: 0px solid #000;
}

.shipping-method {
  padding: 31px 0px;
  grid-column-gap: 12px;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-color: #000 #000 #f1f3f8;
}

.shipping-price {
  color: #181b1f;
  font-weight: 700;
}

.order-item-list {
  margin-bottom: 0px;
}

.order-item {
  margin-top: 18px;
  margin-bottom: 18px;
  padding-top: 18px;
  padding-bottom: 18px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid #e9edf4;
}

.order-list-price {
  color: #181b1f;
  font-weight: 700;
}

.order-list-title {
  margin-bottom: 8px;
  color: #181b1f;
  font-size: 20px;
  font-weight: 700;
  text-decoration: none;
}

.order-list-title:hover {
  color: #508dff;
}

.order-option-item {
  padding-left: 0px;
}

.order-item-regular {
  color: #181b1f;
}

.order-summary-price {
  color: #181b1f;
}

.discounts-wrapper {
  margin-top: 32px;
  padding: 32px 0px 0px;
  border-width: 1px 0px 0px;
  border-top-color: #f1f3f8;
  background-color: transparent;
}

.order-item-content {
  margin-left: 0px;
}

.text-underline {
  text-decoration: underline;
}

.z-index-1 {
  position: relative;
  z-index: 1;
}

.home-pages-cointainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 90px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-pages-top-wrapper {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-home-link-wrapper {
  overflow: hidden;
  max-width: 400px;
  margin-right: 12px;
  margin-left: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 28px;
  box-shadow: 0 2px 10px 0 rgba(24, 27, 31, 0.14);
  -webkit-transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: color 300ms ease, -webkit-transform 300ms ease;
  transition: transform 300ms ease, color 300ms ease;
  transition: transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.image-home-link-wrapper:hover {
  -webkit-transform: scale3d(0.98, 0.98, 1.01);
  transform: scale3d(0.98, 0.98, 1.01);
}

.image-home-link {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.home-pages-bottom-wrapper {
  position: static;
  top: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.card-sales-home-image {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.card-sales-home-content {
  padding: 22px 32px;
  text-align: center;
}

.figma-file-image-wrapper {
  width: 100%;
  max-width: 640px;
}

.figma-file-text-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 30px;
  padding-left: 56px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.badge-home {
  position: absolute;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: auto;
  padding: 8px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #f7f9fc;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(24, 27, 31, 0.13);
  color: #211f54;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.home-surprises-card {
  overflow: hidden;
  border-radius: 40px;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
}

.card-home-pages-wrapper {
  padding-top: 56px;
  padding-right: 56px;
  padding-left: 56px;
}

.cta-square-logo {
  margin-bottom: 22px;
  border-radius: 22px;
  box-shadow: 0 8px 28px 0 rgba(20, 20, 43, 0.1);
}

.cta-square-logo.white {
  border-style: solid;
  border-width: 1px;
  border-color: #f7f9fc;
}

.mg-bottom-12px {
  margin-bottom: 12px;
}

.cta-image-card {
  width: 1140px;
  max-width: 1140px;
  margin-top: 52px;
  margin-bottom: -23px;
}

.order-summary-item {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 20px;
}

.text-no-wrap {
  white-space: nowrap;
}

.shipping-method-content {
  margin-right: 0px;
  margin-left: 0px;
}

.position-absolute {
  position: absolute;
}

.position-absolute.btn-primary-gradient {
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: -1;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7fdca4), to(#58c09b));
  background-image: linear-gradient(180deg, #7fdca4, #58c09b);
}

.position-absolute.btn-primary-gradient.fixed {
  position: static;
  border-radius: 20px;
  font-weight: 500;
  text-align: left;
}

.position-absolute.card-advantage-01-image-fade {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  min-height: 126px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#f7f9fc), to(rgba(247, 249, 252, 0)));
  background-image: linear-gradient(0deg, #f7f9fc, rgba(247, 249, 252, 0));
}

.position-absolute.team-item-social-media-wrapper {
  left: auto;
  top: auto;
  right: 24px;
  bottom: 24px;
  z-index: 1;
  width: 100%;
  max-width: 132px;
  padding: 12px 20px;
  border-radius: 1000px;
  background-color: #fff;
  -webkit-transform: translate3d(0px, 0px, 0.01px);
  transform: translate3d(0px, 0px, 0.01px);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.position-absolute.card-pricing-fade {
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  min-height: 127px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(29%, hsla(0, 0%, 100%, 0)), color-stop(82%, #fff));
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 29%, #fff 82%);
}

.mg-bottom-7px {
  margin-bottom: 7px;
}

.mg-top-116px {
  margin-top: 116px;
}

.mg-bottom-10px {
  margin-bottom: 10px;
}

.mg-bottom-19px {
  margin-bottom: 19px;
}

.mg-bottom-42px {
  margin-bottom: 42px;
}

.border-radius-20px {
  border-radius: 20px;
}

.mg-bottom-38px {
  margin-bottom: 38px;
}

.mg-top-auto {
  margin-top: auto;
}

.masonry-grid-v1 {
  -ms-grid-columns: 1fr 0.4fr 1fr;
  grid-template-columns: 1fr 0.4fr 1fr;
}

.card-advantage-01-content {
  padding: 11px 24px 38px;
}

.card-advantage-02-content {
  padding: 45px 48px 37px;
}

.card-advantage-03-content {
  padding: 32px 34px 50px;
}

.card-advantage-04-content {
  padding: 43px 34px 33px;
}

.card-cta-v1-image-wrapper {
  position: relative;
  width: 100%;
  height: 495px;
  max-width: 543px;
  margin-bottom: -49px;
  margin-left: 40px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mg-bottom-30px {
  margin-bottom: 30px;
}

.testimonial-twitter-strip-icon {
  color: #508dff;
  font-size: 30px;
  line-height: 1em;
}

.mg-top-15px {
  margin-top: 15px;
}

.testimonials-strip {
  min-width: 1977px;
  margin-right: 24px;
  grid-column-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.blog-post-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 35px 28px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.blog-post-content-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-row-gap: 15px;
}

.card-footer-cta-icon {
  width: 100%;
  max-width: 74px;
  margin: -2px 27px -2px -2px;
}

.footer-newsletter-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 194px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu-wrapper---main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 241px;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.grid-footer---main {
  grid-column-gap: 40px;
  grid-template-areas: "Area Area-2 Area-2";
  -ms-grid-columns: 1fr 40px 0.5fr 40px 0.75fr;
  grid-template-columns: 1fr 0.5fr 0.75fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.menu-wrapper {
  width: 300px;
  max-width: 300px;
  margin-right: 20px;
  padding-top: 15px;
}

.menu-wrapper.last {
  margin-right: 0px;
}

.nav-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav-link-badge---text {
  display: inline-block;
  margin-right: 10px;
}

.mg-top-36px {
  margin-top: 36px;
}

.mg-bottom-255px {
  margin-bottom: 255px;
}

.mg-bottom-60px {
  margin-bottom: 60px;
}

.mg-bottom-20px {
  margin-bottom: 20px;
}

.card-stats-wrapper {
  padding-right: 54px;
  padding-left: 54px;
  border-right: 1.5px solid rgba(177, 183, 197, 0.6);
  text-align: center;
}

.card-stats-wrapper._04 {
  padding-right: 0px;
  border-right-width: 0px;
}

.card-stats-wrapper._01 {
  padding-left: 0px;
}

.team-item-content {
  position: relative;
  z-index: 1;
  padding-top: 36px;
  padding-right: 34px;
  padding-left: 34px;
  text-align: center;
}

.stats-shadow {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 89%;
  min-height: 80px;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(3%, rgba(80, 170, 255, 0.5)), color-stop(96%, rgba(80, 129, 255, 0.5)));
  background-image: linear-gradient(0deg, rgba(80, 170, 255, 0.5) 3%, rgba(80, 129, 255, 0.5) 96%);
  -webkit-filter: blur(40px);
  filter: blur(40px);
}

.social-media-link {
  font-size: 20px;
  line-height: 1em;
  text-decoration: none;
}

.logo-strip-grid {
  min-width: 1644px;
  margin-right: 24px;
  grid-column-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.logo-strip-grid._02 {
  margin-right: 0px;
  margin-left: 24px;
}

.career-item-main-content {
  width: 100%;
  max-width: 404px;
  margin-left: 15px;
}

.mg-bottom-6px {
  margin-bottom: 6px;
}

.career-item-details {
  margin-right: 15px;
}

.career-item-details-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.career-item-main-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1086px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.big-number {
  margin-bottom: 12px;
  color: #181b1f;
  font-size: 4.5vw;
  line-height: 1.258em;
  font-weight: 700;
}

.card-stats-main-wrapper {
  width: 100%;
  max-width: 1009px;
  margin-right: auto;
  margin-left: auto;
}

.card-stats-grid {
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.blog-post-featured-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 39px 39px 49px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.mg-bottom-46px {
  margin-bottom: 46px;
}

.categories-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.categories-wrapper.blog-categories {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.category-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.category-item.blog-category {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.logo-strips-right-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 848px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-column-gap: 50px;
}

.logo-strips-right-wrapper.v2 {
  max-width: none;
  grid-column-gap: 56px;
}

.logo-strip-v2-main-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 50px;
}

.category-link-icon {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 16px;
  margin-right: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-newsletter-icon {
  width: 100%;
  max-width: 98px;
  margin: -2px 30px -2px -2px;
}

.button-inside-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 72px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.newsletter-shadow {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin: 52px 28px 18px;
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(3%, rgba(80, 170, 255, 0.5)), color-stop(96%, rgba(80, 129, 255, 0.5)));
  background-image: linear-gradient(0deg, rgba(80, 170, 255, 0.5) 3%, rgba(80, 129, 255, 0.5) 96%);
  -webkit-filter: blur(35px);
  filter: blur(35px);
}

.card-newsletter-title {
  max-width: 314px;
}

.content-link-block {
  color: #707480;
  text-decoration: none;
}

.content-link-block:hover {
  color: #707480;
}

.content-link-block.blog-post-page-category {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #181b1f;
  line-height: 1.111em;
}

.content-link-block.blog-post-page-category:hover {
  color: #508dff;
}

.text-divider {
  width: 12px;
  min-height: 1px;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #707480;
}

.blog-post-page-category-icon {
  overflow: visible;
  max-width: 20px;
  margin-right: 8px;
}

.blog-post-page-image-shadow {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 88%;
  min-height: 64px;
  margin-right: auto;
  margin-bottom: 17px;
  margin-left: auto;
  border-radius: 30px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(3%, #50aaff), color-stop(96%, #506aff));
  background-image: linear-gradient(0deg, #50aaff 3%, #506aff 96%);
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

.card-blog-post-author-content {
  padding: 40px 66px 40px 58px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card-blog-post-author-social-media {
  overflow: hidden;
  width: 100%;
  max-width: 141px;
  padding: 13px 21px;
  border-radius: 1000px;
  background-color: #fff;
  box-shadow: 0 2px 13px 0 rgba(24, 24, 31, 0.08);
}

.contact-form-shadow {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 91%;
  min-height: 64%;
  margin-right: auto;
  margin-bottom: 10px;
  margin-left: auto;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(3%, rgba(80, 170, 255, 0.8)), color-stop(96%, rgba(80, 129, 255, 0.8)));
  background-image: linear-gradient(0deg, rgba(80, 170, 255, 0.8) 3%, rgba(80, 129, 255, 0.8) 96%);
  -webkit-filter: blur(40px);
  filter: blur(40px);
}

.contact-icon-link---left {
  max-width: 19px;
  margin-top: 3px;
  margin-right: 10px;
}

.text-area-wrapper {
  max-width: 100%;
  min-width: 100%;
}

.contact-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 534px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.accordion-icon-line {
  position: absolute;
  width: 14px;
  min-height: 1.5px;
  border-radius: 100000px;
  background-color: #181b1f;
}

.accordion-icon-line.vertical {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.contact-social-media-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 145px;
  padding: 13px 21px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10000px;
  background-color: #fff;
  box-shadow: 0 2px 13px 0 rgba(24, 24, 31, 0.08);
}

.card-plan-item-top-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 100%;
  padding: 48px 41px 173px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-plan-item-top-wrapper.popular {
  padding-right: 30px;
  padding-left: 30px;
  background-color: #f7f9fc;
}

.mg-top-45px {
  margin-top: 45px;
}

.mg-bottom-28px {
  margin-bottom: 28px;
}

.pricing-main-wrapper {
  max-width: 876px;
}

.card-pricing-shadow {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 95%;
  min-height: 75%;
  margin-top: 14px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(3%, rgba(80, 170, 255, 0.6)), color-stop(96%, rgba(80, 129, 255, 0.6)));
  background-image: linear-gradient(180deg, rgba(80, 170, 255, 0.6) 3%, rgba(80, 129, 255, 0.6) 96%);
  opacity: 0.8;
  -webkit-filter: blur(30px);
  filter: blur(30px);
}

.card-plan-item-content {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card-plan-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-color: #f1f3f8;
}

.card-plan-item:nth-child(even) {
  border-width: 0px;
}

.card-product-page-divider {
  width: 1px;
  min-height: 100%;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  background-color: #e9edf4;
}

.gap-row-12px {
  grid-row-gap: 12px;
}

.add-cart {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 428px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.add-cart-default-state {
  margin-bottom: 0px;
}

.rich-text-v2 img {
  border-radius: 24px;
}

.rich-text-v2 p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rich-text-v2 h2 {
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 38px;
  line-height: 1.263em;
  font-weight: 700;
}

.rich-text-v2 h3 {
  margin-top: 40px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 28px;
  line-height: 1.429em;
  font-weight: 700;
}

.rich-text-v2 figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.rich-text-v2 ul {
  margin-bottom: 40px;
  padding-left: 0px;
  list-style-type: none;
}

.rich-text-v2 ol {
  margin-bottom: 40px;
  padding-left: 0px;
  list-style-type: none;
}

.rich-text-v2 h6 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v2 h5 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v2 h4 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v2 blockquote {
  margin-top: 56px;
  margin-bottom: 56px;
}


.mg-top-0px {
  margin-top: 0px;
}

.card-product-shadow {
  position: absolute;
  left: 0%;
  top: -6%;
  right: 0%;
  bottom: 0%;
  width: 90%;
  height: 83%;
  margin: auto;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(3%, rgba(80, 170, 255, 0.4)), color-stop(96%, rgba(80, 129, 255, 0.4)));
  background-image: linear-gradient(0deg, rgba(80, 170, 255, 0.4) 3%, rgba(80, 129, 255, 0.4) 96%);
  opacity: 1;
  -webkit-filter: blur(40px);
  filter: blur(40px);
}

.card-integration-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card-integration-top-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mg-bottom-35px {
  margin-bottom: 35px;
}

.integration-plus-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.integration-plus-line {
  position: absolute;
  width: 24px;
  min-height: 6px;
  background-color: #181b1f;
}

.integration-plus-line.vertical {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rich-text-v3 img {
  border-radius: 24px;
}

.rich-text-v3 p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rich-text-v3 h2 {
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 24px;
  line-height: 1.417em;
  font-weight: 700;
}

.rich-text-v3 h3 {
  margin-top: 40px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 22px;
  line-height: 1.273em;
  font-weight: 700;
}

.rich-text-v3 figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.rich-text-v3 ul {
  margin-bottom: 40px;
}

.rich-text-v3 ol {
  margin-bottom: 40px;
}

.rich-text-v3 h6 {
  margin-top: 40px;
  margin-bottom: 16px;
  font-size: 14px;
}

.rich-text-v3 h5 {
  margin-top: 40px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 16px;
  line-height: 1.375em;
  font-weight: 700;
}

.rich-text-v3 h4 {
  margin-top: 40px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 18px;
  line-height: 1.333em;
  font-weight: 700;
}

.rich-text-v3 blockquote {
  margin-top: 56px;
  margin-bottom: 56px;
}

.mg-top-155px {
  margin-top: 155px;
}

.careers-perk-icon-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.careers-perk-icon-wrapper._01 {
  max-width: 83px;
}

.careers-perk-icon-wrapper._02 {
  max-width: 85px;
}

.careers-perk-icon-wrapper._03 {
  max-width: 83px;
}

.careers-perk-icon-wrapper._04 {
  max-width: 83px;
}

.careers-perk-icon-wrapper._05 {
  max-width: 87px;
}

.careers-perk-icon-wrapper._06 {
  max-width: 86px;
}

.rich-text-v4 img {
  border-radius: 24px;
}

.rich-text-v4 p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rich-text-v4 h2 {
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 28px;
  line-height: 1.429em;
  font-weight: 700;
}

.rich-text-v4 h3 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v4 figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.rich-text-v4 ul {
  margin-bottom: 40px;
}

.rich-text-v4 ol {
  margin-bottom: 40px;
}

.rich-text-v4 h6 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v4 h5 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v4 h4 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v4 blockquote {
  margin-top: 56px;
  margin-bottom: 56px;
}

.rich-text-v5 img {
  border-radius: 24px;
}

.rich-text-v5 p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.rich-text-v5 h2 {
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 38px;
  line-height: 1.263em;
  font-weight: 700;
}

.rich-text-v5 h3 {
  margin-top: 40px;
  margin-bottom: 16px;
  color: #181b1f;
  font-size: 28px;
  line-height: 1.429em;
  font-weight: 700;
}

.rich-text-v5 figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.rich-text-v5 ul {
  margin-bottom: 40px;
}

.rich-text-v5 ol {
  margin-bottom: 40px;
}

.rich-text-v5 h6 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v5 h5 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v5 h4 {
  margin-top: 40px;
  margin-bottom: 16px;
}

.rich-text-v5 blockquote {
  margin-top: 56px;
  margin-bottom: 56px;
}

.card-team-page-content {
  max-width: 48%;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 35px;
}

.card-team-page-social-media {
  overflow: hidden;
  width: 100%;
  max-width: 131px;
  padding: 12px 19px;
  border-radius: 1000px;
  background-color: #fff;
  box-shadow: 0 2px 13px 0 rgba(24, 24, 31, 0.08);
}

.footer-small {
  padding-top: 56px;
  padding-bottom: 56px;
}

.coming-soon-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 112px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.coming-soon-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 151px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid-footer-small-logo---paragraph {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 0.3fr 1fr;
  grid-template-columns: 0.3fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.mg-bottom-95px {
  margin-bottom: 95px;
}

.demo-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 448px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.demo-icon-left {
  max-width: 25px;
  min-width: 25px;
  margin-right: 13px;
}

.form-space {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 32px;
  margin-bottom: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.form-space.v2 {
  margin-top: 38px;
}

.form-space-line {
  min-height: 1px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #e9edf4;
}

.form-space-line.bg-neutral-500 {
  background-color: #b1b7c5;
}

.sign-up-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 528px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sign-up-form-grid {
  grid-row-gap: 26px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.mg-top-6px {
  margin-top: 6px;
}

.mg-top-9px {
  margin-top: 9px;
}

.check-box-paragraph {
  margin-top: -3px;
  margin-bottom: 0px;
  color: #707480;
  line-height: 1.667em;
  font-weight: 400;
}

.check-box-paragraph.text-100 {
  margin-top: -1px;
}

.gap-row-26px {
  grid-row-gap: 26px;
}

.sign-in-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 366px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sign-in-form-grid {
  grid-row-gap: 26px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.gap-row-21px {
  grid-row-gap: 21px;
}

.sign-in-options {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}

.not-found-images-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 677px;
  min-width: 600px;
  margin-right: -8%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.not-found-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.password-page-icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 101px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.discount-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.order-confirmation-2 {
  min-height: auto;
  padding: 0px;
  background-color: transparent;
}

.blockquote-style-guide blockquote {
  background-color: #f1f3f8;
}

.cart-option-item {
  padding-left: 0px;
}

.cart-item-regular {
  color: #181b1f;
}

.remove-button {
  color: #707480;
}

.remove-button:hover {
  color: #ff5a65;
}

.cart-item-content {
  margin-left: 0px;
}

.header-nav-menu-top {
  display: none;
}

.typing-animation-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.typing-animation-wrapper.center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.typing-animation-line {
  width: 14px;
  background-color: #181b1f;
}

.category-link-icon-current {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  -webkit-filter: brightness(0%) invert(100%);
  filter: brightness(0%) invert(100%);
}

.youtube {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  height: auto;
  -o-object-fit: fill;
  object-fit: fill;
}

.youtube-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 50%;
  height: 50%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.report-image {
  width: 100%;
  padding: 25px;
}

.partner-program-contain {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  padding: 30px 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 1px;
  border-color: #05c168;
}

.green-button {
  border-radius: 20px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(5, 193, 104, 0.95)), color-stop(62%, #7fdca4));
  background-image: linear-gradient(0deg, rgba(5, 193, 104, 0.95), #7fdca4 62%);
  -webkit-transition-property: none;
  transition-property: none;
  font-weight: 700;
}

.green-button:hover {
  color: #fff;
}

.simple-hr {
  width: 100%;
  height: 5px;
  max-width: 250px;
  margin-top: 25px;
  margin-bottom: 25px;
  background-color: transparent;
}

.simple-hr.green {
  width: 100%;
  max-width: 250px;
  margin: 25px auto;
  background-color: #7fdca4;
}

.html-embed {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media screen and (min-width: 1440px) {
  .grid-2-columns.style---styleguide-wrapper {
    -ms-grid-columns: 0.3fr 1fr;
    grid-template-columns: 0.3fr 1fr;
  }

  .grid-2-columns.form {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
  }

  .card.cta-v1 {
    box-shadow: inset 0 1px 12px 0 rgba(24, 24, 31, 0.16), 0 3px 16px 0 rgba(0, 37, 71, 0.53);
  }

  .style---typography-block-grid {
    -ms-grid-columns: minmax(auto, 380px) 0.8fr;
    grid-template-columns: minmax(auto, 380px) 0.8fr;
  }

  .badge-secondary.category-link {
    overflow: hidden;
  }

  .style---dropdown-toggle {
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 20px;
    line-height: 20px;
  }

  .style---dropdown-link-sidebar {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
  }

  .style---dropdown-link-icon-sidebar {
    max-width: 46px;
    margin-right: 14px;
    border-radius: 12px;
  }

  .style---logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .style---logo-sidebar {
    max-width: 260px;
  }

  .section {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .section.template-pages-hero {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .section.hero.v5 {
    padding-top: 120px;
  }

  .section.pd-100px {
    padding-top: 100px;
    padding-bottom: 100px;
    box-shadow: 1px 1px 3px 0 #000;
  }

  .section.pd-bottom-240px {
    padding-bottom: 240px;
  }

  .section.pd-bottom-160px {
    padding-bottom: 160px;
  }

  .section.pd-bottom-280px {
    padding-bottom: 280px;
  }

  .section.pd-200px {
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .section.pd-top-100px {
    padding-top: 100px;
  }

  .section.pd-90px {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .section.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-180px {
    padding-top: 180px;
    padding-bottom: 180px;
  }

  .section.pd-top-90px {
    padding-top: 90px;
  }

  .section.pd-bottom-224px {
    padding-bottom: 224px;
  }

  .section.pd-148px {
    padding-top: 148px;
    padding-bottom: 148px;
  }

  .section.pd-148px.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-bottom-80px {
    padding-bottom: 80px;
  }

  .section.pd-top-80px {
    padding-top: 80px;
  }

  .section.pd-bottom-0px {
    padding-bottom: 0px;
  }

  .section.pd-150px {
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .section.pd-150px.pd-top-0px {
    padding-top: 0px;
  }

  .grid-footer-v1---5-column {
    grid-column-gap: 91px;
    -ms-grid-columns: minmax(340px, 1fr) auto auto auto auto;
    grid-template-columns: minmax(340px, 1fr) auto auto auto auto;
  }

  .image.cover {
    box-shadow: 0 0 7px 0 #def2e6;
  }

  .image-wrapper.hero-v1 {
    width: 100%;
    height: 100%;
  }

  .checkout-block-content.pd-top-0px {
    padding-top: 0px;
  }

  .big-number {
    font-size: 62px;
  }

  .mg-top-0px {
    margin-top: 0px;
  }

  .html-embed {
    border-style: dotted;
    border-width: 1px;
    border-color: #05c168;
  }
}

@media screen and (min-width: 1920px) {
  .card.cta-v1 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#6ca32d), to(#11845b));
    background-image: linear-gradient(180deg, #6ca32d, #11845b);
  }

  .style---heading {
    padding-right: 6vw;
    padding-left: 6vw;
  }

  .style---content-wrapper {
    padding-right: 8vw;
    padding-left: 8vw;
  }

  .section.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-148px.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-bottom-0px {
    padding-bottom: 0px;
  }

  .section.pd-150px.pd-top-0px {
    padding-top: 0px;
  }

  .checkout-col-right {
    position: -webkit-sticky;
    position: sticky;
    top: 24px;
  }

  .checkout-block-content.pd-top-0px {
    padding-top: 0px;
  }

  .image-home-link-wrapper {
    max-width: 560px;
  }

  .mg-top-0px {
    margin-top: 0px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  blockquote {
    padding: 69px 88px;
    font-size: 24px;
  }

  .grid-2-columns.style---styleguide-wrapper {
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns._1-col-tablet {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.template-page-sidebar {
    -ms-grid-columns: 0.5fr 1fr;
    grid-template-columns: 0.5fr 1fr;
  }

  .grid-2-columns.checkout-page {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.suprises-pages-right {
    margin-bottom: 100px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.suprises-pages-left {
    margin-bottom: 100px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons._1-col-tablet {
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons.pricing {
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.small-print-social-media {
    grid-row-gap: 25px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .grid-2-columns.card-newsletter-grid {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.blog-post-page-grid {
    grid-row-gap: 50px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.conatct-form-section-grid {
    grid-row-gap: 60px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.faqs-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.careers-hero-images-grid {
    margin-top: 40px;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .grid-2-columns.demo-grid {
    grid-row-gap: 80px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .style---navigation-sidebar {
    position: static;
    max-height: 100%;
    min-height: auto;
    box-shadow: none;
  }

  .card.link-card.career-item {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .card.link-card.integration-item {
    padding: 50px 34px;
  }

  .card.figma-file {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card.featured-feature {
    padding-right: 34px;
    padding-left: 34px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .card.feature {
    padding-right: 34px;
    padding-left: 34px;
  }

  .card.cta-v1 {
    padding: 80px 60px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .card.advantage-01 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card.footer-cta {
    padding-right: 60px;
    padding-left: 60px;
    text-align: center;
  }

  .card.footer-newsletter {
    padding-right: 80px;
    padding-left: 80px;
  }

  .card.logo-strip {
    height: 133px;
    max-height: 133px;
    min-height: 133px;
    padding: 30px;
  }

  .card.newsletter {
    padding: 73px 60px;
  }

  .card.blog-post-body {
    padding-top: 78px;
    padding-bottom: 78px;
  }

  .card.product-page {
    padding-right: 40px;
    padding-left: 40px;
  }

  .card.product-description {
    padding-right: 65px;
    padding-left: 65px;
  }

  .card.integration-body {
    padding-top: 77px;
    padding-right: 60px;
    padding-left: 60px;
  }

  .card.careers-perks {
    padding-top: 100px;
    padding-right: 60px;
    padding-left: 60px;
  }

  .card.team-page {
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.dropdown-nav {
    overflow: visible;
    padding: 0px;
    border-width: 0px;
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
  }

  .grid-4-columns {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-4-columns.pricing-tablet-grid {
    display: none;
    margin-top: -73px;
  }

  .grid-4-columns.pricing-tablet-grid.mobile {
    display: -ms-grid;
    display: grid;
    max-width: 700px;
    margin-top: 0px;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 80px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .style---block-sub-heading {
    margin-bottom: 24px;
  }

  .divider {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .divider._64px {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .style---typography-block-grid {
    -ms-grid-columns: minmax(auto, 250px) 1fr;
    grid-template-columns: minmax(auto, 250px) 1fr;
  }

  .mg-bottom-40px.footer-v2 {
    margin-bottom: 24px;
  }

  .mg-bottom-56px {
    margin-bottom: 48px;
  }

  .mg-bottom-56px.keep {
    margin-bottom: 56px;
  }

  .mg-top-56px {
    margin-top: 40px;
  }

  .mg-top-64px {
    margin-top: 60px;
  }

  .mg-right-24px.mg-bt-16px {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .display-1 {
    font-size: 68px;
  }

  .display-1.mg-bottom-7px.typing-01 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-02 {
    padding-left: 10px;
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-03 {
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-04 {
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-05 {
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-06 {
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-07 {
    font-size: 49px;
  }

  .display-1.mg-bottom-7px.typing-08 {
    font-size: 49px;
  }

  .grid-1-column.gap-row-32px {
    grid-column-gap: 40px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-1-column.career-item-details-grid {
    grid-row-gap: 20px;
  }

  .grid-1-column.contact-icon-link-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .gap-row-80px {
    grid-row-gap: 56px;
  }

  .display-2 {
    font-size: 52px;
  }

  .display-3 {
    font-size: 32px;
  }

  .display-4 {
    font-size: 22px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 140px;
  }

  .paragraph-large {
    font-size: 22px;
  }

  .text-400 {
    font-size: 22px;
  }

  .grid-3-columns {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-3-columns.gap-row-80px {
    grid-row-gap: 64px;
  }

  .grid-3-columns._1-col-tablet {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns.gap-24px._1-col-tablet {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns.section-blog {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns.careers-perks-grid {
    grid-row-gap: 70px;
  }

  .style---heading {
    min-height: auto;
    margin-bottom: 60px;
    padding-top: 116px;
    padding-bottom: 116px;
  }

  .inner-container.card-featured-feature-content {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 50px;
  }

  .inner-container._100---tablet {
    max-width: 100%;
  }

  .inner-container._600px---tablet {
    max-width: 600px;
  }

  .inner-container._700px---tablet {
    max-width: 700px;
  }

  .inner-container._500px---tablet {
    max-width: 500px;
  }

  .inner-container._400px---tablet {
    max-width: 400px;
  }

  .inner-container._300px---tablet {
    max-width: 300px;
  }

  .inner-container._450px---tablet {
    max-width: 450px;
  }

  .inner-container.card-career-item-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .inner-container.card-career-item-right {
    max-width: 249px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .inner-container._800px---tablet {
    max-width: 800px;
  }

  .inner-container.center---tablet {
    margin-right: auto;
    margin-left: auto;
  }

  .inner-container.newsletter-form-wrapper {
    max-width: 100%;
  }

  .inner-container._650px---tablet {
    max-width: 650px;
  }

  .inner-container.not-found-content {
    min-width: auto;
    margin-right: 0px;
    margin-bottom: 60px;
    text-align: center;
  }

  .style---content-heading {
    margin-bottom: 60px;
    padding: 32px;
  }

  .border-radius-32px {
    border-radius: 28px;
  }

  .btn-primary.small.header-btn-hidde-on-mb {
    margin-left: 20px;
  }

  .avatar-circle._06 {
    width: 140px;
    height: 140px;
    max-height: 140px;
    max-width: 140px;
  }

  .avatar-circle._07 {
    width: 160px;
    height: 160px;
    max-height: 160px;
    max-width: 160px;
  }

  .avatar-circle._08 {
    width: 280px;
    height: 280px;
    max-height: 280px;
    max-width: 280px;
  }

  .line-square-icon.top-bar-close-icon {
    padding: 18px 24px;
  }

  .style---dropdown-wrapper-sidebar {
    display: none;
  }

  .buttons-row.center---tablet {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .style---sidebar-wrapper {
    overflow: hidden;
  }

  .style---logo-wrapper {
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .style---button-wrapper-sidebar {
    display: none;
  }

  .elements---page-hero {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section {
    padding-top: 83px;
    padding-bottom: 83px;
  }

  .section.small {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.hero.v1 {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .section.hero.v2 {
    padding-top: 210px;
    padding-bottom: 133px;
  }

  .section.hero.v3 {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section.hero.v4 {
    padding-top: 100px;
    padding-bottom: 83px;
  }

  .section.hero.v5 {
    padding-top: 80px;
    padding-bottom: 157px;
  }

  .section.hero.v6 {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section.hero.v7 {
    padding-top: 80px;
    padding-bottom: 88px;
  }

  .section.hero.v8 {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  .section.hero.v9 {
    padding-top: 80px;
    padding-bottom: 100px;
  }

  .section.hero.v10 {
    padding-top: 80px;
    padding-bottom: 125px;
  }

  .section.hero.v11 {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .section.pd-100px {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section.pd-bottom-240px {
    padding-bottom: 168px;
  }

  .section.pd-bottom-160px {
    padding-bottom: 102px;
  }

  .section.pd-bottom-280px {
    padding-bottom: 180px;
  }

  .section.pd-200px {
    padding-top: 140px;
    padding-bottom: 140px;
  }

  .section.pd-top-100px {
    padding-top: 70px;
  }

  .section.pd-90px {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-180px {
    padding-top: 125px;
    padding-bottom: 125px;
  }

  .section.pd-top-90px {
    padding-top: 70px;
  }

  .section.pd-bottom-224px {
    padding-bottom: 143px;
  }

  .section.pd-148px {
    padding-top: 103px;
    padding-bottom: 103px;
  }

  .section.pd-148px.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-bottom-80px {
    padding-bottom: 60px;
  }

  .section.pd-top-80px {
    padding-top: 60px;
  }

  .section.pd-bottom-0px {
    padding-bottom: 0px;
  }

  .section.pd-150px {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  .section.pd-150px.pd-top-0px {
    padding-top: 0px;
  }

  .top-bar-wrapper {
    padding-right: 56px;
    padding-left: 56px;
  }

  .top-bar-padding {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .top-bar-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .heading-h1-size {
    font-size: 48px;
  }

  .heading-h2-size {
    font-size: 32px;
  }

  .header-wrapper {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .header-nav-menu-wrapper {
    position: fixed;
    top: 0px;
    overflow: auto;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
    padding-right: 24px;
    padding-bottom: 40px;
    padding-left: 24px;
    background-color: #fff;
  }

  .header-nav-menu-list {
    height: 84%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-left: 20% !important;
  }

  .header-nav-link {
    font-size: 32px;
  }

  .header-nav-link.cart-link {
    font-size: 18px;
  }

  .header-nav-link.login-link---mobile {
    font-size: 20px;
  }

  .header-nav-list-item {
    margin-bottom: 12px;
    padding-left: 0px;
  }

  .header-nav-list-item.show-in-tablet {
    display: block;
  }

  .header-nav-list-item.show-in-tablet.login-link {
    margin-top: auto;
    margin-bottom: 28px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .header-nav-list-item.show-in-tablet.btn-link {
    margin-bottom: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .header-nav-list-item.middle {
    margin-bottom: 28px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .dropdown-wrapper {
    position: static;
    z-index: auto;
  }

  .dropdown-column-wrapper {
    width: 100%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .dropdown-column-wrapper.w--open {
    position: static;
  }

  .header-logo-link {
    z-index: 1;
  }

  .header-logo-link.w--current {
    z-index: 1;
  }

  .hamburger-menu-btn {
    overflow: hidden;
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    margin-left: 20px;
    border-radius: 50%;
    box-shadow: 0 1px 12px 0 rgba(24, 24, 31, 0.16), 0 3px 16px 0 rgba(89, 175, 255, 0.02);
    -webkit-transition: -webkit-transform 300ms ease;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
  }

  .hamburger-menu-btn:hover {
    -webkit-transform: translate3d(0px, 3px, 0.01px);
    transform: translate3d(0px, 3px, 0.01px);
  }

  .hamburger-menu-bar {
    width: 20px;
    height: 3px;
    background-color: #fff;
  }

  .header-right-side {
    position: relative;
    z-index: 1;
  }

  .header-middle {
    margin-left: auto;
  }

  .social-media-grid-top.right {
    grid-column-gap: 10px;
  }

  .social-media-grid-top.right.center-tablet {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-horizontal.align-center.pricing-tablet-title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .flex-horizontal.hero-v1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    text-align: center;
  }

  .flex-horizontal.footer-cta {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-horizontal.card-newsletter {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .flex-horizontal.justify-center---tablet {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-horizontal.justify-center---tablet {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-horizontal.category-title.center---tablet {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .flex-horizontal.card-product-page-title-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hidden-on-tablet {
    display: none;
  }

  .grid-footer-v1---5-column {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-logo-wrapper.v2 {
    margin-bottom: 5px;
  }

  .nav-menu-list-wrapper {
    margin-right: 40px;
  }

  .footer-top {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .footer-top.v2 {
    padding-top: 40px;
  }

  .footer-middle {
    padding-top: 70px;
    padding-bottom: 40px;
  }

  .tablet-width-100 {
    width: 100%;
  }

  .image.cover {
    width: 100%;
    max-width: 100%;
  }

  .image.cover.card-cta-v1-image {
    left: 0%;
    width: 128%;
    margin-top: -14px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 50% 0%;
    object-position: 50% 0%;
  }

  .image.cover.hero-v1-image {
    width: 129.3%;
    max-width: none;
    margin-right: -5px;
  }

  .pricing-content-wrapper.left.space {
    max-height: 15px;
    min-height: 15px;
  }

  .pricing-content-wrapper.left.title {
    max-height: none;
    min-height: auto;
    padding-top: 18px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .pricing-content-wrapper.left.title.main {
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-image: linear-gradient(180deg, #def2e6, #def2e6), linear-gradient(88deg, #7fdca4, hsla(0, 0%, 100%, 0.27) 100%, rgba(127, 220, 164, 0.13) 0, #fcfefd);
  }

  .pricing-content-wrapper.mobile {
    padding-right: 28px;
    padding-left: 28px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .pricing-content-wrapper.mobile.accent {
    border-radius: 10px;
  }

  .pricing-content-wrapper.mobile.last {
    max-height: none;
    min-height: auto;
    margin-bottom: 0px;
    padding: 30px 0px 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .image-wrapper.hero-v1 {
    max-width: 700px;
    margin-right: 0px;
    margin-bottom: 35px;
    margin-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .image-wrapper.card-featured-feature-image {
    width: auto;
    max-width: none;
    margin: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-wrapper.card-advantage-02-image {
    max-width: 100%;
    margin-top: auto;
    margin-bottom: -70px;
  }

  .image-wrapper.card-advantage-03-image {
    max-width: 100%;
    margin-right: -23px;
    margin-bottom: -28px;
    margin-left: -23px;
  }

  .image-wrapper.card-advantage-04-image {
    max-width: 100%;
    margin: -16px -26px -28px;
  }

  .image-wrapper.card-cta-v1-image {
    position: static;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .image-wrapper.circle-image.hero-v2-avatar---01 {
    margin-left: -4%;
  }

  .image-wrapper.circle-image.hero-v2-avatar---02 {
    margin-top: -21%;
  }

  .image-wrapper.circle-image.hero-v2-avatar---03 {
    margin-right: -6%;
  }

  .image-wrapper.circle-image.hero-v2-avatar---04 {
    margin-bottom: -19%;
    margin-left: 2%;
  }

  .image-wrapper.circle-image.hero-v2-avatar---05 {
    margin-right: -1%;
    margin-bottom: -18%;
  }

  .image-wrapper.hero-v2-bg {
    top: -40%;
  }

  .image-wrapper.hero-v2-gradient-bg---01 {
    left: -53%;
    top: -11%;
  }

  .image-wrapper.hero-v2-gradient-bg---02 {
    left: 47%;
    top: -20%;
  }

  .image-wrapper.logo-strip-gradient-bg---01 {
    top: -61%;
  }

  .image-wrapper.hero-v1-gradient-bg {
    top: -5%;
  }

  .image-wrapper.hero-v3-gradient-bg {
    left: -72%;
    top: -27%;
  }

  .image-wrapper.card-blog-post-author-image {
    max-width: 35%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .image-wrapper.contact-links-bg {
    top: 33%;
  }

  .image-wrapper.card-product-page-icon {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 14px;
  }

  .image-wrapper.integration-page-image {
    margin-top: -16px;
    margin-bottom: -115px;
  }

  .image-wrapper.careers-hero-bg---01 {
    top: -35%;
  }

  .image-wrapper.careers-hero-bg---02 {
    top: -39%;
  }

  .image-wrapper.career-page-bg---01 {
    top: -24%;
  }

  .image-wrapper.career-page-bg---02 {
    top: -39%;
  }

  .image-wrapper.features-hero-bg {
    top: -8%;
  }

  .image-wrapper.coming-soon-bg---01 {
    left: -39%;
  }

  .image-wrapper.coming-soon-bg---02 {
    right: -42%;
  }

  .image-wrapper.sign-up-bg---01 {
    left: -35%;
    top: -32%;
  }

  .image-wrapper.sign-up-bg---02 {
    bottom: -26%;
  }

  .image-wrapper.sign-in-bg---01 {
    left: -35%;
    top: -32%;
  }

  .image-wrapper.sign-in-bg---02 {
    right: -26%;
    bottom: -21%;
  }

  .image-wrapper.not-found-bg---01 {
    left: -78%;
    top: -37%;
  }

  .image-wrapper.not-found-bg---02 {
    right: -42%;
  }

  ._404-not-found {
    margin-bottom: -38px;
    font-size: 208px;
  }

  .template-pages---phography-wrapper {
    border-radius: 10px;
  }

  .cart-button-wrapper {
    z-index: 2;
  }

  .checkout-form {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
  }

  .checkout-block-content.pd-top-0px {
    padding-top: 0px;
  }

  .image-home-link-wrapper {
    max-width: 260px;
  }

  .figma-file-image-wrapper {
    max-width: 100%;
  }

  .figma-file-text-wrapper {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .position-absolute.card-pricing-fade {
    min-height: 106px;
  }

  .mg-top-116px {
    margin-top: 93px;
  }

  .masonry-grid-v1 {
    -ms-grid-columns: 1fr 0.25fr 1fr;
    grid-template-columns: 1fr 0.25fr 1fr;
  }

  .card-advantage-01-content {
    margin-top: auto;
  }

  .card-advantage-02-content {
    max-width: 460px;
    margin-bottom: -18px;
    padding: 43px 24px 33px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .card-advantage-03-content {
    max-width: 460px;
    margin-top: auto;
    padding-right: 24px;
    padding-left: 24px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .card-advantage-04-content {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-cta-v1-image-wrapper {
    position: static;
    max-width: 100%;
    margin: 80px 0px 32px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }

  .card-footer-cta-icon {
    margin-right: -2px;
    margin-bottom: 25px;
  }

  .footer-newsletter-form-block {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .menu-wrapper---main {
    max-width: 100%;
  }

  .grid-footer---main {
    grid-auto-flow: row;
    grid-row-gap: 12px;
    grid-template-areas: ".";
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto 12px auto;
    grid-template-rows: auto auto;
  }

  .text-center---tablet {
    text-align: center;
  }

  .mg-bottom-80px---tablet {
    margin-bottom: 80px;
  }

  .mg-bottom-255px {
    margin-bottom: 213px;
  }

  .card-stats-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }

  .career-item-main-content {
    margin-top: 20px;
    margin-left: 0px;
  }

  .career-item-details {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .career-item-main-wrapper {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .big-number {
    margin-bottom: 10px;
    font-size: 5vw;
  }

  .card-stats-main-wrapper {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0px;
  }

  .categories-wrapper.blog-categories {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .category-item.blog-category {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .logo-strips-right-wrapper {
    max-width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    grid-column-gap: 50px;
  }

  .logo-strips-right-wrapper.v2 {
    grid-column-gap: 50px;
  }

  .logo-strip-v2-main-wrapper {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 25px;
  }

  .card-newsletter-icon {
    margin-right: -2px;
    margin-bottom: 25px;
  }

  .card-blog-post-author-content {
    padding-right: 50px;
    padding-left: 40px;
  }

  .card-plan-item-top-wrapper {
    padding-right: 34px;
    padding-bottom: 132px;
    padding-left: 34px;
  }

  .pricing-main-wrapper {
    max-width: 100%;
  }

  .mg-top-0px---tablet {
    margin-top: 0px;
  }

  .card-product-page-divider {
    margin-right: 30px;
    margin-left: 30px;
  }

  .add-cart {
    min-height: 449px;
  }

  .rich-text-v2 h2 {
    font-size: 32px;
  }

  .rich-text-v2 h3 {
    font-size: 22px;
  }

  .mg-top-0px {
    margin-top: 0px;
  }

  .mg-top-155px {
    margin-top: 100px;
  }

  .rich-text-v4 h2 {
    font-size: 26px;
  }

  .rich-text-v5 h2 {
    font-size: 32px;
  }

  .rich-text-v5 h3 {
    font-size: 22px;
  }

  .card-team-page-content {
    max-width: 51%;
    padding-left: 24px;
  }

  .footer-small {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .grid-footer-small-logo---paragraph {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
    text-decoration: none;
  }

  .mg-bottom-95px {
    margin-bottom: 80px;
  }

  .not-found-images-wrapper {
    min-width: auto;
    margin-right: 0%;
  }

  .not-found-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .hamburger-menu-wrapper {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-row-gap: 8px;
  }

  .header-nav-menu-top {
    position: -webkit-sticky;
    position: sticky;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 1;
    display: block;
    min-height: 130px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(61%, #fff), to(transparent));
    background-image: linear-gradient(180deg, #fff 61%, transparent);
  }

  .typing-animation-wrapper {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .typing-animation-wrapper.center {
    max-width: 600px;
  }

  .typing-animation-line {
    width: 12px;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }

  h1 {
    margin-bottom: 10px;
    font-size: 38px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  blockquote {
    padding: 60px 34px;
    border-radius: 18px;
    font-size: 20px;
  }

  .grid-2-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.style---styleguide-wrapper {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .grid-2-columns.form {
    grid-column-gap: 24px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns.title-and-paragraph {
    grid-row-gap: 12px;
    -ms-grid-columns: 1.1fr;
    grid-template-columns: 1.1fr;
  }

  .grid-2-columns.template-page-sidebar {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.changelog-item.first {
    grid-column-gap: 30px;
  }

  .grid-2-columns.checkout-page {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons.card-blog-post-author-top {
    grid-row-gap: 10px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons.pricing {
    grid-row-gap: 50px;
  }

  .grid-2-columns.card-newsletter-grid {
    max-width: 400px;
  }

  .grid-2-columns.blog-post-page-grid {
    grid-row-gap: 40px;
  }

  .grid-2-columns.conatct-form-section-grid {
    grid-row-gap: 50px;
  }

  .grid-2-columns.careers-hero-images-grid {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .grid-2-columns.demo-grid {
    grid-row-gap: 70px;
  }

  .card.link-card.search-item {
    padding-right: 34px;
    padding-left: 34px;
  }

  .card.link-card.career-item {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .card.link-card.contact-link {
    padding: 40px 34px 54px;
  }

  .card.link-card.contact-icon-link {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .card.sticky-top.top-24px.static-mbl {
    position: static;
  }

  .card.featured-feature {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .card.feature {
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .card.cta-v1 {
    padding-top: 70px;
    padding-right: 34px;
    padding-left: 34px;
  }

  .card.advantage-03 {
    padding-top: 0px;
  }

  .card.advantage-04 {
    padding-bottom: 24px;
  }

  .card.testimonial-twitter-strip {
    padding-top: 41px;
    padding-bottom: 41px;
  }

  .card.footer-cta {
    padding: 40px 34px;
  }

  .card.footer-newsletter {
    padding: 40px 34px;
  }

  .card.stats {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .card.value {
    padding-top: 48px;
    padding-bottom: 45px;
  }

  .card.logo-strip {
    height: 111px;
    max-height: 111px;
    min-height: 111px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .card.newsletter {
    padding: 60px 34px;
  }

  .card.blog-post-body {
    padding: 65px 34px;
  }

  .card.contact-form {
    padding: 50px 34px;
  }

  .card.accordion-item {
    padding-right: 34px;
    padding-left: 34px;
  }

  .card.product-page {
    padding-top: 50px;
    padding-right: 34px;
    padding-left: 34px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .card.product-description {
    padding: 60px 34px;
  }

  .card.integration-body {
    padding: 64px 34px 51px;
  }

  .card.careers-perks {
    padding: 83px 34px;
  }

  .card.career-body {
    padding: 62px 34px 50px;
  }

  .card.team-page {
    padding-top: 34px;
    padding-right: 34px;
    padding-left: 34px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card.demo-form {
    padding: 50px 34px;
  }

  .card.sign-up-form {
    padding: 58px 34px 63px;
  }

  .card.sign-in-form {
    padding-top: 56px;
    padding-right: 34px;
    padding-left: 34px;
  }

  .card.password-page {
    max-width: 551px;
    padding: 51px 34px;
  }

  .style---color-block {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .grid-4-columns.pricing-tablet-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-4-columns.pricing-tablet-grid.mobile {
    max-width: 500px;
    grid-row-gap: 70px;
  }

  .style---block-sub-heading.border {
    margin-bottom: 48px;
    padding-bottom: 24px;
  }

  .divider {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .style---typography-block-grid {
    grid-row-gap: 28px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .style---content-block {
    margin-bottom: 64px;
  }

  .mg-bottom-16px {
    margin-bottom: 13px;
  }

  .mg-bottom-24px {
    margin-bottom: 20px;
  }

  .mg-bottom-24px.keep {
    margin-bottom: 24px;
  }

  .mg-bottom-32px {
    margin-bottom: 24px;
  }

  .mg-bottom-32px.keep {
    margin-bottom: 32px;
  }

  .mg-bottom-40px {
    margin-bottom: 32px;
  }

  .mg-bottom-48px {
    margin-bottom: 40px;
  }

  .mg-bottom-56px {
    margin-bottom: 40px;
  }

  .mg-bottom-56px.keep {
    margin-bottom: 56px;
  }

  .mg-top-48px {
    margin-top: 40px;
  }

  .mg-top-64px {
    margin-top: 50px;
  }

  .text-200 {
    font-size: 16px;
  }

  .text-200.list {
    line-height: 22px;
  }

  .display-1 {
    font-size: 46px;
  }

  .display-1.mg-bottom-7px.typing-01 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-02 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-03 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-04 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-05 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-06 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-07 {
    font-size: 40px;
  }

  .display-1.mg-bottom-7px.typing-08 {
    font-size: 40px;
  }

  .grid-1-column.gap-column-24px {
    grid-row-gap: 20px;
  }

  .grid-1-column.gap-row-32px {
    grid-row-gap: 28px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-1-column.career-item-details-grid {
    grid-row-gap: 15px;
  }

  .display-2 {
    font-size: 36px;
  }

  .display-2.mg-bottom-0 {
    font-size: 40px;
  }

  .display-3 {
    font-size: 26px;
  }

  .display-4 {
    font-size: 20px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 120px;
  }

  .paragraph-large {
    font-size: 20px;
  }

  .text-400 {
    font-size: 20px;
  }

  .text-300 {
    font-size: 18px;
  }

  .text-100 {
    font-size: 14px;
  }

  .text-100.keep {
    font-size: 16px;
  }

  .grid-3-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns.gap-row-80px {
    grid-row-gap: 40px;
  }

  .grid-3-columns.style---buttons-grid {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .grid-3-columns.style---buttons-grid.download-grid {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
  }

  .grid-3-columns.pricing {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns.integration-icons {
    max-width: 183px;
    grid-column-gap: 20px;
  }

  .grid-3-columns.careers-perks-grid {
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;
    grid-row-gap: 60px;
  }

  .style---shadow-card {
    border-radius: 18px;
  }

  .style---heading {
    padding: 104px 24px;
  }

  .inner-container.card-featured-feature-content {
    margin-bottom: 40px;
  }

  .inner-container._500px---mbl {
    max-width: 500px;
  }

  .inner-container._400px---mbl {
    max-width: 400px;
  }

  .inner-container._460px---mbl {
    max-width: 460px;
  }

  .inner-container.center---mbl {
    margin-right: auto;
    margin-left: auto;
  }

  .inner-container._100---mbl {
    max-width: 100%;
  }

  .inner-container._350px---mbl {
    max-width: 350px;
  }

  .inner-container._300px---mbl {
    max-width: 300px;
  }

  .inner-container.card-career-item-left {
    margin-right: 0px;
    margin-bottom: 25px;
  }

  .inner-container.card-career-item-right {
    max-width: none;
  }

  .inner-container._600px---mbl {
    max-width: 600px;
  }

  .inner-container._450px---mbl {
    max-width: 450px;
  }

  .inner-container._550px---mbl {
    max-width: 550px;
  }

  .inner-container._250px---mbl {
    max-width: 250px;
  }

  .inner-container.card-product-page-right {
    max-width: 100%;
  }

  .inner-container.career-icon {
    max-width: 63px;
  }

  .inner-container._520px---mbl {
    max-width: 520px;
  }

  .inner-container.not-found-content {
    max-width: 500px;
    margin-bottom: 50px;
  }

  .inner-container._382px {
    max-width: 382px;
  }

  .style---content-heading {
    margin-bottom: 36px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-radius: 18px;
  }

  .style---heading-icon-wrapper {
    max-width: 58px;
    border-radius: 16px;
  }

  .style---content-wrapper {
    padding-right: 24px;
    padding-left: 24px;
  }

  .border-radius-32px {
    border-radius: 20px;
  }

  .style---card-wrapper {
    border-radius: 18px;
  }

  .style---bg-white {
    padding: 34px 24px;
  }

  .style-buttons-dark {
    padding-right: 24px;
    padding-left: 24px;
  }

  .btn-primary {
    padding: 20px 28px;
    font-size: 16px;
  }

  .btn-primary.small {
    padding: 14px 18px;
    font-size: 14px;
  }

  .btn-primary.small.header-btn-hidde-on-mb {
    display: none;
  }

  .btn-primary.large {
    padding: 22px 40px;
    font-size: 18px;
  }

  .btn-primary.button-row {
    margin-right: 16px;
  }

  .btn-primary.pagination {
    margin-top: 50px;
  }

  .btn-primary.form-btn-inside.pd-default {
    padding-right: 28px;
    padding-left: 28px;
  }

  .btn-secondary {
    padding: 20px 28px;
    font-size: 16px;
  }

  .btn-secondary.small {
    padding: 14px 18px;
    font-size: 14px;
  }

  .btn-secondary.large {
    padding: 22px 40px;
    font-size: 18px;
  }

  .btn-secondary.pagination {
    margin-top: 50px;
  }

  .badge-primary {
    padding: 16px 24px;
    font-size: 16px;
  }

  .badge-primary.small {
    padding: 12px 20px;
    font-size: 14px;
  }

  .badge-primary.large {
    padding: 20px 32px;
    font-size: 18px;
  }

  .badge-secondary {
    padding: 16px 24px;
    font-size: 16px;
  }

  .badge-secondary.small {
    padding: 12px 20px;
    font-size: 14px;
  }

  .badge-secondary.large {
    padding: 20px 32px;
    font-size: 18px;
  }

  .badge-secondary.category-link {
    padding: 14px;
    font-size: 14px;
  }

  .input {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .input.large.button-inside {
    min-height: 68px;
  }

  .input.button-inside {
    min-height: 64px;
    padding-right: 170px;
  }

  .text-area {
    border-radius: 18px;
    font-size: 16px;
  }

  .style---avatars-grid {
    grid-template-columns: repeat(auto-fit, 180px);
  }

  .avatar-circle._02 {
    width: 40px;
    height: 40px;
    max-height: 40px;
    max-width: 40px;
  }

  .avatar-circle._03 {
    width: 56px;
    height: 56px;
    max-height: 56px;
    max-width: 56px;
  }

  .avatar-circle._04 {
    width: 64px;
    height: 64px;
    max-height: 64px;
    max-width: 64px;
  }

  .avatar-circle._05 {
    width: 80px;
    height: 80px;
    max-height: 80px;
    max-width: 80px;
  }

  .avatar-circle._06 {
    width: 120px;
    height: 120px;
    max-height: 120px;
    max-width: 120px;
  }

  .avatar-circle._07 {
    width: 140px;
    height: 140px;
    max-height: 140px;
    max-width: 140px;
  }

  .avatar-circle._08 {
    width: 220px;
    height: 220px;
    max-height: 220px;
    max-width: 220px;
  }

  .style---icons-grid {
    grid-template-columns: repeat(auto-fit, 56px);
  }

  .square-icon {
    max-width: 56px;
  }

  .line-rounded-icon.success-message-check.large {
    font-size: 68px;
  }

  .style---line-icons-grid {
    grid-template-columns: repeat(auto-fit, 18px);
  }

  .rich-text img {
    border-radius: 20px;
  }

  .rich-text p {
    margin-top: 10px;
  }

  .rich-text h2 {
    margin-bottom: 10px;
  }

  .rich-text h3 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .rich-text ul {
    margin-bottom: 32px;
  }

  .rich-text ol {
    margin-bottom: 32px;
  }

  .rich-text h6 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text h5 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text h4 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text blockquote {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .buttons-row.center-mobile {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .buttons-row.center---mbl {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .style---logo-sidebar {
    max-width: 210px;
  }

  .elements---page-hero {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section.small {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section.template-pages-hero {
    padding-top: 116px;
    padding-bottom: 116px;
  }

  .section.hero.v1 {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  .section.hero.v2 {
    padding-top: 100px;
    padding-bottom: 111px;
  }

  .section.hero.v3 {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.hero.v4 {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  .section.hero.v5 {
    padding-top: 60px;
    padding-bottom: 130px;
  }

  .section.hero.v6 {
    padding-top: 60px;
    padding-bottom: 70px;
  }

  .section.hero.v7 {
    padding-top: 60px;
    padding-bottom: 73px;
  }

  .section.hero.v8 {
    padding-top: 60px;
    padding-bottom: 100px;
  }

  .section.hero.v9 {
    padding-top: 60px;
    padding-bottom: 83px;
  }

  .section.hero.v10 {
    padding-top: 60px;
    padding-bottom: 104px;
  }

  .section.hero.v11 {
    padding-top: 108px;
    padding-bottom: 108px;
  }

  .section.pd-100px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.pd-bottom-240px {
    padding-bottom: 138px;
  }

  .section.pd-bottom-160px {
    padding-bottom: 82px;
  }

  .section.pd-bottom-280px {
    padding-bottom: 143px;
  }

  .section.pd-200px {
    padding-top: 116px;
    padding-bottom: 116px;
  }

  .section.pd-top-100px {
    padding-top: 60px;
  }

  .section.pd-90px {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-180px {
    padding-top: 104px;
    padding-bottom: 104px;
  }

  .section.pd-top-90px {
    padding-top: 60px;
  }

  .section.pd-bottom-224px {
    padding-bottom: 115px;
  }

  .section.pd-148px {
    padding-top: 86px;
    padding-bottom: 86px;
  }

  .section.pd-148px.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-bottom-80px {
    padding-bottom: 60px;
  }

  .section.pd-top-80px {
    padding-top: 60px;
  }

  .section.pd-bottom-0px {
    padding-bottom: 0px;
  }

  .section.pd-150px {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  .section.pd-150px.pd-top-0px {
    padding-top: 0px;
  }

  .top-bar-wrapper {
    padding-right: 36px;
    padding-left: 36px;
  }

  .top-bar-padding {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .top-bar-padding.small-padding {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .top-bar-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .elements---element-title {
    margin-top: 48px;
    padding-right: 24px;
    padding-bottom: 20px;
    padding-left: 24px;
  }

  .heading-h1-size {
    font-size: 38px;
  }

  .heading-h2-size {
    font-size: 26px;
  }

  .heading-h3-size {
    font-size: 22px;
  }

  .heading-h4-size {
    font-size: 20px;
  }

  .heading-h5-size {
    font-size: 16px;
  }

  .heading-h6-size {
    font-size: 14px;
  }

  .header-wrapper.header-small {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header-nav-link.cart-link {
    font-size: 16px;
  }

  .header-nav-link.login-link---mobile {
    font-size: 18px;
  }

  .header-logo-link {
    max-width: 152px;
  }

  .hamburger-menu-btn {
    width: 45px;
    height: 45px;
    min-height: 45px;
    min-width: 45px;
    margin-left: 20px;
  }

  .hamburger-menu-btn.btn-primary {
    padding: 0px;
  }

  .btn-circle-primary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 22px;
  }

  .btn-circle-primary.small {
    width: 46px;
    height: 46px;
    min-height: 46px;
    min-width: 46px;
    font-size: 20px;
  }

  .btn-circle-primary.large {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    font-size: 26px;
  }

  .btn-circle-secondary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 22px;
  }

  .btn-circle-secondary.small {
    width: 46px;
    height: 46px;
    min-height: 46px;
    min-width: 46px;
    font-size: 20px;
  }

  .btn-circle-secondary.large {
    width: 72px;
    height: 72px;
    min-height: 72px;
    min-width: 72px;
    font-size: 26px;
  }

  .nav-menu-list-wrapper {
    margin-right: 20px;
  }

  .footer-top {
    padding-bottom: 30px;
  }

  .footer-middle {
    padding-top: 60px;
    padding-bottom: 30px;
  }

  .grid-subscribe-form-button-down.coming-soon-form-grid {
    grid-row-gap: 20px;
  }

  .text-center.mobile-l {
    text-align: center;
  }

  .accordion-spacer {
    min-height: 14px;
  }

  .accordion-title {
    font-size: 20px;
    line-height: 30px;
  }

  .tabs-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .tabs-menu.integration-menu {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .empty-state.card-empty.pricing {
    margin-right: 34px;
    margin-left: 34px;
  }

  .pd---content-inside-card {
    padding: 32px 24px 40px;
  }

  .pd---content-inside-card.large {
    padding: 56px 40px;
  }

  .pd---content-inside-card.large.template-pages {
    padding-right: 32px;
    padding-left: 32px;
  }

  .pricing-content-wrapper.empty {
    display: none;
  }

  .pricing-content-wrapper.mobile {
    max-height: 50px;
    min-height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .pricing-content-wrapper.mobile.last {
    padding-top: 25px;
  }

  .image-wrapper.order-item-image-wrapper {
    max-width: 40px;
  }

  .image-wrapper.hero-v1 {
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 0%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .image-wrapper.card-feature-image._01 {
    max-width: 128px;
  }

  .image-wrapper.card-feature-image._03 {
    max-width: 133px;
  }

  .image-wrapper.card-feature-image._02 {
    max-width: 206px;
  }

  .image-wrapper.card-advantage-02-image {
    max-width: 95%;
    margin-right: -24px;
    margin-left: -24px;
  }

  .image-wrapper.card-advantage-03-image {
    max-width: 94%;
    margin-top: -18px;
    margin-bottom: 0px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }

  .image-wrapper.card-advantage-04-image {
    margin-top: -22px;
    margin-right: -36px;
    margin-left: -36px;
  }

  .image-wrapper.step-image._01 {
    max-width: 132px;
  }

  .image-wrapper.step-image._03 {
    max-width: 166px;
  }

  .image-wrapper.step-image._02 {
    max-width: 150px;
  }

  .image-wrapper.step-image._04 {
    max-width: 138px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---01 {
    display: none;
  }

  .image-wrapper.circle-image.hero-v2-avatar---02 {
    display: none;
    max-width: 88px;
    margin-top: -128px;
    margin-left: -15.5px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---03 {
    display: none;
    max-width: 90px;
    margin-top: -145px;
    margin-right: -14px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---04 {
    display: none;
    max-width: 92px;
    margin-bottom: -136px;
    margin-left: 0px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---05 {
    display: none;
    max-width: 92px;
    margin-right: 0px;
    margin-bottom: -133px;
  }

  .image-wrapper.hero-v2-bg {
    top: -85px;
    width: 1180px;
  }

  .image-wrapper.value-image._01 {
    max-width: 138px;
  }

  .image-wrapper.value-image._02 {
    max-width: 130px;
    margin-right: -14px;
    margin-bottom: -24px;
    margin-left: -15px;
  }

  .image-wrapper.value-image._03 {
    max-width: 133px;
  }

  .image-wrapper.value-image._04 {
    max-width: 125px;
  }

  .image-wrapper.value-image._05 {
    max-width: 125px;
  }

  .image-wrapper.value-image._06 {
    max-width: 118px;
    margin-bottom: -22px;
  }

  .image-wrapper.career-item-icon {
    margin-top: -9px;
  }

  .image-wrapper.hero-v2-gradient-bg---01 {
    top: -10%;
  }

  .image-wrapper.hero-v2-gradient-bg---02 {
    left: 64%;
    top: -20%;
  }

  .image-wrapper.logo-strip-gradient-bg---01 {
    left: -54%;
    top: -30%;
  }

  .image-wrapper.logo-strip-gradient-bg---02 {
    top: -45%;
  }

  .image-wrapper.hero-v1-gradient-bg {
    left: -82%;
  }

  .image-wrapper.hero-v3-gradient-bg {
    left: -122%;
    top: -19%;
  }

  .image-wrapper.card-blog-post-author-image {
    max-width: 44%;
  }

  .image-wrapper.contact-link-icon._01 {
    max-width: 102px;
  }

  .image-wrapper.contact-link-icon._02 {
    max-width: 108px;
  }

  .image-wrapper.contact-link-icon._03 {
    max-width: 102px;
  }

  .image-wrapper.contact-form-bg {
    left: -59%;
    top: -27%;
  }

  .image-wrapper.card-product-page-icon {
    margin-bottom: 10px;
  }

  .image-wrapper.integrations-hero {
    max-width: 1320px;
    min-width: 1320px;
  }

  .image-wrapper.integration-page-image {
    margin-top: -11px;
    margin-bottom: -16%;
  }

  .image-wrapper.integration-app {
    margin: -8px;
  }

  .image-wrapper.career-icon-image {
    margin-bottom: -23px;
  }

  .image-wrapper.features-hero-bg {
    top: -5%;
  }

  .image-wrapper.card-team-page-image {
    width: auto;
    max-width: 100%;
  }

  .image-wrapper.coming-soon-icon-image {
    margin-top: -14px;
    margin-bottom: -30px;
  }

  .image-wrapper.image-circle.avatar-left {
    max-width: 63px;
  }

  .image-wrapper.not-found-image---02 {
    margin-right: -19px;
  }

  .image-wrapper.not-found-bg---01 {
    top: -26%;
  }

  .image-wrapper.not-found-bg---02 {
    right: -49%;
    bottom: -21%;
  }

  .image-wrapper.password-page-icon-image {
    margin-bottom: -10px;
  }

  .image-wrapper.cart-item-image-wrapper {
    max-width: 40px;
  }

  ._404-not-found {
    margin-bottom: -25px;
    font-size: 160px;
  }

  .checkout-form {
    max-width: 600px;
  }

  .checkout-block-content.pd-top-0px {
    padding-top: 0px;
  }

  .home-pages-top-wrapper {
    margin-bottom: 12px;
  }

  .image-home-link-wrapper {
    max-width: 209px;
    margin-right: 6px;
    margin-left: 6px;
    border-radius: 16px;
  }

  .figma-file-text-wrapper {
    padding-right: 48px;
    padding-left: 48px;
  }

  .badge-home {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 14px;
    line-height: 22px;
  }

  .home-surprises-card {
    border-radius: 28px;
  }

  .card-home-pages-wrapper {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 40px;
  }

  .mg-bottom-12px {
    margin-bottom: 10px;
  }

  .cta-image-card {
    width: 980px;
    max-width: 980px;
  }

  .hidden-on-mbl {
    display: none;
  }

  .position-absolute.card-pricing-fade {
    min-height: 112px;
  }

  .mg-top-116px {
    margin-top: 80px;
  }

  .mg-bottom-19px {
    margin-bottom: 16px;
  }

  .mg-bottom-42px {
    margin-bottom: 40px;
  }

  .mg-bottom-38px {
    margin-bottom: 30px;
  }

  .masonry-grid-v1 {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .card-advantage-01-content {
    max-width: 450px;
    padding-right: 34px;
    padding-left: 34px;
  }

  .card-advantage-02-content {
    max-width: 450px;
    margin-bottom: -15px;
    padding-right: 34px;
    padding-left: 34px;
  }

  .card-advantage-03-content {
    max-width: 450px;
    padding: 43px 34px 33px;
  }

  .card-advantage-04-content {
    max-width: 460px;
    padding-right: 34px;
    padding-left: 34px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }

  .card-cta-v1-image-wrapper {
    margin-top: 60px;
    margin-bottom: 25px;
  }

  .mg-bottom-30px {
    margin-bottom: 25px;
  }

  .card-footer-cta-icon {
    max-width: 70px;
    margin-bottom: 20px;
  }

  .footer-newsletter-form-block {
    max-width: 450px;
    min-height: 181px;
  }

  .menu-wrapper---main {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-row-gap: 50px;
  }

  .grid-footer---main {
    grid-row-gap: 60px;
  }

  .mg-bottom-80px---tablet {
    margin-bottom: 60px;
  }

  .text-center---mbl {
    text-align: center;
  }

  .mg-top-36px {
    margin-top: 30px;
  }

  .mg-bottom-255px {
    margin-bottom: 177px;
  }

  .mg-bottom-60px {
    margin-bottom: 50px;
  }

  .card-stats-wrapper._02 {
    padding-right: 0px;
    border-right-width: 0px;
  }

  .stats-shadow {
    width: 400px;
  }

  .logo-strip-grid {
    min-width: 1370px;
  }

  .career-item-main-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .big-number {
    font-size: 36px;
  }

  .card-stats-main-wrapper {
    max-width: 580px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0px;
  }

  .card-stats-grid {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .mg-bottom-120px---mbl {
    margin-bottom: 120px;
  }

  .blog-post-featured-content {
    padding-right: 34px;
    padding-left: 34px;
  }

  .mg-bottom-46px {
    margin-bottom: 40px;
  }

  .categories-wrapper {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }

  .category-item {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
  }

  .logo-strips-right-wrapper {
    grid-column-gap: 35px;
  }

  .logo-strips-right-wrapper.v2 {
    grid-column-gap: 30px;
  }

  .logo-strip-v2-main-wrapper {
    max-width: 550px;
  }

  .card-newsletter-icon {
    max-width: 70px;
    margin-bottom: 20px;
  }

  .button-inside-form-block {
    min-height: 64px;
  }

  .blog-post-page-image-shadow {
    width: 400px;
  }

  .card-blog-post-author-content {
    padding-right: 34px;
    padding-left: 24px;
  }

  .card-blog-post-author-social-media {
    margin-top: 15px;
  }

  .contact-icon-link---left {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
  }

  .contact-form-block {
    min-height: 771px;
  }

  .card-plan-item-top-wrapper {
    padding-bottom: 48px;
  }

  .mg-bottom-28px {
    margin-bottom: 23px;
  }

  .mg-top-0px---tablet {
    margin-top: 0px;
  }

  .card-plan-item:last-child {
    padding-bottom: 110px;
  }

  .card-product-page-divider {
    width: 100%;
    min-height: 1px;
    margin: 40px 0px;
  }

  .add-cart {
    min-height: 377px;
  }

  .rich-text-v2 img {
    border-radius: 20px;
  }

  .rich-text-v2 p {
    margin-top: 10px;
  }

  .rich-text-v2 h2 {
    margin-bottom: 10px;
    font-size: 26px;
  }

  .rich-text-v2 h3 {
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .rich-text-v2 figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .rich-text-v2 ul {
    margin-bottom: 32px;
  }

  .rich-text-v2 ol {
    margin-bottom: 32px;
  }

  .rich-text-v2 h6 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v2 h5 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v2 h4 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v2 blockquote {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .mg-top-0px {
    margin-top: 0px;
  }

  .mg-bottom-35px {
    margin-bottom: 28px;
  }

  .rich-text-v3 img {
    border-radius: 20px;
  }

  .rich-text-v3 p {
    margin-top: 10px;
  }

  .rich-text-v3 h2 {
    margin-bottom: 10px;
    font-size: 22px;
  }

  .rich-text-v3 h3 {
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .rich-text-v3 figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .rich-text-v3 ul {
    margin-bottom: 32px;
  }

  .rich-text-v3 ol {
    margin-bottom: 32px;
  }

  .rich-text-v3 h6 {
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .rich-text-v3 h5 {
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .rich-text-v3 h4 {
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 16px;
  }

  .rich-text-v3 blockquote {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .mg-top-155px {
    margin-top: 60px;
  }

  .careers-perk-icon-wrapper._01 {
    max-width: 69px;
  }

  .careers-perk-icon-wrapper._02 {
    max-width: 71px;
  }

  .careers-perk-icon-wrapper._03 {
    max-width: 69px;
  }

  .careers-perk-icon-wrapper._04 {
    max-width: 69px;
  }

  .careers-perk-icon-wrapper._05 {
    max-width: 73px;
  }

  .careers-perk-icon-wrapper._06 {
    max-width: 72px;
  }

  .rich-text-v4 img {
    border-radius: 20px;
  }

  .rich-text-v4 p {
    margin-top: 10px;
  }

  .rich-text-v4 h2 {
    margin-bottom: 10px;
    font-size: 24px;
  }

  .rich-text-v4 h3 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v4 figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .rich-text-v4 ul {
    margin-bottom: 32px;
  }

  .rich-text-v4 ol {
    margin-bottom: 32px;
  }

  .rich-text-v4 h6 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v4 h5 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v4 h4 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v4 blockquote {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .rich-text-v5 img {
    border-radius: 20px;
  }

  .rich-text-v5 p {
    margin-top: 10px;
  }

  .rich-text-v5 h2 {
    margin-bottom: 10px;
    font-size: 26px;
  }

  .rich-text-v5 h3 {
    margin-top: 32px;
    margin-bottom: 10px;
    font-size: 20px;
  }

  .rich-text-v5 figure {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .rich-text-v5 ul {
    margin-bottom: 32px;
  }

  .rich-text-v5 ol {
    margin-bottom: 32px;
  }

  .rich-text-v5 h6 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v5 h5 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v5 h4 {
    margin-top: 32px;
    margin-bottom: 10px;
  }

  .rich-text-v5 blockquote {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .card-team-page-content {
    max-width: 100%;
    padding-bottom: 60px;
    padding-left: 0px;
  }

  .card-team-page-social-media {
    margin-top: 15px;
  }

  .footer-small {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .coming-soon-icon {
    max-width: 93px;
  }

  .coming-soon-form-block {
    min-height: 142px;
  }

  .mg-bottom-95px {
    margin-bottom: 70px;
  }

  .demo-form-block {
    min-height: 812px;
  }

  .sign-up-form-block {
    min-height: 500px;
  }

  .sign-up-form-grid {
    grid-row-gap: 24px;
  }

  .gap-row-26px {
    grid-row-gap: 22px;
  }

  .sign-in-form-block {
    min-height: 347px;
  }

  .sign-in-form-grid {
    grid-row-gap: 24px;
  }

  .not-found-images-wrapper {
    max-width: 600px;
  }

  .password-page-icon {
    max-width: 84px;
  }

  .flex-vertical---mbl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .typing-animation-wrapper {
    max-width: 340px;
  }

  .typing-animation-line {
    width: 8px;
  }

  .html-embed {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 32px;
  }

  blockquote {
    padding: 50px 24px;
    font-size: 18px;
  }

  .grid-2-columns.form {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.changelog-item {
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-2-columns.title-and-buttons.pricing {
    grid-row-gap: 40px;
  }

  .grid-2-columns.conatct-form-section-grid {
    grid-row-gap: 40px;
  }

  .grid-2-columns.careers-hero-images-grid {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .grid-2-columns.demo-grid {
    grid-row-gap: 60px;
  }

  .card.link-card.search-item {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.link-card.career-item {
    padding: 42px 24px;
  }

  .card.link-card.blog-post-author {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .card.link-card.contact-link {
    padding: 32px 24px 43px;
  }

  .card.link-card.contact-icon-link {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }

  .card.link-card.integration-item {
    padding: 43px 24px;
  }

  .card.checkout-block {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.featured-feature {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.feature {
    padding: 32px 24px 42px;
  }

  .card.cta-v1 {
    padding-top: 60px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.testimonial-twitter-strip {
    max-width: 100%;
    padding: 33px 24px;
  }

  .card.footer-cta {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.footer-newsletter {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.stats {
    padding: 40px 20px;
  }

  .card.value {
    padding: 40px 24px 38px;
  }

  .card.logo-strip {
    height: 93px;
    max-height: 93px;
    min-height: 93px;
  }

  .card.newsletter {
    padding: 50px 24px;
  }

  .card.blog-post-body {
    padding: 60px 24px;
  }

  .card.contact-form {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.accordion-item {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.product-page {
    padding: 43px 24px 50px;
  }

  .card.product-description {
    padding: 55px 24px 50px;
  }

  .card.integration-body {
    padding: 53px 24px 43px;
  }

  .card.careers-perks {
    padding: 69px 24px;
  }

  .card.career-body {
    padding-top: 52px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.team-page {
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.demo-form {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card.sign-up-form {
    padding: 50px 24px 60px;
  }

  .card.sign-in-form {
    padding: 48px 24px 50px;
  }

  .card.password-page {
    padding-right: 24px;
    padding-left: 24px;
  }

  .grid-4-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .divider {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .divider._64px {
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .mg-bottom-24px.keep {
    margin-bottom: 24px;
  }

  .mg-bottom-32px.keep {
    margin-bottom: 32px;
  }

  .mg-bottom-56px.keep {
    margin-bottom: 56px;
  }

  .display-1 {
    font-size: 38px;
  }

  .display-1.mg-bottom-7px.typing-01 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-02 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-03 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-04 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-05 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-06 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-07 {
    font-size: 30px;
  }

  .display-1.mg-bottom-7px.typing-08 {
    font-size: 30px;
  }

  .grid-1-column.gap-row-32px {
    grid-row-gap: 24px;
  }

  .grid-1-column.contact-icon-link-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .display-2 {
    font-size: 30px;
  }

  .display-2.mg-bottom-0 {
    font-size: 35px;
  }

  .display-3 {
    font-size: 24px;
  }

  .text-100.keep {
    font-size: 16px;
  }

  .grid-3-columns {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .grid-3-columns.integration-icons {
    max-width: 173px;
    grid-column-gap: 18px;
  }

  .style---heading {
    margin-bottom: 40px;
    padding-top: 66px;
    padding-bottom: 66px;
  }

  .inner-container._100---mbp {
    max-width: 100%;
  }

  .inner-container._250px---mbp {
    max-width: 250px;
  }

  .inner-container._400px---mbp {
    max-width: 400px;
  }

  .inner-container._300px---mbp {
    max-width: 300px;
  }

  .inner-container.card-career-item-left {
    max-width: 371px;
  }

  .inner-container._380px---mbp {
    max-width: 380px;
  }

  .style---content-heading {
    padding-right: 22px;
    padding-left: 22px;
  }

  .style---heading-icon-wrapper {
    width: 58px;
    margin-right: 14px;
    border-radius: 16px;
  }

  .btn-primary {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    box-shadow: none;
    white-space: normal;
  }

  .btn-primary.large {
    padding-right: 20px;
    padding-left: 20px;
  }

  .btn-primary.inside-input {
    position: static;
  }

  .btn-primary.button-row {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .btn-primary.pagination {
    margin-top: 15px;
    margin-right: 0px;
    margin-left: 0px;
  }

  .btn-primary.form-btn-inside {
    width: auto;
  }

  .btn-primary.form-btn-inside.pd-default {
    padding-right: 20px;
    padding-left: 20px;
  }

  .btn-secondary {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    white-space: normal;
  }

  .btn-secondary.large {
    padding-right: 20px;
    padding-left: 20px;
  }

  .btn-secondary.width-100.log-social-media {
    font-size: 15px;
  }

  .btn-secondary.pagination {
    margin-right: 0px;
    margin-left: 0px;
  }

  .badge-secondary.category-link {
    padding: 2px 18px;
  }

  .input {
    padding-right: 20px;
    padding-left: 20px;
  }

  .input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 16px;
  }

  .input:-ms-input-placeholder {
    font-size: 16px;
    line-height: 16px;
  }

  .input::-ms-input-placeholder {
    font-size: 16px;
    line-height: 16px;
  }

  .input::placeholder {
    font-size: 16px;
    line-height: 16px;
  }

  .input.large.button-inside {
    min-height: 64px;
  }

  .input.button-inside {
    margin-bottom: 16px;
    padding-right: 20px;
  }

  .input.icon-left {
    padding-left: 40px;
    background-position: 16px 50%;
  }

  .text-area {
    padding: 20px;
  }

  .text-area.icon-left {
    padding-left: 40px;
    background-position: 16px 24px;
  }

  .checkbox-field-wrapper {
    font-size: 16px;
    line-height: 20px;
  }

  .checkbox-field-wrapper.large {
    font-size: 18px;
    line-height: 22px;
  }

  .checkbox {
    min-height: 22px;
    min-width: 22px;
  }

  .checkbox.large {
    min-height: 26px;
    min-width: 26px;
  }

  .radio-button-field-wrapper {
    font-size: 16px;
    line-height: 22px;
  }

  .radio-button-field-wrapper.large {
    font-size: 18px;
    line-height: 24px;
  }

  .radio-button {
    min-height: 22px;
    min-width: 22px;
  }

  .radio-button.large {
    min-height: 26px;
    min-width: 26px;
  }

  .square-icon {
    max-width: 56px;
  }

  .social-icon-square {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    border-radius: 6px;
    font-size: 14px;
  }

  .line-rounded-icon.success-message-check.large {
    font-size: 58px;
  }

  .line-square-icon.top-bar-close-icon {
    padding-right: 12px;
    padding-left: 12px;
  }

  .rich-text img {
    border-radius: 10px;
  }

  .rich-text figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text ul {
    padding-left: 20px;
  }

  .rich-text ol {
    padding-left: 20px;
  }

  .rich-text blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .buttons-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .container-default {
    padding-right: 20px;
    padding-left: 20px;
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.small {
    padding-top: 64px;
    padding-bottom: 64px;
  }

  .section.template-pages-hero {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section.template-pages-hero.short {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  .section.hero-page {
    padding-bottom: 94px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(76%, #fff), color-stop(0, #508dff));
    background-image: linear-gradient(180deg, #fff 76%, #508dff 0);
  }

  .section.hero.v1 {
    padding-top: 40px;
    padding-bottom: 70px;
  }

  .section.hero.v2 {
    padding-top: 80px;
    padding-bottom: 93px;
  }

  .section.hero.v3 {
    padding-top: 50px;
  }

  .section.hero.v4 {
    padding-top: 50px;
  }

  .section.hero.v5 {
    padding-top: 50px;
    padding-bottom: 110px;
  }

  .section.hero.v6 {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .section.hero.v7 {
    padding-top: 50px;
    padding-bottom: 60px;
  }

  .section.hero.v8 {
    padding-top: 50px;
    padding-bottom: 80px;
  }

  .section.hero.v9 {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  .section.hero.v10 {
    padding-top: 50px;
    padding-bottom: 87px;
  }

  .section.hero.v11 {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .section.pd-bottom-240px {
    padding-bottom: 116px;
  }

  .section.pd-bottom-160px {
    padding-bottom: 66px;
  }

  .section.pd-bottom-280px {
    padding-bottom: 115px;
  }

  .section.pd-200px {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  .section.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-180px {
    padding-top: 88px;
    padding-bottom: 88px;
  }

  .section.pd-top-90px {
    padding-top: 60px;
  }

  .section.pd-bottom-224px {
    padding-bottom: 92px;
  }

  .section.pd-148px {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section.pd-148px.pd-top-0px {
    padding-top: 0px;
  }

  .section.pd-bottom-80px {
    padding-bottom: 60px;
  }

  .section.pd-top-80px {
    padding-top: 60px;
  }

  .section.pd-bottom-0px {
    padding-bottom: 0px;
  }

  .section.pd-150px {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .section.pd-150px.pd-top-0px {
    padding-top: 0px;
  }

  .top-bar-wrapper {
    padding-right: 19px;
    padding-left: 19px;
  }

  .elements---element-title.large {
    margin-top: 53px;
  }

  .heading-h1-size {
    font-size: 32px;
  }

  .header-nav-link {
    font-size: 28px;
  }

  .header-logo-link {
    max-width: 148px;
  }

  .hamburger-menu-btn {
    margin-left: 15px;
  }

  .hamburger-menu-btn.w--open {
    width: 45px;
  }

  .hamburger-menu-btn.btn-primary {
    width: 45px;
  }

  .social-media-grid-top {
    grid-template-columns: repeat(auto-fit, 30px);
  }

  .grid-footer-v1---5-column {
    grid-row-gap: 48px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-logo {
    width: 100%;
    max-width: 240px;
  }

  .footer-bottom {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .grid-subscribe-form-button-down {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .accordion-title {
    font-size: 18px;
    line-height: 26px;
  }

  .tabs-menu.integration-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .empty-state.card-empty.pricing {
    margin-right: 24px;
    margin-left: 24px;
  }

  .pd---content-inside-card.large {
    padding: 40px 24px;
  }

  .pd---content-inside-card.large.template-pages {
    padding-right: 24px;
    padding-left: 24px;
  }

  .pd---content-inside-card.template-pages---sidebar {
    padding-top: 18px;
    padding-bottom: 18px;
  }

  .pricing-content-wrapper.mobile {
    padding-right: 15px;
    padding-left: 15px;
  }

  .image-wrapper.order-item-image-wrapper {
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .image-wrapper.card-featured-feature-image {
    margin-right: -9px;
    margin-left: -9px;
  }

  .image-wrapper.card-advantage-02-image {
    max-width: 98%;
    margin-right: -21px;
    margin-bottom: -50px;
    margin-left: -21px;
  }

  .image-wrapper.card-advantage-03-image {
    max-width: 98%;
    margin-top: -16px;
    margin-right: -19px;
    margin-left: -19px;
  }

  .image-wrapper.card-advantage-04-image {
    margin-top: -19px;
    margin-right: -30px;
    margin-left: -30px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---02 {
    max-width: 73px;
    margin-top: -108px;
    margin-left: 0px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---03 {
    max-width: 75px;
    margin-top: -124px;
    margin-right: 0px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---04 {
    max-width: 77px;
    margin-bottom: -110px;
  }

  .image-wrapper.circle-image.hero-v2-avatar---05 {
    max-width: 77px;
    margin-bottom: -110px;
  }

  .image-wrapper.hero-v2-bg {
    top: -80px;
    width: 1040px;
  }

  .image-wrapper.value-image._01 {
    max-width: 115px;
  }

  .image-wrapper.value-image._02 {
    max-width: 108px;
  }

  .image-wrapper.value-image._03 {
    max-width: 111px;
  }

  .image-wrapper.value-image._04 {
    max-width: 104px;
  }

  .image-wrapper.value-image._05 {
    max-width: 104px;
  }

  .image-wrapper.value-image._06 {
    max-width: 98px;
  }

  .image-wrapper.career-item-icon {
    max-width: 75px;
    margin: -8px -13px -20px;
  }

  .image-wrapper.career-item-details-icon {
    max-width: 22px;
    margin-right: 8px;
  }

  .image-wrapper.hero-v2-gradient-bg---01 {
    top: 0%;
  }

  .image-wrapper.hero-v2-gradient-bg---02 {
    top: -3%;
  }

  .image-wrapper.logo-strip-gradient-bg---01 {
    top: 3%;
  }

  .image-wrapper.logo-strip-gradient-bg---02 {
    top: -3%;
  }

  .image-wrapper.hero-v1-gradient-bg {
    top: 0%;
  }

  .image-wrapper.logo {
    width: 21%;
  }

  .image-wrapper.logo.google {
    width: 16%;
  }

  .image-wrapper.logo.twitch {
    width: 15%;
  }

  .image-wrapper.hero-v3-gradient-bg {
    left: -146%;
  }

  .image-wrapper.card-blog-post-author-image {
    max-width: 100%;
  }

  .image-wrapper.blog-post-body-bg {
    display: none;
  }

  .image-wrapper.integrations-hero {
    max-width: 1100px;
    min-width: 1100px;
  }

  .image-wrapper.integration-page-image {
    margin-top: -10px;
  }

  .image-wrapper.features-hero-bg {
    top: -3%;
  }

  .image-wrapper.coming-soon-icon-image {
    margin-top: -12px;
    margin-bottom: -26px;
  }

  .image-wrapper.image-circle.avatar-left {
    max-width: 60px;
    margin-right: 10px;
  }

  .image-wrapper.log-social-media-icon {
    max-width: 22px;
  }

  .image-wrapper.cart-item-image-wrapper {
    max-width: 30px;
  }

  ._404-not-found {
    margin-bottom: 0px;
    font-size: 30vw;
    line-height: 1em;
  }

  .cart-container {
    border-radius: 0px;
  }

  .cart-item-price {
    color: #707480;
    font-size: 16px;
    line-height: 18px;
  }

  .pay-btn.cart {
    height: 53px;
  }

  .checkout-block-content.pd-top-0px {
    padding-top: 0px;
  }

  .shipping-method {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-row-gap: 12px;
  }

  .order-item {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .order-list-price {
    margin-top: 11px;
  }

  .order-list-title {
    margin-bottom: 4px;
  }

  .order-item-content {
    margin-right: 0px;
  }

  .checkout-column {
    margin-bottom: 24px;
  }

  .home-pages-top-wrapper {
    margin-bottom: 16px;
  }

  .image-home-link-wrapper {
    max-width: 180px;
    margin-right: 8px;
    margin-left: 8px;
  }

  .figma-file-text-wrapper {
    padding-right: 24px;
    padding-left: 24px;
  }

  .home-surprises-card {
    border-radius: 22px;
  }

  .card-home-pages-wrapper {
    padding-right: 24px;
    padding-left: 24px;
  }

  .position-absolute.team-item-social-media-wrapper {
    right: 20px;
    bottom: 20px;
  }

  .position-absolute.card-pricing-fade {
    min-height: 92px;
  }

  .mg-top-116px {
    margin-top: 70px;
  }

  .mg-bottom-19px {
    margin-bottom: 13px;
  }

  .card-advantage-01-content {
    padding-top: 10px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-advantage-02-content {
    padding: 40px 24px 30px;
  }

  .card-advantage-03-content {
    padding: 40px 24px 30px;
  }

  .card-advantage-04-content {
    padding: 40px 24px 30px;
  }

  .card-cta-v1-image-wrapper {
    margin-bottom: 60px;
  }

  .mg-bottom-30px {
    margin-bottom: 20px;
  }

  .blog-post-content {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-footer-cta-icon {
    max-width: 60px;
    margin-bottom: 15px;
  }

  .menu-wrapper---main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    grid-row-gap: 40px;
  }

  .grid-footer---main {
    grid-row-gap: 50px;
  }

  .menu-wrapper {
    margin-right: 0px;
  }

  .nav-content {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mg-bottom-255px {
    margin-bottom: 148px;
  }

  .card-stats-wrapper {
    padding-right: 10px;
    padding-left: 10px;
  }

  .team-item-content {
    padding-top: 35px;
    padding-right: 24px;
    padding-left: 24px;
  }

  .stats-shadow {
    width: 90%;
  }

  .logo-strip-grid {
    min-width: 1098px;
  }

  .career-item-main-content {
    margin-top: 15px;
  }

  .big-number {
    margin-bottom: 5px;
    font-size: 7vw;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0px;
  }

  .card-stats-grid {
    grid-row-gap: 30px;
  }

  .mg-bottom-120px---mbl {
    margin-bottom: 100px;
  }

  .blog-post-featured-content {
    padding: 32px 24px 41px;
  }

  .pagination-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .logo-strips-right-wrapper {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-row-gap: 15px;
  }

  .card-newsletter-icon {
    max-width: 60px;
    margin-bottom: 15px;
  }

  .button-inside-form-block {
    min-height: 138px;
  }

  .newsletter-shadow {
    width: 90%;
  }

  .blog-post-page-image-shadow {
    width: 90%;
  }

  .card-blog-post-author-content {
    padding-top: 32px;
  }

  .contact-icon-link---left {
    min-width: 19px;
    margin-top: 3px;
    margin-right: 8px;
    margin-bottom: 0px;
  }

  .contact-form-block {
    min-height: 842px;
  }

  .card-plan-item-top-wrapper {
    padding: 40px 24px;
  }

  .mg-top-0px---tablet {
    margin-top: 0px;
  }

  .card-plan-item:last-child {
    padding-bottom: 92px;
  }

  .rich-text-v2 img {
    border-radius: 10px;
  }

  .rich-text-v2 h2 {
    font-size: 24px;
  }

  .rich-text-v2 figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text-v2 blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-top-0px {
    margin-top: 0px;
  }

  .mg-bottom-35px {
    margin-bottom: 24px;
  }

  .rich-text-v3 img {
    border-radius: 10px;
  }

  .rich-text-v3 figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text-v3 ul {
    padding-left: 20px;
  }

  .rich-text-v3 ol {
    padding-left: 20px;
  }

  .rich-text-v3 blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .mg-top-155px {
    margin-top: 50px;
  }

  .rich-text-v4 img {
    border-radius: 10px;
  }

  .rich-text-v4 figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text-v4 ul {
    padding-left: 20px;
  }

  .rich-text-v4 ol {
    padding-left: 20px;
  }

  .rich-text-v4 blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text-v5 img {
    border-radius: 10px;
  }

  .rich-text-v5 h2 {
    font-size: 24px;
  }

  .rich-text-v5 figure {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .rich-text-v5 ul {
    padding-left: 20px;
  }

  .rich-text-v5 ol {
    padding-left: 20px;
  }

  .rich-text-v5 blockquote {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .card-team-page-content {
    padding-top: 32px;
    padding-bottom: 50px;
  }

  .coming-soon-icon {
    max-width: 80px;
  }

  .demo-icon-left {
    max-width: 20px;
    min-width: 20px;
    margin-right: 10px;
  }

  .sign-in-form-block {
    min-height: 346px;
  }

  .cart-item-content {
    margin-right: 0px;
  }

  .typing-animation-wrapper {
    max-width: 280px;
  }

  .typing-animation-line {
    width: 6px;
  }
}

#w-node-_186fbe43-5fbe-0034-e8b4-787e8adcfd3f-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_50cacb8e-de58-161a-1f8f-5bbd2d28523d-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f0fbf3a8-e14e-69fb-5226-d9d894e0718c-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b802a070-9102-8775-5428-930ff319ab2f-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c65ebb9a-0003-60c6-c9f8-473e2fee1b2f-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5eb27792-4351-d8fc-ea25-105b76196b2b-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c4b4a860-7d95-555a-fc7a-89f463a0fe67-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5b0d90e5-bfd5-f30b-256b-3e7c69948a4c-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_168b8203-cf11-9a96-c4b9-fb1fe5614763-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-faefbd2b-a550-bdfc-1647-b80235307d04-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_57f2bfa5-9e14-5e45-08e1-896a88470a8f-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5781d8af-144a-ff83-6a75-ef1ef04e689b-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e7bad051-593f-f5ef-08c8-0d3d3aea5d2f-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_494e08e1-a5bb-f81b-89f6-e7626df950ee-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_8733a564-0971-43a9-33aa-b0e25015502c-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_52310a14-b5f3-9ad7-3bc4-1fa9b7c5fa79-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_84216be6-6075-19af-2f37-f79309a0bed7-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6bce258d-e638-b2ac-937e-cc8adfc681ba-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4bf4fff5-bb35-40c9-34ae-79ad4ed49ead-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4bf4fff5-bb35-40c9-34ae-79ad4ed49eb5-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4bf4fff5-bb35-40c9-34ae-79ad4ed49ebd-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_12892065-bc17-e70e-db75-d40813dad9a1-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6d81184f-6c54-cc79-8d80-c8efdfbacb8f-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_39f1c957-e64f-03e8-7fd1-e917c40b1c60-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-aee1fdbe-1387-4397-e993-d21c363dd8ba-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a03e51d8-a0ed-40fd-367a-fbe9c62136e2-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a03e51d8-a0ed-40fd-367a-fbe9c62136db-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dc0973a7-e425-9f2a-6ca0-2cc0078e37a5-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dc0973a7-e425-9f2a-6ca0-2cc0078e37ac-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a94e09d-dc86-bbd2-d2f3-c35b05bbe257-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6a94e09d-dc86-bbd2-d2f3-c35b05bbe259-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7d4afe16-534c-7da9-a1f1-701c68b3f2c6-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7d4afe16-534c-7da9-a1f1-701c68b3f2d3-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_781fd030-4278-db6d-0b33-f865d791c7e8-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0ea7b455-31b2-75ac-3c81-e240aea03e46-59b50fca {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab1d2-c21ab1cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_03a07d8c-b68c-7418-f3b9-b31931fad5a9-c21ab1cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab1f2-c21ab1cb {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab1fc-c21ab1cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_16cb2c78-3a6c-34a9-6fbf-67135ec7333d-c21ab1cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_023705f9-9ca0-ab66-32ff-a6c1af0df4cc-84b50fce {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9933b054-27a6-8891-223a-5b2d5d090f45-45b50fcf {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_64b0a31d-004c-ecfc-3704-0b53d27c505c-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_55b166db-ec4d-63c0-c1e0-32efad25b619-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_15d35607-6933-24a2-ab6e-a1af39d365fa-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_553cb387-fdf0-ab0f-3552-6b779fcb4ed7-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f2a8ab0c-cc6e-a5a0-c480-fff6e6bdaf2c-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_757b4470-415e-4691-2ae4-f8e18bb4dcb8-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_518c3515-0f0d-10a0-4d6b-757a82282168-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_518c3515-0f0d-10a0-4d6b-757a8228216b-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_518c3515-0f0d-10a0-4d6b-757a8228216e-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_518c3515-0f0d-10a0-4d6b-757a82282171-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_518c3515-0f0d-10a0-4d6b-757a82282174-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_518c3515-0f0d-10a0-4d6b-757a82282177-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be4c655e-f3da-cfe9-8ad8-3644306c1bd9-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be4c655e-f3da-cfe9-8ad8-3644306c1bdc-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be4c655e-f3da-cfe9-8ad8-3644306c1bdf-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be4c655e-f3da-cfe9-8ad8-3644306c1be2-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be4c655e-f3da-cfe9-8ad8-3644306c1be5-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be4c655e-f3da-cfe9-8ad8-3644306c1be8-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dd21112e-ddb7-e52f-4fc2-73340b46b652-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dd21112e-ddb7-e52f-4fc2-73340b46b655-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dd21112e-ddb7-e52f-4fc2-73340b46b658-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dd21112e-ddb7-e52f-4fc2-73340b46b65b-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dd21112e-ddb7-e52f-4fc2-73340b46b65e-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-dd21112e-ddb7-e52f-4fc2-73340b46b661-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7fbab8e-0a8b-99da-a841-eef3f1dedeae-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7fbab8e-0a8b-99da-a841-eef3f1dedeb1-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7fbab8e-0a8b-99da-a841-eef3f1dedeb4-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7fbab8e-0a8b-99da-a841-eef3f1dedeb7-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7fbab8e-0a8b-99da-a841-eef3f1dedeba-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7fbab8e-0a8b-99da-a841-eef3f1dedebd-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e66d689c-bdca-8445-2467-dda02bd92b3e-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e66d689c-bdca-8445-2467-dda02bd92b41-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e66d689c-bdca-8445-2467-dda02bd92b44-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e66d689c-bdca-8445-2467-dda02bd92b47-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e66d689c-bdca-8445-2467-dda02bd92b4a-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e66d689c-bdca-8445-2467-dda02bd92b4d-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4a68729f-ffb7-9b2c-398c-fa684fae8861-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4a68729f-ffb7-9b2c-398c-fa684fae8864-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4a68729f-ffb7-9b2c-398c-fa684fae8867-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4a68729f-ffb7-9b2c-398c-fa684fae886a-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4a68729f-ffb7-9b2c-398c-fa684fae886d-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4a68729f-ffb7-9b2c-398c-fa684fae8870-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e418e573-d849-9870-7694-47935a765087-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e418e573-d849-9870-7694-47935a76508a-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e418e573-d849-9870-7694-47935a76508d-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e418e573-d849-9870-7694-47935a765090-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e418e573-d849-9870-7694-47935a765093-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e418e573-d849-9870-7694-47935a765096-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0195bfab-1977-a4ae-73cc-453672f3ebbd-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0195bfab-1977-a4ae-73cc-453672f3ebc0-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0195bfab-1977-a4ae-73cc-453672f3ebc3-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0195bfab-1977-a4ae-73cc-453672f3ebc6-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0195bfab-1977-a4ae-73cc-453672f3ebc9-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0195bfab-1977-a4ae-73cc-453672f3ebcc-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4caea7f8-9b9e-bc4e-c7ea-0e25ff8d1e1d-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4caea7f8-9b9e-bc4e-c7ea-0e25ff8d1e20-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4caea7f8-9b9e-bc4e-c7ea-0e25ff8d1e23-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4caea7f8-9b9e-bc4e-c7ea-0e25ff8d1e26-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4caea7f8-9b9e-bc4e-c7ea-0e25ff8d1e29-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_4caea7f8-9b9e-bc4e-c7ea-0e25ff8d1e2c-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_80795cf0-3959-cb76-6fa3-e33b5d09ab83-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_80795cf0-3959-cb76-6fa3-e33b5d09ab86-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_80795cf0-3959-cb76-6fa3-e33b5d09ab89-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_80795cf0-3959-cb76-6fa3-e33b5d09ab8c-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_80795cf0-3959-cb76-6fa3-e33b5d09ab8f-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_80795cf0-3959-cb76-6fa3-e33b5d09ab92-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-abbfd761-79d8-4172-c5fa-802cf5b0b66f-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-abbfd761-79d8-4172-c5fa-802cf5b0b672-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-abbfd761-79d8-4172-c5fa-802cf5b0b675-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-abbfd761-79d8-4172-c5fa-802cf5b0b678-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-abbfd761-79d8-4172-c5fa-802cf5b0b67b-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-abbfd761-79d8-4172-c5fa-802cf5b0b67e-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-bd0f1f51-e60e-25d3-02db-9f40a1393321-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e0c00b25-6aa0-fc7a-acf5-c62dc1b5238d-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-_8e149ae0-7a86-4a5b-3364-699cf9e511a6-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b618439a-066a-e1a7-48f4-7fce4f36ee13-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-b9f56016-3c8c-252e-6f3e-60e1b359923a-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_94581837-a691-367c-58e3-8b0ea3b33924-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a4671e7b-ed29-f88f-ec4d-569dba879c1d-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_190e756d-135e-0f9c-1df1-e708c65b0214-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6ef7df69-2a62-5da3-176f-07cfeb8591a3-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c2778706-bec9-013d-7ab9-62504d8193ba-44b50fd0 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-bb435907-c1dc-ecfa-30ef-bd7267ded17c-aab50fd2 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
  -ms-grid-row-align: end;
  align-self: end;
}

#w-node-a6c2e003-8258-2858-fe1f-28d52d83095a-aab50fd2 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0172909a-3555-d686-0b15-372879434713-aab50fd2 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_48f458c4-6e15-4ca3-4ab3-03da18809a7d-aab50fd2 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-eea7670c-71e6-1c10-b123-7739e5beb3fe-aab50fd2 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_9517360f-1b29-cfe5-6e40-7b02da9af3d8-aab50fd2 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_236160e4-6be2-91b9-9e50-54fd70560794-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_72315b8b-fd7b-e7c6-42b5-62d30dc4741e-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_3e655760-95c1-d996-7cc6-217cc5abf02d-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0ca1e8d5-4514-ea88-8199-185558f19d33-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_30c7e285-4e1a-8b59-19eb-0d82fc9d36d0-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f1fc2c10-639a-fff2-d63a-e6dc4972319d-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d93c9881-a651-aaac-fc9d-2d55297ea1e9-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fce912cb-d3a2-a726-75ba-ad7aca026bbe-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a4c75e31-d860-b76f-dc15-75c4795f62e2-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_59fce8d6-10a8-2f35-a5ea-e70078806b7c-53b50fd6 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_712d1474-8d9c-7605-f479-9bbd8c706947-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_712d1474-8d9c-7605-f479-9bbd8c70694b-53b50fd6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9b91b7f0-e5e0-3503-ad41-52d4fcc9f561-5db50fd9 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9b91b7f0-e5e0-3503-ad41-52d4fcc9f562-5db50fd9 {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_3ed8f6ff-0274-20ff-2495-04e5b4454c03-b4454bff {
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_3ed8f6ff-0274-20ff-2495-04e5b4454c05-b4454bff {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_99fd8587-4021-5793-3329-855e28ceb987-d3b50fda {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6d0-d3b50fda {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_684674f7-560b-6d62-0d82-8f8b0d603282-d3b50fda {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_684674f7-560b-6d62-0d82-8f8b0d603283-d3b50fda {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c08a43c1-b28e-c399-bb16-7275606f07be-d3b50fda {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-d6b69710-0f08-34d4-fe64-d45fa82ae721-8db50fde {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_44382268-6ce5-1875-ec50-79cc055a5451-8db50fde {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_15e7164f-9c51-5b0b-8c1d-887664894dc9-8db50fde {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-fc60eb8d-d8b4-a954-6e37-ffb5b3cc38bc-8db50fde {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-eee02bf1-32b1-87c4-17c8-1ec6ea500152-0fb50fdf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_1e31efc7-4ed1-628a-5e46-a6bc3bdb735b-0fb50fdf {
  -ms-grid-column-align: end;
  justify-self: end;
  -webkit-align-self: start;
  -ms-flex-item-align: start;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_7663b816-cf73-8d71-e5b7-95b1248f840a-0fb50fdf {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_48b64d74-8406-ef8b-5e72-0800de8d6539-0fb50fdf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_48b64d74-8406-ef8b-5e72-0800de8d6540-0fb50fdf {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_884b5418-7f99-5602-14d9-929082f5c3a9-e5b50ff6 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_8d192219-41f0-6b3b-8e35-e88dd48ba7b0-e5b50ff6 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_1ec962f3-bb71-2a70-98aa-e0c73078dadd-0ab51009 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-fed7d581-ebd1-6c86-92b2-67fb42a835b1-0ab51009 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_11803f16-460f-460d-fa3e-612c25b8f1fa-0ab51009 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_11803f16-460f-460d-fa3e-612c25b8f1fe-0ab51009 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e834eb98-8d66-62bf-485a-0885b24a808a-dcb5100c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5b11eff4-7539-cfae-e5b5-74a8c65513a6-dcb5100c {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_5b11eff4-7539-cfae-e5b5-74a8c65513c5-dcb5100c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5b11eff4-7539-cfae-e5b5-74a8c65513c9-dcb5100c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0a9ed8f4-36b0-af08-5864-f5fc1ba0fcb4-adb5100d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-_0a9ed8f4-36b0-af08-5864-f5fc1ba0fcbf-adb5100d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0a9ed8f4-36b0-af08-5864-f5fc1ba0fcc0-adb5100d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0a9ed8f4-36b0-af08-5864-f5fc1ba0fcc1-adb5100d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0a9ed8f4-36b0-af08-5864-f5fc1ba0fcc5-adb5100d {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_3d29d0d5-e8e7-1c12-f1b8-a5e9a97c35b1-53b5100e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-cb747c70-221b-f4ec-c438-fc7dfff9706a-53b5100e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_27ffc396-024d-48a5-279d-825b28655c44-53b5100e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_33b99b46-395b-51eb-325a-d3c1fec3ae4c-40b5100f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_519de634-48dd-3b58-e59a-a328795007f8-40b5100f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_10ca4d72-3fe8-8f37-abc9-1c575bf16145-40b5100f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d28c7370-66d8-4d28-65bf-5da60307a623-56b51011 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-e37eb5c7-6c6c-3a93-7c95-e98faa61b891-56b51011 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e37eb5c7-6c6c-3a93-7c95-e98faa61b898-56b51011 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-82b51017 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a28-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a36-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a44-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_13f258cb-47ea-c61e-2f05-e4867f55317b-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_13f258cb-47ea-c61e-2f05-e4867f553187-82b51017 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_13f258cb-47ea-c61e-2f05-e4867f553192-82b51017 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_786ca2f5-fbe9-eeb8-5838-98a776a96cbf-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_786ca2f5-fbe9-eeb8-5838-98a776a96ccc-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_786ca2f5-fbe9-eeb8-5838-98a776a96cd9-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_786ca2f5-fbe9-eeb8-5838-98a776a96ce6-82b51017 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_28700045-4e7d-769b-896c-24b23b1b466a-beb51046 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_03db3392-1388-aca3-5b45-5c1c62ec46f9-d0b510cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-c0dddd0f-d407-ae88-485c-97fe07b1c4c9-d0b510cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_2c5d86dd-3cb7-f81a-6360-63bbf6b8e8f5-d0b510cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a33844ef-d97b-1399-34c9-3a92859bca71-d0b510cb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_1eb9fce8-76ab-632f-4778-d8bc693f6b84-d0b510cb {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_03db3392-1388-aca3-5b45-5c1c62ec46f9-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: start;
  align-self: start;
}

#w-node-c0dddd0f-d407-ae88-485c-97fe07b1c4c9-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_2c5d86dd-3cb7-f81a-6360-63bbf6b8e8f5-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a33844ef-d97b-1399-34c9-3a92859bca71-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-df906bdd-b95d-b88f-963a-1f12607bbbc4-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_61ddb27a-1d08-a60f-3dd4-724036599678-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_04e093ca-5fad-7ce8-5366-283a0cf4220c-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6b40b45e-736b-7923-fd78-c232cf934c41-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_8a87a549-2ac4-f491-e6f9-beae8e63444c-85b510f1 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9def8932-4468-cf64-172f-74b918cc0212-d1b51104 {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0485beb4-c9dc-71bb-c595-1997ad72276c-d1b51104 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ae6266a9-0545-c3d9-a253-8879dd094d70-d1b51104 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a8799011-ec24-24af-9c61-9151c4f6a1d8-d1b51104 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_15a4fa92-27d3-1373-d559-a74128c14aff-d1b51104 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d9892f12-3c55-279d-0814-e8c3064bc56f-d1b51104 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_67664dda-b36b-0e7a-0f5a-e46edeca4371-d1b51104 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f79b2818-4f58-7aff-2d54-8ece3ebdc45f-d1b51104 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f4ec9e9d-b7b5-aec8-8d40-5e1391ae3a35-d1b51104 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_537a51f1-604f-c298-1adf-a782e710ec6c-d1b51104 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_61914fa1f3028ad34c1c480b000000000014-1bb5110d {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_784ae6c3-99e7-22ff-5a91-55348281b8d7-1db5110e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_30051546-ceb3-4329-abdd-8f62e2e089c1-1db5110e {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_7f0d3c46-3030-200b-faf3-cb9c503b102a-1db5110e {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-row-align: center;
  align-self: center;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_30051546-ceb3-4329-abdd-8f62e2e089cc-1db5110e {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-d477c705-3310-a211-4ecd-72b5564c2fed-41b5110f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_317d673b-7252-7fc8-32dd-29667b8b3465-41b5110f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_37c53a37-5ad4-712a-1825-2d08cc2cd2b1-41b5110f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_92e42b57-63d5-9190-d901-7997474121df-41b5110f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_79f29721-3ac7-3211-edeb-995d5e38032d-41b5110f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ddac9b20-4b50-e9a4-4f54-5fdddd5b95e8-41b5110f {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-_48179c42-8780-db30-ba01-1c22fea07ab4-18b51112 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-58f68725 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a28-58f68725 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a36-58f68725 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a44-58f68725 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_236d437d-986f-0744-6b00-89092311787a-58f68725 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_236d437d-986f-0744-6b00-890923117886-58f68725 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_236d437d-986f-0744-6b00-890923117892-58f68725 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_99fd8587-4021-5793-3329-855e28ceb987-2892788f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6d0-2892788f {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_684674f7-560b-6d62-0d82-8f8b0d603282-2892788f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: start;
  justify-self: start;
}

#w-node-_684674f7-560b-6d62-0d82-8f8b0d603283-2892788f {
  -ms-grid-column-align: start;
  justify-self: start;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-c08a43c1-b28e-c399-bb16-7275606f07be-2892788f {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-25560134 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a28-25560134 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a36-25560134 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a44-25560134 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0ebdcb93-515c-03d3-62ed-9fc5f3c94a8c-25560134 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0ebdcb93-515c-03d3-62ed-9fc5f3c94a98-25560134 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0ebdcb93-515c-03d3-62ed-9fc5f3c94aa4-25560134 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9def8932-4468-cf64-172f-74b918cc0212-b937813d {
  -ms-grid-column-align: end;
  justify-self: end;
}

#w-node-_0485beb4-c9dc-71bb-c595-1997ad72276c-b937813d {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-ae6266a9-0545-c3d9-a253-8879dd094d70-b937813d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a8799011-ec24-24af-9c61-9151c4f6a1d8-b937813d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_15a4fa92-27d3-1373-d559-a74128c14aff-b937813d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d9892f12-3c55-279d-0814-e8c3064bc56f-b937813d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_67664dda-b36b-0e7a-0f5a-e46edeca4371-b937813d {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f79b2818-4f58-7aff-2d54-8ece3ebdc45f-b937813d {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f4ec9e9d-b7b5-aec8-8d40-5e1391ae3a35-b937813d {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_537a51f1-604f-c298-1adf-a782e710ec6c-b937813d {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-eb960a05 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a28-eb960a05 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a36-eb960a05 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a44-eb960a05 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_72f8c22e-012b-a6d0-f33a-326ff001f105-eb960a05 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_72f8c22e-012b-a6d0-f33a-326ff001f111-eb960a05 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_72f8c22e-012b-a6d0-f33a-326ff001f11c-eb960a05 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 991px) {
  #w-node-a03e51d8-a0ed-40fd-367a-fbe9c62136db-59b50fca {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_6a94e09d-dc86-bbd2-d2f3-c35b05bbe259-59b50fca {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab1d2-c21ab1cb {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab1e5-c21ab1cb {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab1fc-c21ab1cb {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_16cb2c78-3a6c-34a9-6fbf-67135ec7333d-c21ab1cb {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_59d31a6a-2c5d-0844-8749-8a50c21ab261-c21ab1cb {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_8b706edb-719d-cf8e-3ef8-51befd842f1a-aab50fd2 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-a6c2e003-8258-2858-fe1f-28d52d83095a-aab50fd2 {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-_3ed8f6ff-0274-20ff-2495-04e5b4454c03-b4454bff {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_3ed8f6ff-0274-20ff-2495-04e5b4454c05-b4454bff {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_99fd8587-4021-5793-3329-855e28ceb987-d3b50fda {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae705-8db50fde {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_44382268-6ce5-1875-ec50-79cc055a5451-8db50fde {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-eee02bf1-32b1-87c4-17c8-1ec6ea500152-0fb50fdf {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_0a9ed8f4-36b0-af08-5864-f5fc1ba0fcbf-adb5100d {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-82b51017 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c0dddd0f-d407-ae88-485c-97fe07b1c4c9-d0b510cb {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-c0dddd0f-d407-ae88-485c-97fe07b1c4c9-85b510f1 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_0485beb4-c9dc-71bb-c595-1997ad72276c-d1b51104 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-58f68725 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_99fd8587-4021-5793-3329-855e28ceb987-2892788f {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-25560134 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0485beb4-c9dc-71bb-c595-1997ad72276c-b937813d {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_7ee0c7e5-29d3-53d2-4f71-cfbe283e5a1b-eb960a05 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 767px) {
  #w-node-a03e51d8-a0ed-40fd-367a-fbe9c62136db-59b50fca {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_6a94e09d-dc86-bbd2-d2f3-c35b05bbe259-59b50fca {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-bb435907-c1dc-ecfa-30ef-bd7267ded17c-aab50fd2 {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-_3ed8f6ff-0274-20ff-2495-04e5b4454c03-b4454bff {
    -ms-grid-column-align: center;
    justify-self: center;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c0-d3b50fda {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c4-d3b50fda {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c8-d3b50fda {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6cc-d3b50fda {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae70c-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae710-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae714-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f92d193d-60c6-9491-5699-4e38b7aedac9-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae718-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-e56800a4-c92a-6111-a5be-b8b448f5edc6-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae720-8db50fde {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_1e31efc7-4ed1-628a-5e46-a6bc3bdb735b-0fb50fdf {
    -ms-grid-column-align: auto;
    justify-self: auto;
    -webkit-box-ordinal-group: 10000;
    -webkit-order: 9999;
    -ms-flex-order: 9999;
    order: 9999;
  }

  #w-node-_7663b816-cf73-8d71-e5b7-95b1248f840a-0fb50fdf {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_13f258cb-47ea-c61e-2f05-e4867f553187-82b51017 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_13f258cb-47ea-c61e-2f05-e4867f553192-82b51017 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_67664dda-b36b-0e7a-0f5a-e46edeca4371-d1b51104 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f79b2818-4f58-7aff-2d54-8ece3ebdc45f-d1b51104 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e3a90f75-d470-7097-bbbe-55a933809da3-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_4d23d9c3-32aa-001b-0596-e5a65931aef9-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_5d0cf41d-1172-6000-505c-5abc2306569e-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-ae36a11d-9aa6-9b99-1c4e-812c3ccf1b6f-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_529f5180-557e-0011-a8c7-d413bd690ec5-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_529f5180-557e-0011-a8c7-d413bd690ed0-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_529f5180-557e-0011-a8c7-d413bd690edb-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_529f5180-557e-0011-a8c7-d413bd690ee6-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_3e7a3750-203b-4f65-b2ff-7193010c307e-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_820331d6-c4e7-e22c-17e6-307c4ba0cf79-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d263-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d26e-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_2cc935a5-ba4b-b138-1cd0-3ca62c07d279-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-cd97ed09-7ee7-f868-9f24-92008cbffbf9-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_678fa4ae-1fca-b02f-fd27-9efe550463d6-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_053b3848-5278-b0d0-565f-4e26607311a1-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_8638ecc7-fc7a-8770-ed2b-943d852abcf3-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0a9-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0bd-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-fdedfdaf-07eb-26ce-ec5a-44f9429fbce6-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-e5098d5c-99e5-a310-1707-51b579e0852f-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_1d39ea8e-bae3-1512-4ef0-cdf93fc0f8da-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_0959ac05-e5ad-fd43-8e7e-a09dd2f57df9-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-d7e62f01-1c68-6af4-0f0b-aa76e3a3f0d1-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-f9a626ae-4f05-7075-0d66-3072978dbb53-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_5e353be1-d012-39fc-eeb8-2763382b8e35-18b51112 {
    -webkit-box-ordinal-group: -9998;
    -webkit-order: -9999;
    -ms-flex-order: -9999;
    order: -9999;
  }

  #w-node-_236d437d-986f-0744-6b00-890923117886-58f68725 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_236d437d-986f-0744-6b00-890923117892-58f68725 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c0-2892788f {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c4-2892788f {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c8-2892788f {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6cc-2892788f {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_0ebdcb93-515c-03d3-62ed-9fc5f3c94a98-25560134 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_0ebdcb93-515c-03d3-62ed-9fc5f3c94aa4-25560134 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_67664dda-b36b-0e7a-0f5a-e46edeca4371-b937813d {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f79b2818-4f58-7aff-2d54-8ece3ebdc45f-b937813d {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_72f8c22e-012b-a6d0-f33a-326ff001f111-eb960a05 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_72f8c22e-012b-a6d0-f33a-326ff001f11c-eb960a05 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}

@media screen and (max-width: 479px) {
  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c0-d3b50fda {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c4-d3b50fda {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c8-d3b50fda {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6cc-d3b50fda {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6d0-d3b50fda {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c08a43c1-b28e-c399-bb16-7275606f07be-d3b50fda {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae70c-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae710-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae714-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f92d193d-60c6-9491-5699-4e38b7aedac9-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae718-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-e56800a4-c92a-6111-a5be-b8b448f5edc6-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-d6b69710-0f08-34d4-fe64-d45fa82ae720-8db50fde {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-_30051546-ceb3-4329-abdd-8f62e2e089c1-1db5110e {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-_7f0d3c46-3030-200b-faf3-cb9c503b102a-1db5110e {
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-column-align: auto;
    justify-self: auto;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c0-2892788f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c4-2892788f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6c8-2892788f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6cc-2892788f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-f10c63ff-af96-b4fc-eab0-386ef7c9f6d0-2892788f {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }

  #w-node-c08a43c1-b28e-c399-bb16-7275606f07be-2892788f {
    -ms-grid-column-align: auto;
    justify-self: auto;
  }
}

@font-face {
  font-family: 'Line Rounded Icons';
  src: url('../../assets/images/fonts/line-rounded-icons.woff2') format('woff2'), url('../../assets/images/fonts/line-rounded-icons.eot') format('embedded-opentype'), url('../../assets/images/fonts/line-rounded-icons.woff') format('woff'), url('../../assets/images/fonts/line-rounded-icons.ttf') format('truetype'), url('../../assets/images/fonts/line-rounded-icons.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@media screen and (max-width: 1000px) {
  .header-nav-menu-list {
    display: none;
  }
  .header-logo {
    width: 152px;
  }
  .header-container {
    justify-content: space-between !important;
  }
  .header-right-side {
    margin-left: 250px;
  }
}

@media  screen and (min-width: 999px) {
  .drawer {
    display: none;
  }
}
  
  