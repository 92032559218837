.footer_d {
  background: #18422b;
  color: white;
  padding: 27px 0px 13px 0px;
}
.icon_span {
  font-size: 2rem;
  background: #205d3b;
  border-radius: 12px;
  padding: 6px;
  margin: 4px;
}

.FooterPage-email {
  /* text-decoration: none; */
  color: white;
  cursor: pointer;
  font-weight: 500;
}
