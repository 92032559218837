@keyframes ldio-ygqw8hugljs {
  0% { transform: rotate(0) }
  100% { transform: rotate(360deg) }
}
.ldio-ygqw8hugljs div { box-sizing: border-box!important }
.ldio-ygqw8hugljs > div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #5be16b transparent #5be16b transparent;
  animation: ldio-ygqw8hugljs 1s linear infinite;
}

.ldio-ygqw8hugljs > div:nth-child(2), .ldio-ygqw8hugljs > div:nth-child(4) {
  width: 54px;
  height: 54px;
  top: 23px;
  left: 23px;
  animation: ldio-ygqw8hugljs 1s linear infinite reverse;
}
.ldio-ygqw8hugljs > div:nth-child(2) {
  border-color: transparent #d3f86a transparent #d3f86a
}
.ldio-ygqw8hugljs > div:nth-child(3) { border-color: transparent }
.ldio-ygqw8hugljs > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-ygqw8hugljs > div:nth-child(3) div:before, .ldio-ygqw8hugljs > div:nth-child(3) div:after { 
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #5be16b;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #5be16b;
}
.ldio-ygqw8hugljs > div:nth-child(3) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #5be16b;
}

.ldio-ygqw8hugljs > div:nth-child(4) { border-color: transparent; }
.ldio-ygqw8hugljs > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-ygqw8hugljs > div:nth-child(4) div:before, .ldio-ygqw8hugljs > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 15px;
  background: #d3f86a;
  border-radius: 50%;
  box-shadow: 0 46px 0 0 #d3f86a;
}
.ldio-ygqw8hugljs > div:nth-child(4) div:after {
  left: -8px;
  top: 15px;
  box-shadow: 46px 0 0 0 #d3f86a;
}
.loadingio-spinner-double-ring-nvd7kvp4yop {
  width: 74px;
  height: 74px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-ygqw8hugljs {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.74);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ygqw8hugljs div { box-sizing: content-box; }
/* generated by https://loading.io/ */

.Loading_body{
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}